import { Fragment, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Checkbox,
  Card,
  Hidden,
} from "@mui/material";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { i18n } from "i18n";
import InputNumber from "components/Common/ElementInForm/InputNumber";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AxiosGet,
  AxiosPost,
  AzureFunction,
  Extension,
  GetPermission,
  ResultAPI,
} from "service/CommonFunction/TS_function";
import {
  BtnSubmit,
  BtnBack,
  BtnReject,
  BtnApprove
} from "components/Common/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import UploadFile from 'components/Common/ElementInForm/UploadFile/UploadFile';
import secureLocalStorage from 'react-secure-storage';

let sController = "AdminOrder";
let sPackageType = sController + "/GetData_DropDown_PackageType";
let sPackage = sController + "/GetData_DropDown_Package";
let sPricePakage = sController + "/GetData_PricePakage";
let sDetail = sController + "/GetData_DetailOrderEmployer";
let sSave = sController + "/SaveDataOrder";
let sGetEmployer = sController + "/GetData_Employer";
let sSpecial = sController + "/GetData_DropDown_Special";
let sStatus = sController + "/GetData_DropDown_Status_Order";

export default function AdminOrderDetail(props) {
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");

  const isEditMode = sID ? true : false;

  const [isDisabled, setisDisabled] = useState(false);
  const [isDisabledPackage, setisDisabledPackage] = useState(true);
  const [isCheckedConsent, setisCheckedConsent] = useState(false);
  const [isShowPrice, setisShowPrice] = useState(false);
  const i18nField = "OrderEmployer";
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isBanner, setIsBanner] = useState(false);
  const [nPackageType, setnPackageType] = useState("");
  const [detail, setDetail] = useState<{ [key: string]: any }>({});
  const [isPause, setPause] = useState(true);
  const [lstPackage, setlstPackage] = useState([]);
  const [lstPackageType, setlstPackageType] = useState([]);
  const [lstEmployer, setlstEmployer] = useState([]);
  const [lstSpecial, setlstSpecial] = useState([]);
  const [lstStatus, setlstStatus] = useState([]);
  const [lstStatusAll, setlstStatusAll] = useState([]);
  //Banner Package
  const [arrDuration, setarrDuration] = useState([]);
  const [arrlstRow, setarrlstRow] = useState([]);
  const [lstarrValue, setarrValue] = useState([]);
  const [lstCheck, setlstCheck] = useState([]);
  const [isAddPayment_Invoice, setisAddPayment_Invoice] = useState(false);
  const [isAddPayment_Payment, setisAddPayment_Payment] = useState(false);
  const [isAddPayment_Tax, setisAddPayment_Tax] = useState(false);
  const [arrFile1, setarrFile1] = useState([]);
  const [arrFile2, setarrFile2] = useState([]);
  const [arrFile3, setarrFile3] = useState([]);
  const [sChangeStatus, setChangeStatus] = useState(0);
  const [sStatusOrder_ID, setStatus_ID] = useState("");
  const [sPauseID, setPauseID] = useState("");
  const [sEmailcompany, setEmailcompany] = useState({} as any);
  const [IsJobPost, setIsJobPost] = useState(false);
  const [IsnCoin, setIsCoin] = useState(false);
  const [IsnCoinFree, setInCoinFree] = useState(false);
  const [IsExpirey, setIsExpirey] = useState(false);
  const [IsTotalPrice, setIsTotalPrice] = useState(false);
  const [IsDiscount, setIsDiscount] = useState(false);
  const [nPermission, setPermission] = useState(0);
  const [isInvoid, setInvoid] = useState(false);
  const [isCancel, setCancel] = useState(false);
  const [sPercent, setPercent] = useState("");
  const [isWaitforPayment, setWaitforpayment] = useState(false);
  const [isRequestPreUsed, setRequestPreUsed] = useState(false); //when choose special request (sSpecial_ID = 2)
  const [isSpecialRequest, setSpecialRequest] = useState(false); //when choose special request
  const [isDisDesc, setDisDesc] = useState(false); //disable description when edit in AdminOrder page
  const navigate = useNavigate();
  //order status
  const statusNormal = ["2", "3", "4", "5"]; //normal order status
  const statusSpecial = ["6", "7", "8", "9"]; //special order status
  const statusCancle = ["10"]; //cancle order (only status no. 2/3/6)
  const isOrderDetail = new URLSearchParams(searchParams).get("sMode") === "1";
  const isOrderEmployerForm = new URLSearchParams(searchParams).get("sMode") === "2";
  const isAdminSpecialDetail = new URLSearchParams(searchParams).get("sMode") === "3";

  //ถ้าเป็น Banner ไม่ต้อง required
  const objSchema = {
    sType_ID: yupFormSchemas.string("ประเภทแพ็กเกจ", {
      required: true,
      isAdmin: true
    }),
    sPackage_ID: yupFormSchemas.string("เลือกแพ็กเกจ", {
      required: !isBanner,
      isAdmin: true
    }),
    sEmployer_ID: yupFormSchemas.string("ผู้ประกอบการ", {
      required: true,
      isAdmin: true
    }),
    nJobPost: yupFormSchemas.string("จำนวนโพสต์", {
      required: IsJobPost,
      isAdmin: true
    }),
    nCoinAmount: yupFormSchemas.string("จำนวน Coin (ตามแพ็กเกจ)", {
      required: IsnCoin,
      isAdmin: true
    }),
    nCoinFree: yupFormSchemas.string("จำนวน Coin (Free)", {
      required: IsnCoinFree,
      isAdmin: true
    }),
    nExpirey: yupFormSchemas.string("วันหมดอายุ", {
      required: IsExpirey,
      isAdmin: true
    }),
    sNetPrice: yupFormSchemas.string("ราคาหลังคิดส่วนลด", {
      required: IsTotalPrice,
      isAdmin: true
    }),
    nDiscount: yupFormSchemas.string("ส่วนลด", {
      required: IsDiscount,
      isAdmin: true
    }),
    sSpecial_ID: yupFormSchemas.string("ประเภทการอนุญาต", {
      required: isCheckedConsent,
      isAdmin: true
    }),
    sDescription: yupFormSchemas.string("เหตุผลในการขออนุญาต", {
      required: isCheckedConsent && !props.SpecialEdit,
      isAdmin: true
    }),
    sStatus_ID: yupFormSchemas.string("สถานะใบคำสั่งซื้อ", {
      required: (props.nType_ID === 0),
      isAdmin: true
    }),
    sComment: yupFormSchemas.string("หมายเหตุ", {
      // required: props.SpecialEdit || sChangeStatus === 10,
      required: props.SpecialEdit || (props.nType_ID == 0 && sChangeStatus === 10),
      isAdmin: true
    })
  };

  const onBack = () => {
    if (isPause === false) {
      history(`/PausePackageAdd?sID=${encodeURI(sPauseID)}`);
    } else {
      history(-1);
    }
  };

  useEffect(() => {
    GetPermission(33, setPermission, fncDialog);
    OnGetData_DropDown_Employer();
    OnGetData_DropDown_PackageType();
    OnGetData_BannerPackage();
    GetData_DropDown_Special();
    GetData_DropDown_Status();
  }, []);

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  useEffect(() => {
    OnGetData();
  }, [lstStatusAll]);


  useEffect(() => {
    if (!secureLocalStorage.getItem("sUserId")) {
      if (isOrderDetail) {
        history(`/AdminLogin?sID=${encodeURI(sID)}&sMode=1`);
      } else if (isAdminSpecialDetail) {
        history(`/AdminLogin?sID=${encodeURI(sID)}&sMode=3`);
      }

    }
  })
  //step2.2นำข้อมูลมาทำเป็น Yup object
  const schema = yup.object().shape(objSchema);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const OnGetData_DropDown_Employer = () => {
    AxiosGet(sGetEmployer, {}, (result) => {
      setlstEmployer(result.lstOptionEmployer || []);
    },
      (err) => {
        if (!err?.response) {
          history("/error", { replace: true });
        }
      }
    );
  };

  const OnGetData_Email = () => {
    let nID = 0
    if (form.getValues("sEmployer_ID")) {
      nID = form.getValues("sEmployer_ID")
    }
    AxiosGet("AdminOrder/GetData_DataEmail", { nID: nID }, (result) => {
      setEmailcompany(result.objEmail.sEmail);
    });

  }
  const GetData_DropDown_Special = () => {
    AxiosGet(sSpecial, {}, (result) => {
      setlstSpecial(result.lstOptionSpecial || []);
    },
      (err) => {
        if (!err?.response) {
          history("/error", { replace: true });
        }
      }
    );
  };
  const GetData_DropDown_Status = () => {
    AxiosGet(sStatus, {}, (result) => {
      setlstStatusAll(result.lst_Status_Order)
    },
      (err) => {
        if (!err?.response) {
          history("/error", { replace: true });
        }
      }
    );
  };
  const OnGetData_DropDown_PackageType = () => {
    AxiosGet(sPackageType, {}, (result) => {
      //// console.log("OnGetData_DropDown_PackageType", result);
      setlstPackageType(result.lstOptionPackageType || []);
    },
      (err) => {
        if (!err?.response) {
          history("/error", { replace: true });
        }
      }
    );
  };

  const OnGetData_DropDown_Package = () => {
    let sType_ID = form.getValues("sType_ID");

    if (sType_ID === "3") {
      setIsBanner(true);
    } else {
      setIsBanner(false);
      AxiosGet(sPackage, { sID: sType_ID, isEdit: isEditMode }, (result) => {
        setlstPackage(result.lstOptionPackage || [])
      },
        (err) => {
          if (!err?.response) {
            history("/error", { replace: true });
          }
        }
      );
    }
    setisDisabledPackage((sType_ID === "" ? true : false));
  };

  const OnGetData_PricePackage = () => {
    let sPackage_ID = form.getValues("sPackage_ID");
    AxiosPost(sPricePakage, { sPackage_ID: sPackage_ID, sType_ID: form.getValues("sType_ID") }, (result) => {
      form.setValue("nCoinAmount", result.nCoinAmount);
      form.setValue("nJobPost", result.sJobPost);
      form.setValue("nDiscount", result.sDiscount);
      form.setValue("sNetPrice", result.sNetPrice);
      form.setValue("nCoinFree", result.nCoinFree);
      form.setValue("nTotalCoin", result.nTotalCoin);
      form.setValue("sNormalPrice", result.sNormalPrice);
      form.setValue("nExpirey", result.sDate_Expiry);

      setDetail(result);
    },
      (err) => {
        if (!err?.response) {
          history("/error", { replace: true });
        }
      }
    );
  };

  const OnGetData_BannerPackage = () => {
    AxiosPost("AdminUserPackage/SetFilter_TableBannerDataPackage", {}, (result) => {
      setarrDuration(result.lstDuration);
      setarrlstRow(result.lstRow);
      setarrValue(result.lstValue);
    },
      (err) => {
        if (!err?.response) {
          history("/error", { replace: true });
        }
      }
    );
  };

  const handleChangeCheckboxBanner = (e) => {
    let nPosition_ID = +e.target.value.split("_")[0];
    let nBannerPackage_ID = +e.target.value.split("_")[1];
    let objCheck = lstCheck.filter((f) => f.nPosition_ID === nPosition_ID)[0];
    if (objCheck) {
      if (e.target.checked) {
        objCheck.nBannerPackage_ID = nBannerPackage_ID;
      } else {
        objCheck.nBannerPackage_ID = null;
      }
    }
    setlstCheck([...lstCheck]);
    CalGrandTotal();
  };

  const CalGrandTotal = () => {
    let total = 0;
    lstCheck.map((item, index) => {
      let obj = lstarrValue.filter(
        (f) =>
          f.nPosition_ID === item.nPosition_ID &&
          f.nBannerPackage_ID === item.nBannerPackage_ID
      )[0];
      if (obj) {
        total = total + obj.nValue;
      }
    });
    let cal = Math.round(total * 100) / 100;
    detail.sAfterVat = (cal).toLocaleString();

    setDetail(detail);
    form.setValue("sAfterVat", cal);
  };

  const nSumnNetPrice = () => {
    let nJobPostCal = +(form.getValues("nJobPost"));
    let AfterDiscount = +(form.getValues("sNetPrice"));

    let nNetPriceCal = nJobPostCal * AfterDiscount;
    let nVat = ((nNetPriceCal * 7) / 100);
    let nVatCoin = ((AfterDiscount * 7) / 100);
    let nPriceAfterVat = nNetPriceCal + nVat;
    let nPriceAfterVatCoin = AfterDiscount + nVatCoin;

    //2 digit
    var txt = /\B(?=(\d{3})+(?!\d))/g;
    let nNetPriceTwoDigit = (Math.round(nNetPriceCal * 100) / 100).toFixed(2).replace(txt, ",");

    if (nPackageType === "1") {
      var nVatTwoDigit = (Math.round(nVat * 100) / 100).toFixed(2).replace(txt, ",");
      var nPriceAfterVatTwoDigit = (Math.round(nPriceAfterVat * 100) / 100).toFixed(2).replace(txt, ",");
    } else {
      var nVatTwoDigit = (Math.round(nVatCoin * 100) / 100).toFixed(2).replace(txt, ",");
      var nPriceAfterVatTwoDigit = (Math.round(nPriceAfterVatCoin * 100) / 100).toFixed(2).replace(txt, ",");
    };

    setDetail({ ...detail, sAfterVat: nPriceAfterVatTwoDigit, sTotalPrice: nNetPriceTwoDigit, sVat: nVatTwoDigit })
  };

  const nSumnCoinNetPrice = () => {
    let coinamount = form.getValues("nCoinAmount")
    let coinfree = form.getValues("nCoinFree")

    let Allcoin = coinamount + coinfree;

    setDetail({ ...detail, nTotalCoin: Allcoin })
  };

  const onSubmit = (e, status) => {
    let sTitle = status === "2" ? "ยืนยันการบันทึกข้อมูลใช่หรือไม่" : status === "7" ? "ยืนยันการอนุมัติข้อมูลใช่หรือไม่" : "ยืนยันการส่งกลับแก้ไขข้อมูลใช่หรือไม่";
    dispatch(
      DialogActionCreators.OpenDialogSubmit(sTitle, () => { //Do you want to save ?
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        let sStatus_ID = (status === "2" ? form.getValues("sStatus_ID") : status)

        let objSaveData = {
          nType_ID: isCheckedConsent === true ? 1 : 0, // nType_ID == 2 >> free package | nType_ID == 1 >> special package | nType_ID == 0 >> normal package
          sCompany_ID: form.getValues("sEmployer_ID"),
          sOrder_ID: sID,
          sType_ID: form.getValues("sType_ID"),

          sPackage_ID: form.getValues("sType_ID") === "3" ? null : form.getValues("sPackage_ID"),
          nPackage_ID: form.getValues("sType_ID") === "3" ? null : form.getValues("sPackage_ID"),

          sJobPost: form.getValues("nJobPost") + "",
          nCoinAmount: form.getValues("nCoinAmount"),
          nCoinFree: IsnCoinFree ? form.getValues("nCoinFree") : 0,
          nTotalCoin: detail.nTotalCoin,
          sDate_Expiry: form.getValues("nExpirey") + "",
          sNetPrice: form.getValues("sNetPrice") + "",
          sDiscount: form.getValues("nDiscount") + "",
          sTotalPrice: detail.sTotalPrice + "",
          sVat: detail.sVat + "",
          sAfterVat: isBanner ? form.getValues("sAfterVat") + "" : detail.sAfterVat + "",
          sStatus_ID: sStatus_ID,
          lstBanner: lstCheck,
          arrFile1: arrFile1, //invoice
          arrFile2: arrFile2, //payment
          arrFile3: arrFile3, //tax
          isCheckedConsent: isCheckedConsent,
          sConsent_ID: form.getValues("sSpecial_ID"),
          sComment: form.getValues("sComment"),
          sDescription: form.getValues("sDescription"),
          sEmail: sEmailcompany + "",
          sNormalPrice: form.getValues("sNormalPrice") + "",
        };

        if (parseFloat(objSaveData.sTotalPrice) < 0 || parseFloat(objSaveData.sAfterVat) < 0) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("ไม่สามารถทำการสั่งซื้อได้ เนื่องจากราคารวมทั้งหมดน้อยกว่า 0 บาท") as any
          );
          return;
        }
        if (sStatus_ID === "3" && objSaveData.arrFile1.length == 0) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("กรุณาอัพโหลดเอกสารใบแจ้งหนี้") as any
          );
        } else if (sStatus_ID === "4") {
          if (objSaveData.arrFile1.length == 0 && objSaveData.arrFile2.length == 0) {// wait for confirm payment if no invoice
            dispatch(
              DialogActionCreators.OpenDialogWarning("กรุณาอัพโหลดเอกสารใบแจ้งหนี้และหลักฐานการชำระเงิน") as any
            );
          } else if (objSaveData.arrFile1.length == 0) {// wait for confirm payment if no invoice
            dispatch(
              DialogActionCreators.OpenDialogWarning("กรุณาอัพโหลดเอกสารใบแจ้งหนี้") as any
            );
          } else if (objSaveData.arrFile2.length == 0) {// wait for confirm payment if no payment
            dispatch(
              DialogActionCreators.OpenDialogWarning("กรุณาอัพโหลดหลักฐานการชำระเงิน") as any
            );
          } else {
            AxiosPost(sSave, objSaveData, (result) => {
              dispatch(DialogActionCreators.CloseDialogSubmit() as any);
              ResultAPI(result, "บันทึกข้อมูลสำเร็จ", () => {
                dispatch(DialogActionCreators.OpenDialogSuccess(
                  "บันทึกข้อมูลสำเร็จ",
                  () => onBack()
                ) as any
                );
              });
            },
              (err) => {
                if (parseFloat(objSaveData.sTotalPrice) < 0 || parseFloat(objSaveData.sAfterVat) < 0) {
                  dispatch(
                    DialogActionCreators.OpenDialogWarning("ไม่สามารถทำการสั่งซื้อได้ เนื่องจากราคารวมทั้งหมดน้อยกว่า 0 บาท") as any
                  );
                }
                if (err && err.Message !== "") {
                  dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any);
                }
              }
            );
          }
        } else if (sStatus_ID === "5") {
          if (objSaveData.arrFile1.length == 0 && objSaveData.arrFile2.length == 0 && objSaveData.arrFile3.length == 0) { //close order if no invoice payment and tax
            dispatch(
              DialogActionCreators.OpenDialogWarning("กรุณาอัปโหลดเอกสารใบแจ้งหนี้, หลักฐานการชำระเงิน และใบกำกับภาษี") as any
            );
          } else if (objSaveData.arrFile1.length == 0 && objSaveData.arrFile2.length == 0) { //close order if no invoice and payment
            dispatch(
              DialogActionCreators.OpenDialogWarning("กรุณาอัปโหลดเอกสารใบแจ้งหนี้และหลักฐานการชำระเงิน") as any
            );
          } else if (objSaveData.arrFile2.length == 0 && objSaveData.arrFile3.length == 0) { //close order if no payment and tax
            dispatch(
              DialogActionCreators.OpenDialogWarning("กรุณาอัปโหลดหลักฐานการชำระเงินและใบกำกับภาษี") as any
            );
          } else if (objSaveData.arrFile1.length == 0 && objSaveData.arrFile3.length == 0) {//close order if no invoice and tax
            dispatch(
              DialogActionCreators.OpenDialogWarning("กรุณาอัปโหลดเอกสารใบแจ้งหนี้และใบกำกับภาษี") as any
            );
          } else if (objSaveData.arrFile1.length == 0) {//close order if no invoice
            dispatch(
              DialogActionCreators.OpenDialogWarning("กรุณาอัปโหลดเอกสารใบแจ้งหนี้") as any
            );
          } else if (objSaveData.arrFile2.length == 0) {//close order if no payment
            dispatch(
              DialogActionCreators.OpenDialogWarning("กรุณาอัปโหลดหลักฐานการชำระเงิน") as any
            );
          } else if (objSaveData.arrFile3.length == 0) {//close order if no tax
            dispatch(
              DialogActionCreators.OpenDialogWarning("กรุณาอัปโหลดใบกำกับภาษี") as any
            );
          } else {
            AxiosPost(sSave, objSaveData, (result) => {
              dispatch(DialogActionCreators.CloseDialogSubmit() as any);
              ResultAPI(result, "บันทึกข้อมูลสำเร็จ", () => {
                dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ",
                  () => onBack()
                ) as any);
              });
            },
              (err) => {
                if (parseFloat(objSaveData.sTotalPrice) < 0 || parseFloat(objSaveData.sAfterVat) < 0) {
                  dispatch(
                    DialogActionCreators.OpenDialogWarning("ไม่สามารถทำการสั่งซื้อได้ เนื่องจากราคารวมทั้งหมดน้อยกว่า 0 บาท") as any
                  );
                }
                if (err && err.Message !== "") {
                  dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any);
                }
              }
            );
          }
        } else if (parseFloat(objSaveData.sTotalPrice) < 0 || parseFloat(objSaveData.sAfterVat) < 0) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("ไม่สามารถทำการสั่งซื้อได้ เนื่องจากราคารวมทั้งหมดน้อยกว่า 0 บาท") as any
          );
        } else {

          AxiosPost(sSave, objSaveData, (result) => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            ResultAPI(result, "Save successfully", () => {
              dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ", () => onBack()) as any);
            });
          },
            (err) => {
              if (parseFloat(objSaveData.sTotalPrice) < 0 || parseFloat(objSaveData.sAfterVat) < 0) {
                dispatch(
                  DialogActionCreators.OpenDialogWarning("ไม่สามารถทำการสั่งซื้อได้ เนื่องจากราคารวมทั้งหมดน้อยกว่า 0 บาท") as any
                );
              }
              if (err && err.Message !== "") {
                dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any);
              }
            }
          );
        }
      }) as any
    );
  };

  const OnGetData = () => {
    let obj = {
      sOrder_ID: sID,
    };

    AxiosPost(sDetail, obj, (result) => {
      if (isEditMode) {

        // let percentTwodigit = (Math.round(result.defaultPercent * 100) / 100) + ""
        // setPercent(percentTwodigit);
        setarrFile1(result.arrFile1 || []);
        setarrFile2(result.arrFile2 || []);
        setarrFile3(result.arrFile3 || []);

        setisAddPayment_Invoice(false);
        setisAddPayment_Payment(false);
        setisAddPayment_Tax(false);
        // setWaitforpayment(true);

        if (result.sStatus_ID !== "2" && result.sStatus_ID !== "1") {
          setisDisabled(true);
          // setWaitforpayment(false);
        }
        if (result.sStatus_ID !== "2" && result.sStatus_ID !== "6" && result.sStatus_ID !== "7" && result.sStatus_ID !== "8" && result.sStatus_ID !== "9") {
          setisAddPayment_Invoice(true);
          setisAddPayment_Payment(true);
          // setWaitforpayment(false);
        }
        if (result.sStatus_ID !== "1" && result.sStatus_ID !== "2" && result.sStatus_ID !== "3" && result.sStatus_ID !== "4" && result.sStatus_ID !== "6" && result.sStatus_ID !== "7" && result.sStatus_ID !== "8") {
          setisAddPayment_Tax(true);
          // setWaitforpayment(false);
        }

        if (result.sStatus_ID === "10") {
          setCancel(true);
        }

        if (result.sStatus_ID === "3") {
          setisAddPayment_Invoice(true);
          setisAddPayment_Payment(false);
          setWaitforpayment(true);
        }

        if (result.sStatus_ID === 6) {
          setisAddPayment_Invoice(false);
          setisAddPayment_Payment(false);
          setisAddPayment_Tax(false);
        }


        form.setValue("sType_ID", result.sType_ID);
        OnGetData_DropDown_Package();
        form.setValue("sPackage_ID", result.sPackage_ID);
        form.setValue("sAfterVat", result.sAfterVat);
        form.setValue("sAfterVat", result.sAfterVat);
        setnPackageType(result.sType_ID);
        form.setValue("sTotalPrice", result.sTotalPrice);
        form.setValue("sNetPrice", result.sNetPrice);
        form.setValue("nDiscount", result.sDiscount);
        form.setValue("nJobPost", result.sJobPost);
        form.setValue("nCoinAmount", result.nCoinAmount);
        form.setValue("nCoinFree", result.nCoinFree);
        form.setValue("nTotalCoin", result.nTotalCoin);
        form.setValue("nExpirey", result.sDate_Expiry);
        form.setValue("dEndDate", result.sDate_Expiry);

        form.setValue("sEmployer_ID", result.sCompany_ID);
        form.setValue("sStatus_ID", result.sStatus_ID);
        form.setValue("sComment", result.sComment);
        form.setValue("sDescription", result.sDescription);
        form.setValue("sNormalPrice", result.sNormalPrice);
        setStatus_ID(result.sStatus_ID);
        setEmailcompany(result.sEmail);
        setPause(result.IsPause);

        setisShowPrice(true);
        setDetail(result);
        setisCheckedConsent(result.isCheckedConsent);
        setPauseID(result.sPauseID);

        form.setValue("sSpecial_ID", result.sConsent_ID);

        if (result.arrFile1.length > 0 && result.sStatus_ID === "10") {
          setWaitforpayment(true);
        }

        if (result.sType_ID === "3") {
          setIsBanner(true);
        }

        setSpecialRequest(false);

        if (form.getValues("sSpecial_ID") == 1) {
          setSpecialRequest(true);
          setDisDesc(true);
        } else if (form.getValues("sSpecial_ID") == 2) {
          setRequestPreUsed(true);
          setSpecialRequest(true);
          setDisDesc(true);
        }

        if (isWaitforPayment) {
          setisAddPayment_Invoice(true);
          setisAddPayment_Payment(false);
          setisAddPayment_Tax(false);
        }

        if (statusCancle && isWaitforPayment) {
          setisAddPayment_Invoice(true);
          setisAddPayment_Payment(false);
          setisAddPayment_Tax(false);
        }

        if (statusSpecial.includes(result.sStatus_ID)) {
          if (result.sStatus_ID === "6") {
            let lstStatusTemp = lstStatusAll.filter(w => w.value == result.sStatus_ID || statusCancle.includes(w.value)) //plus cancle status
            setlstStatus(lstStatusTemp || []);
          } else if (result.sStatus_ID === "9") {
            // let lstStatusTemp = lstStatusAll.filter(w => w.value == result.sStatus_ID || w.value == 6) //when reject special request
            let lstStatusTemp = lstStatusAll.filter(w => w.value == result.sStatus_ID || w.value == 6 || statusCancle.includes(w.value)) //when reject special request
            setlstStatus(lstStatusTemp || []);
          } else {
            let lstStatusTemp = lstStatusAll.filter(w => statusNormal.includes(w.value) || w.value == result.sStatus_ID)
            setlstStatus(lstStatusTemp || []);
          }
        } else {
          if (result.sStatus_ID === "2" || result.sStatus_ID === "3") {
            let lstStatusTemp = lstStatusAll.filter(w => statusNormal.includes(w.value) || statusCancle.includes(w.value)) //plus cancle status
            setlstStatus(lstStatusTemp || []);
          } else {
            let lstStatusTemp = lstStatusAll.filter(w => statusNormal.includes(w.value) || w.value == result.sStatus_ID)
            setlstStatus(lstStatusTemp || []);
          }
        }
      } else {
        let lstStatusTemp = lstStatusAll.filter(w => statusNormal.includes(w.value))
        setlstStatus(lstStatusTemp || []);
      }
      setlstCheck(result.lstBanner || []);

      setSpecialRequest(false);

      if (form.getValues("sSpecial_ID")) {
        setSpecialRequest(true);
        setRequestPreUsed(false)

        if (form.getValues("sSpecial_ID") == 2) {
          setRequestPreUsed(true);
        }
      }
    },
      (err) => {
        if (!err?.response) {
          history("/error", { replace: true });
        }
      }
    );
  };

  const ShowDetail_by_Type = () => {
    var txt = /\B(?=(\d{3})+(?!\d))/g;
    if (isBanner) {
      return (
        <TableHead>
          <TableRow>
            <TableCell>ราคารวมทั้งสิ้น</TableCell>
            {(form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "") ?
              <TableCell align="right">{detail.sDefault_AfterVat}</TableCell> : null
            }

            {form.getValues("sSpecial_ID") != 1 ?
              <TableCell align="right">{detail.sAfterVat}</TableCell> :
              <TableCell align="right">
                <InputNumber
                  decimalPoint={2}
                  name={"sAfterVat"}
                />
              </TableCell>
            }
            <TableCell align="left">บาท</TableCell>
          </TableRow>
        </TableHead>
      );
    } else {
      return (
        <TableHead>
          {
            nPackageType === "1" ?
              <TableRow hidden={true}>
                <TableCell>จำนวนโพสต์ <span style={{ color: "red" }}>*</span></TableCell>
                {
                  props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_Job_Post_Amount}</TableCell> :
                    (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_Job_Post_Amount}</TableCell> : null
                }
                <TableCell align="right">
                  <InputNumber
                    name={"nJobPost"}
                    decimalPoint={0}
                    onBlur={nSumnNetPrice}
                    disabled={isRequestPreUsed || form.getValues("sSpecial_ID") != 1 || (isCheckedConsent && (sStatusOrder_ID === "1")) || !isCheckedConsent || nPermission !== 2 || (sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "10")}
                  />
                </TableCell>
                <TableCell align="left">โพสต์</TableCell>
              </TableRow>
              : null
          }
          {nPackageType === "2" ?
            <>
              <TableRow hidden={true}>
                <TableCell>จำนวน Coin (ตามแพ็กเกจ)<span style={{ color: "red" }}>*</span></TableCell>
                {
                  props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_Coint_Amount}</TableCell> :
                    (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_Coint_Amount}</TableCell> : null
                }
                <TableCell align="right">
                  <InputNumber
                    name={"nCoinAmount"}
                    decimalPoint={0}
                    onBlur={nSumnCoinNetPrice}
                    disabled={isRequestPreUsed || form.getValues("sSpecial_ID") != 1 || (isCheckedConsent && (sStatusOrder_ID === "1")) || !isCheckedConsent || nPermission !== 2 || (sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "10")}
                  />
                </TableCell>
                <TableCell align="left">เหรียญ</TableCell>
              </TableRow>
              <TableRow hidden={true}>
                <TableCell>จำนวน Coin (Free)<span style={{ color: "red" }}>*</span></TableCell>
                {
                  props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_CoinFree}</TableCell> :
                    (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_CoinFree}</TableCell> : null
                }
                <TableCell align="right">
                  <InputNumber
                    name={"nCoinFree"}
                    decimalPoint={0}
                    onBlur={nSumnCoinNetPrice}
                    disabled={isRequestPreUsed || form.getValues("sSpecial_ID") != 1 || (isCheckedConsent && (sStatusOrder_ID === "1")) || !isCheckedConsent || nPermission !== 2 || (sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "10")}
                  />
                </TableCell>
                <TableCell align="left">เหรียญ</TableCell>
              </TableRow>
              <TableRow hidden={true}>
                <TableCell>จำนวน Coin<span style={{ color: "red" }}>*</span></TableCell>
                {
                  props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_TotalCoin}</TableCell> :
                    (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_TotalCoin}</TableCell> : null
                }

                {form.getValues("sSpecial_ID") != 2 ? <TableCell align="right">{detail.sTotalCoin}</TableCell> : <TableCell align="right">{detail.sTotalCoin}</TableCell>}
                <TableCell align="left">เหรียญ</TableCell>
              </TableRow>
            </>
            :
            <TableRow hidden={true}>
              <TableCell>จำนวน Coin (ตามแพ็กเกจ)<span style={{ color: "red" }}>*</span></TableCell>
              {
                props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_Coint_Amount}</TableCell> :
                  (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_Coint_Amount}</TableCell> : null
              }
              <TableCell align="right">
                <InputNumber
                  name={"nCoinAmount"}
                  decimalPoint={0}
                  disabled={isRequestPreUsed || form.getValues("sSpecial_ID") != 1 || (isCheckedConsent && (sStatusOrder_ID === "1")) || !isCheckedConsent || nPermission !== 2 || (sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "10")}
                />
              </TableCell>
              <TableCell align="left">เหรียญ</TableCell>
            </TableRow>
          }

          {nPackageType === "1" || nPackageType === "2" ?
            <TableRow hidden={true}>
              <TableCell>วันที่หมดอายุ<span style={{ color: "red" }}>*</span></TableCell>
              {
                props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_Expiry_Duration}</TableCell> :
                  (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_Expiry_Duration}</TableCell> : null
              }
              <TableCell align="right">
                <InputNumber
                  name={"nExpirey"}
                  decimalPoint={0}
                  disabled={isRequestPreUsed || form.getValues("sSpecial_ID") != 1 || (isCheckedConsent && (sStatusOrder_ID === "1")) || !isCheckedConsent || nPermission !== 2 || (sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "10")}
                />
              </TableCell>
              <TableCell align="left">เดือน</TableCell>
            </TableRow>
            : null
          }
          {
            nPackageType === "1" ?
              <TableRow hidden={true}>
                <TableCell>ราคาปกติต่อ 1 Job<span style={{ color: "red" }}>*</span></TableCell>
                {
                  props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sNormalPrice_Default.replace(txt, ",")}</TableCell> :
                    (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sNormalPrice_Default.replace(txt, ",")}</TableCell> : null
                }
                <TableCell align="right">
                  <InputNumber
                    name={"sNormalPrice"}
                    decimalPoint={2}
                    disabled={isRequestPreUsed || form.getValues("sSpecial_ID") != 1 || (isCheckedConsent && (sStatusOrder_ID === "1")) || !isCheckedConsent || nPermission !== 2 || (sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "10")}
                  />
                </TableCell>
                <TableCell align="left">บาท</TableCell>
              </TableRow>
              :
              <TableRow hidden={true}>
                <TableCell>ราคาสุทธิ<span style={{ color: "red" }}>*</span></TableCell>
                {
                  props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sNormalPrice_Default}</TableCell> :
                    (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sNormalPrice_Default}</TableCell> : null
                }
                {form.getValues("sSpecial_ID") != 2 ?
                  < TableCell align="right">
                    <InputNumber
                      name={"sNormalPrice"}
                      decimalPoint={2}
                      disabled={isRequestPreUsed || form.getValues("sSpecial_ID") != 1 || (isCheckedConsent && (sStatusOrder_ID === "1")) || !isCheckedConsent || nPermission !== 2 || (sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "10")}
                    />
                  </TableCell> :
                  <TableCell align="right">
                    <InputNumber
                      name={"sNormalPrice"}
                      decimalPoint={2}
                      disabled={isRequestPreUsed || form.getValues("sSpecial_ID") != 1 || (isCheckedConsent && (sStatusOrder_ID === "1")) || !isCheckedConsent || nPermission !== 2 || (sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "10")}
                    />
                  </TableCell>
                }
                <TableCell align="left">บาท</TableCell>
              </TableRow>
          }
          <TableRow>
            <TableCell>ส่วนลด<span style={{ color: "red" }}>*</span></TableCell>
            {
              props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_Discount.replace(txt, ",")}</TableCell> :
                (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_Discount.replace(txt, ",")}</TableCell> : null

              // props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{sPercent.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell> :
              // (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{sPercent.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell> : null
            }
            <TableCell align="right">
              <InputNumber
                name={"nDiscount"}
                decimalPoint={2}
                disabled={isRequestPreUsed || form.getValues("sSpecial_ID") != 1 || (isCheckedConsent && (sStatusOrder_ID === "1")) || !isCheckedConsent || nPermission !== 2 || (sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "10")}
              />
            </TableCell>
            <TableCell align="left">%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ราคาหลังคิดส่วนลด<span style={{ color: "red" }}>*</span></TableCell>
            {
              props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_NetPrice.replace(txt, ",")}</TableCell> :
                (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_NetPrice.replace(txt, ",")}</TableCell> : null
            }
            <TableCell align="right">
              <InputNumber
                name={"sNetPrice"}
                decimalPoint={2}
                onBlur={nSumnNetPrice}
                disabled={isRequestPreUsed || form.getValues("sSpecial_ID") != 1 || (isCheckedConsent && (sStatusOrder_ID === "1")) || !isCheckedConsent || nPermission !== 2 || (sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "10")}
              />
            </TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
          {
            nPackageType === "1" ?
              <TableRow>
                <TableCell>ราคาสุทธิ (Net)</TableCell>
                {
                  props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_TotalPrice}</TableCell> :
                    (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_TotalPrice}</TableCell> : null
                }

                {form.getValues("sSpecial_ID") != 2 ? <TableCell align="right">{detail.sTotalPrice}</TableCell> : <TableCell align="right">{detail.sDefault_TotalPrice}</TableCell>}

                <TableCell align="left">บาท</TableCell>
              </TableRow> : null
          }
          <TableRow>
            <TableCell>ภาษีมูลค่าเพิ่ม 7%</TableCell>
            {
              props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_Vat}</TableCell> :
                (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_Vat}</TableCell> : null
            }

            {form.getValues("sSpecial_ID") != 2 ? <TableCell align="right">{detail.sVat}</TableCell> : <TableCell align="right">{detail.sDefault_Vat}</TableCell>}

            <TableCell align="left">บาท</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ราคารวมทั้งสิ้น</TableCell>
            {
              props.nType_ID === 1 && (form.getValues("sSpecial_ID") == 1) ? <TableCell align="right">{detail.sDefault_AfterVat}</TableCell> :
                (form.getValues("sSpecial_ID") == 1 && isCheckedConsent && sStatusOrder_ID !== "" && sStatusOrder_ID !== "2") ? <TableCell align="right">{detail.sDefault_AfterVat}</TableCell> : null
            }

            {form.getValues("sSpecial_ID") != 2 ? <TableCell align="right">{detail.sAfterVat}</TableCell> : <TableCell align="right">{detail.sDefault_AfterVat}</TableCell>}

            <TableCell align="left">บาท</TableCell>
          </TableRow>
        </TableHead >
      );
    }
  };

  return (
    <FormProvider {...form}>
      <Card sx={{
        // padding: "2em",
        borderRadius: "1em",
        position: "relative",
        // background: "white"
      }}>

        <Stack sx={{ border: "1px", p: 1, flex: 1, mx: "2%" }}>
          <Grid container justifyContent="center" alignItems="center" spacing={3} >

            <Grid item container spacing={1} xs={12}>
              <Grid item xs={12} sm={12} md={12}>
                <AutoCompleteSelect
                  required
                  name={"sEmployer_ID"}
                  label={"ผู้ประกอบการ"}
                  options={lstEmployer}
                  customValue={{ key: "value" }}
                  onChange={(v) => {
                    form.setValue("sEmployer_ID", v ? v : "");
                    OnGetData_Email()
                  }}
                  disabled={isDisabled || nPermission !== 2}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={1} xs={12}>
              <Grid item xs={12} sm={12} md={12}>
                <AutoCompleteSelect
                  required
                  name={"sType_ID"}
                  label={"เลือกประเภทแพ็กเกจ"}
                  options={lstPackageType}
                  customValue={{ key: "value" }}
                  onChange={(v) => {
                    setDetail({});
                    setisShowPrice(false);
                    OnGetData_DropDown_Package();
                    setnPackageType(v);
                    setIsJobPost((v === 1))
                    setIsCoin((v === 1 || v === 2))
                    setInCoinFree(v === 2)
                    setIsExpirey((v === 1))
                    setIsTotalPrice((v === 1 || v === 2))
                    setIsDiscount((v === 1 || v === 2))
                  }}
                  disabled={isDisabled || nPermission !== 2}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={1} xs={12}>
              <Grid item xs={12} sm={12} md={12}>
                <AutoCompleteSelect
                  required
                  name={"sPackage_ID"}
                  label={"เลือกแพ็กเกจ"}
                  options={lstPackage}
                  customValue={{ key: "value" }}
                  onChange={(v) => {
                    form.setValue("sPackage_ID", v ? v : "");
                    OnGetData_PricePackage();
                    setisShowPrice(true);
                  }}

                  disabled={isDisabled || isDisabledPackage || nPermission !== 2}
                  hidden={isBanner}
                />
              </Grid>
            </Grid>


            <Grid item container spacing={1} xs={12}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox
                      checked={isCheckedConsent}
                      onChange={(v) => {
                        setisCheckedConsent(!isCheckedConsent);
                        setisAddPayment_Invoice(false);
                        setisAddPayment_Payment(false);
                        setisAddPayment_Tax(false);

                        let lstStatusTemp = [];
                        if (v.target.checked) {
                          lstStatusTemp = lstStatusAll.filter(w => w.value === "6")
                        } else {
                          lstStatusTemp = lstStatusAll.filter(w => statusNormal.includes(w.value))
                        }

                        setlstStatus(lstStatusTemp || []);

                        if (!isCheckedConsent) {
                          form.setValue("sStatus_ID", "6");
                        }
                      }}
                      disabled={isDisabled || nPermission !== 2}
                    />
                    }
                      label={"ต้องการขออนุมัติเป็นกรณีพิเศษ"} />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            {
              isCheckedConsent ?
                (
                  <>
                    <Grid item container spacing={1} xs={12}>
                      <Grid item xs={12} sm={12} md={12}>
                        <AutoCompleteSelect
                          required
                          name={"sSpecial_ID"}
                          label={"ขออนุญาตพิเศษ"}
                          options={lstSpecial}
                          customValue={{ key: "value" }}
                          onChange={(v) => {
                            form.setValue("sSpecial_ID", v ? v : "");
                            setRequestPreUsed(false);
                            setisAddPayment_Invoice(false);
                            setisAddPayment_Payment(false);
                            setisAddPayment_Tax(false);
                            if (form.getValues("sSpecial_ID") == 2) {
                              setRequestPreUsed(true);
                              setisAddPayment_Invoice(false);
                              setisAddPayment_Payment(false);
                              setisAddPayment_Tax(false);
                            }
                          }}
                          disabled={isDisabled || nPermission !== 2}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Input name="sDescription" rows={3}
                          required={true}
                          disabled={sStatusOrder_ID === "5" || sStatusOrder_ID === "7" || (isDisDesc && sStatusOrder_ID !== "9") || nPermission !== 2 || props.SpecialEdit}
                          multiline
                          maxLength={5000}
                          label={
                            <>
                              <Typography sx={{ fontWeight: 400 }} component="label">เหตุผลในการขออนุญาต</Typography>
                            </>}
                        />
                      </Grid>
                    </Grid>
                  </>
                )
                : null
            }
            <Grid item container spacing={1} xs={12}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                hidden={isBanner !== true ? true : false}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          background: "#eeeeee",
                          border: " 1px solid #dddddd",
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {i18n(`${i18nField}.Package`)}
                        </TableCell>
                        {arrDuration != null && arrDuration.length > 0
                          ? arrDuration.map((item, index) => {
                            return (
                              <TableCell
                                align="center"
                                key={index}
                                sx={{ border: " 1px solid #dddddd" }}
                              >
                                {item.sName} เดือน
                              </TableCell>
                            );
                          })
                          : null}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {arrlstRow != null && arrlstRow.length > 0
                        ? arrlstRow.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                key={index}
                                align="left"
                                sx={{ border: " 1px solid #dddddd" }}
                              >
                                {item.sName}
                              </TableCell>

                              {arrDuration != null && item.lstValue.length > 0 ? arrDuration.map((itemSub, indexSub) => {
                                let nvar = item.lstValue.find(e => e.nBannerPackage_ID == itemSub.nBannerPackage_ID)
                                return (
                                  <TableCell
                                    key={
                                      nvar.nPosition_ID +
                                      "_" + nvar.nBannerPackage_ID
                                    }
                                    align="center"
                                    sx={{
                                      border: " 1px solid #dddddd",
                                    }}
                                  >
                                    <FormControlLabel
                                      label={nvar.sName}
                                      control={
                                        <Checkbox
                                          disabled={isDisabled || nPermission !== 2}
                                          checked={
                                            lstCheck.filter(
                                              (f) =>
                                                f.nPosition_ID ===
                                                nvar.nPosition_ID &&
                                                f.nBannerPackage_ID ===
                                                nvar.nBannerPackage_ID
                                            )[0]
                                              ? true
                                              : false
                                          }
                                          value={
                                            nvar.nPosition_ID +
                                            "_" +
                                            nvar.nBannerPackage_ID
                                          }
                                          onChange={(e) =>
                                            handleChangeCheckboxBanner(e)
                                          }
                                        />
                                      }
                                    />
                                  </TableCell>
                                )
                              }) : null}
                            </TableRow>
                          );
                        })
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {isShowPrice ?
              <Grid item container spacing={1} xs={12}>
                <Grid item lg={12} xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">{ShowDetail_by_Type()}</Table>
                  </TableContainer>
                </Grid>
              </Grid>
              : props.SpecialEdit ?
                <Grid item container spacing={1} xs={12}>
                  <Grid item lg={12} xs={12}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">{ShowDetail_by_Type()}</Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                : isRequestPreUsed ?
                  <Grid item container spacing={1} xs={12}>
                    <Grid item lg={12} xs={12}>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">{ShowDetail_by_Type()}</Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  : isBanner ?
                    <Grid item container spacing={1} xs={12}>
                      <Grid item lg={12} xs={12}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">{ShowDetail_by_Type()}</Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                    : null
            }
            {isShowPrice || isBanner ?
              <>
                {props.nType_ID === 0 ?
                  <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12} sm={12} md={12}>
                      <AutoCompleteSelect
                        required
                        name={"sStatus_ID"}
                        label={"สถานะใบคำสั่งซื้อ"}
                        options={lstStatus}
                        customValue={{ key: "value" }}
                        onChange={(v) => {
                          setChangeStatus(v)
                          form.setValue("sStatus_ID", v ? v : "");
                          setisAddPayment_Invoice(false);
                          setisAddPayment_Payment(false);
                          setisAddPayment_Tax(false);

                          if (v !== "2" && v !== "1" && v !== "6" && v !== "7" && v !== "8" && v !== "9" && v !== "10") {
                            setisAddPayment_Invoice(true);
                            setisAddPayment_Payment(true);
                            setWaitforpayment(false);
                          }
                          if (v !== "1" && v !== "4" && v !== "2" && v !== "3" && v !== "6" && v !== "7" && v !== "8" && v !== "9" && v !== "10") {
                            setisAddPayment_Tax(true);
                            setWaitforpayment(false);
                          }
                          if (v === "3") {
                            setisAddPayment_Invoice(true);
                            setisAddPayment_Payment(false);
                            // setisAddPayment_Tax(false);
                            setWaitforpayment(true)
                          }
                          if (v === "4") {
                            setisAddPayment_Invoice(true);
                            setisAddPayment_Payment(true);
                            setisAddPayment_Tax(false);
                            setWaitforpayment(false)
                          }
                          if (v === "5") {
                            setWaitforpayment(false)
                          }
                          if (isWaitforPayment && v === "10") {
                            // if (v === "10") {
                            setisAddPayment_Invoice(true);
                            setisAddPayment_Payment(false);
                            setisAddPayment_Tax(false);
                            setInvoid(true);
                            setCancel(true);
                            // }
                          }

                        }}
                        disabled={sStatusOrder_ID === "5" || isDisabledPackage || nPermission !== 2 || sStatusOrder_ID === "10"}
                      />
                    </Grid>
                  </Grid>
                  : null
                }
              </>
              : null
            }

            {/* wait for payment approve (sStatusID 2) */}
            {isAddPayment_Invoice ? (sStatusOrder_ID === "9" || sStatusOrder_ID === "1" || (sStatusOrder_ID === "10" && !isWaitforPayment)) ? null :
              // {/* {isAddPayment_Invoice ? (sStatusOrder_ID === "9" || sStatusOrder_ID === "1" || (sStatusOrder_ID === "10" && !isInvoid || isWaitforPayment)) ? null : */}
              (
                <Grid item lg={12} xs={12} >
                  <UploadFile
                    disabled={nPermission !== 2 || sStatusOrder_ID === "5" || sStatusOrder_ID === "10"}
                    id="Upload_Invoice"
                    keyID={1}
                    sTitle={"เอกสารใบแจ้งหนี้"}
                    IsRequired={true}
                    arrFile={arrFile1 || []}
                    setarrFile={setarrFile1}
                    IsFolder={false}
                    IsFile={true}
                    IsMultiple={false}
                    Extension={[...Extension.ImagePDF]}
                    IsDrag={false}
                    nLimitFile={5}
                    isSetupEmployerBanner={false}
                    isSetupEmployerLogo={false}
                  />
                </Grid>
              ) : null
            }
            {isAddPayment_Payment ? (sStatusOrder_ID === "9" || sStatusOrder_ID === "1" || sStatusOrder_ID === "10") ? null :
              (
                <Grid item lg={12} xs={12} >
                  <UploadFile
                    disabled={nPermission !== 2 || sStatusOrder_ID === "5" || sStatusOrder_ID === "10"}
                    id="Payment_Pic"
                    keyID={2}
                    sTitle={"หลักฐานการชำระเงิน"}
                    IsRequired={true}
                    arrFile={arrFile2 || []}
                    setarrFile={setarrFile2}
                    IsFolder={false}
                    IsFile={true}
                    IsMultiple={false}
                    Extension={[...Extension.ImagePDF]}
                    IsDrag={false}
                    nLimitFile={5}
                    isSetupEmployerBanner={false}
                    isSetupEmployerLogo={false}
                  />
                </Grid>
              ) : null
            }
            {isAddPayment_Tax ? (sStatusOrder_ID === "9" || sStatusOrder_ID === "1" || sStatusOrder_ID === "10") ? null :
              (
                <Grid item lg={12} xs={12} >
                  <UploadFile
                    disabled={nPermission !== 2 || isWaitforPayment || sStatusOrder_ID === "10" || sStatusOrder_ID === "5"}
                    id="Upload_Tax"
                    keyID={3}
                    sTitle={"ใบกำกับภาษี"}
                    IsRequired={true}
                    arrFile={arrFile3 || []}
                    setarrFile={setarrFile3}
                    IsFolder={false}
                    IsFile={true}
                    IsMultiple={false}
                    Extension={[...Extension.ImagePDF]}
                    IsDrag={false}
                    nLimitFile={5}
                    isSetupEmployerBanner={false}
                    isSetupEmployerLogo={false}
                  />
                </Grid>
              ) : null
            }
            {/* {sStatusOrder_ID === "6" || sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "9" || sChangeStatus === 10 || sStatusOrder_ID === "10" ? */}
            {sStatusOrder_ID === "6" || sStatusOrder_ID === "7" || sStatusOrder_ID === "8" || sStatusOrder_ID === "9" || sStatusOrder_ID === "10" || sChangeStatus == 10 ?
              <Grid item container spacing={1} xs={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <Input name="sComment" rows={3}
                    required={true}
                    disabled={nPermission == 2 ? isSpecialRequest ? sStatusOrder_ID === "10" ? true : false : (props.nType_ID == 0 && sChangeStatus !== 10) ? false : true : true}
                    multiline
                    maxLength={5000}
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">หมายเหตุ</Typography>
                    }
                  />
                </Grid>
              </Grid>
              : null
            }

            {/* phone size */}
            <Hidden smUp>
              {
                sStatusOrder_ID === "5" ? null :
                  props.nType_ID === 1 ?
                    <>
                      <Grid
                        container

                        justifyContent="space-between"
                        alignItems="flex-start"
                        sx={{ mt: "4%" }}
                      >
                        <Grid item sx={{ ml: "8%" }} md={6} justifyContent={"start"}>
                          <BtnReject
                            txt={"ส่งกลับแก้ไข"} //pending invoice
                            IsDisabled={nPermission !== 2}
                            onClick={form.handleSubmit((e) => {
                              onSubmit(e, "9");
                            })}
                            IsHisabled={sStatusOrder_ID === "5"}
                          />
                        </Grid>
                        <Grid item md={6} justifyContent={"end"}>
                          <BtnApprove
                            txt={"อนุมัติ"} //pending invoice
                            IsDisabled={nPermission !== 2}
                            onClick={form.handleSubmit((e) => {
                              // onSubmit(e, "8");
                              onSubmit(e, (form.getValues("sSpecial_ID") ? form.getValues("sSpecial_ID") == 1 ? "8" : "7" : "2")); //special request status 1 = request edit package price | special request status 2 = preuse package
                            })}
                            IsHisabled={sStatusOrder_ID === "5"}
                          />
                        </Grid>
                      </Grid>
                      <Grid item sx={{ ml: "1%", padding: 0 }} xs={12} justifyContent={"start"}>
                        <BtnBack
                          txt={"ย้อนกลับ"}
                          onClick={() => onBack()} />
                      </Grid>
                    </>
                    :
                    <Fragment>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-start"
                        sx={{ mt: "4%" }}
                      >
                        <Grid item sx={{ ml: "5%" }} md={6} justifyContent={"start"}>
                          <BtnBack
                            txt={"ย้อนกลับ"}
                            onClick={() => onBack()} />
                        </Grid>
                        {sStatusOrder_ID == "10" ?
                          null
                          :
                          <Grid item md={6} justifyContent={"end"}>
                            <BtnSubmit
                              txt={"บันทึก"}//pending invoice
                              IsDisabled={nPermission !== 2}
                              onClick={form.handleSubmit((e) => {
                                onSubmit(e, (form.getValues("sSpecial_ID") ? sStatusOrder_ID !== "6" ? sStatusOrder_ID === "9" ? sChangeStatus == 10 ? "10" : "6" : "2" : "10" : "2"));
                                // onSubmit(e, (form.getValues("sSpecial_ID") ? sStatusOrder_ID !== "6" ? sStatusOrder_ID === "9" ? "6" : "2" : "10" : "2"));
                              })}
                              IsHisabled={false}
                            />
                          </Grid>
                        }
                      </Grid>
                    </Fragment>
              }
              {/* </Grid> */}
            </Hidden>

            {/* normal size */}
            <Hidden smDown>
              {
                sStatusOrder_ID === "5" ?
                  <Grid item md={12} xs={6} justifyContent={"start"}>
                    <Box sx={{ flex: 1 }}>
                      <BtnBack

                        txt={"ย้อนกลับ"}
                        onClick={() => onBack()} />
                    </Box>
                  </Grid> : sStatusOrder_ID === "10" ?
                    <Grid item md={12} xs={12} justifyContent={"start"}>
                      <Box sx={{ flex: 1 }}>
                        <BtnBack
                          txt={"ย้อนกลับ"}
                          onClick={() => onBack()} />
                      </Box>
                    </Grid> :
                    <Grid item md={6} xs={6} justifyContent={"start"}>
                      <Box sx={{ flex: 1 }}>
                        <BtnBack
                          txt={"ย้อนกลับ"}
                          onClick={() => onBack()} />
                      </Box>
                    </Grid>
              }

              {
                sStatusOrder_ID === "5" ? null :
                  props.nType_ID === 1 ?
                    <Fragment>
                      <Grid item container md={5} xs={5} justifyContent={"end"} >
                        <BtnReject
                          txt={"ส่งกลับแก้ไข"} //pending invoice
                          IsDisabled={nPermission !== 2}
                          onClick={form.handleSubmit((e) => {
                            onSubmit(e, "9");
                          })}
                          IsHisabled={sStatusOrder_ID === "5"}
                        />

                      </Grid>
                      <Grid item container md={1} xs={3} justifyContent={"end"}>
                        <BtnApprove
                          txt={"อนุมัติ"} //pending invoice
                          IsDisabled={nPermission !== 2}
                          onClick={form.handleSubmit((e) => {
                            //  onSubmit(e, "8");
                            onSubmit(e, (form.getValues("sSpecial_ID") ? form.getValues("sSpecial_ID") == 1 ? "8" : "7" : "2")); //special request status 1 = request edit package price | special request status 2 = preuse package
                          })}
                          IsHisabled={sStatusOrder_ID === "5"}
                        />
                      </Grid>
                    </Fragment>
                    : sStatusOrder_ID == "10" ?
                      null :
                      <Grid item container md={6} xs={6} justifyContent={"end"}>
                        <BtnSubmit
                          txt={"บันทึก"}//pending invoice
                          IsDisabled={nPermission !== 2}
                          onClick={form.handleSubmit((e) => {
                            // onSubmit(e, (form.getValues("sSpecial_ID") ? sStatusOrder_ID !== "6" ? sStatusOrder_ID === "9" ? "6" : "2" : "10" : "2"));
                            onSubmit(e, (form.getValues("sSpecial_ID") ? sStatusOrder_ID !== "6" ? sStatusOrder_ID === "9" ? sChangeStatus == 10 ? "10" : "6" : "2" : "10" : "2"));
                          })}
                          IsHisabled={false}
                        />
                      </Grid>
              }
            </Hidden>

          </Grid>
        </Stack >

      </Card >
    </FormProvider >
  );
}
