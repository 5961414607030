import { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonBase,
  Grid,
  Pagination,
  Paper,
  Skeleton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import LogoBPS from "assets/images/BPS-Logo.png";
// import LogoBPS from "ClientApp/public/wwwroot/UploadFile/images/BPS-Logo.png"
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import { Link } from "react-router-dom";
import { i18n } from "i18n";
import CriteriaSearch from "components/SearchJob/CriteriaSearch";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import HistoryIcon from "@mui/icons-material/History";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { EPageType } from "components/SearchJob/CriteriaSearch";
import secureLocalStorage from "react-secure-storage";
import { Pagination_Custom } from "components/Pagination/Pagination";


const ControllerAPI = "MyJob";
const i18nForthisPage = "myJobInvitation.";
const MyJobInvite = () => {
  const language = secureLocalStorage.getItem("language").toString();
  moment.locale(language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const oFormRef = useRef() as any;
  const [lstJob, setJob] = useState([]);
  const [nCount, setCount] = useState(0);
  const [NewsPage, setNewsPage] = useState() as any;
  const [Page, setPage] = useState(1);
  const [arrJobDetail, setArrJobDetail] = useState([]);
  const [arrJobFun, setArrJobFun] = useState([]);
  const [permission, setPermission] = useState(0);

  const [objPaging, setPaging] = useState({
    // nPage: 1,
    // nPageSize: 1000,
    nTotal: 0,
  });
  const [isLoading, setLoading] = useState(false);

  const GetJob = (param, nPageParam = null) => {
    setLoading(true);
    let nPageFocus = nPageParam ? nPageParam : Page
    if (nPageFocus != Page) {
      setPage(nPageFocus)
    } else {
      param = {
        nPage: nPageFocus,
        ...param,
      };
      AxiosPost(
        `${ControllerAPI}/SearchJobInvite`,
        param,
        (res) => {
          setJob([...res.lstJob]);

          setPaging({
            nTotal: res.nTotal,
          });
          setArrJobFun(res.lstJobFunction);
          setArrJobDetail(res.lstJobFunctionDetail)


          let nCountPage = (res.nTotal) / 10;
          let nPageNews = Math.ceil(nCountPage);
          setNewsPage(nPageNews);
          setCount(nCountPage);
        },
        null,
        () => setLoading(false)
      );
    }
  };

  const PER_PAGE = 10;
  const count = NewsPage;
  const _DATA = Pagination_Custom(NewsPage || [], PER_PAGE);

  //pagination
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    if (oFormRef.current != null) {
      window.scrollTo({
        top: oFormRef.current.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const onSubmit = (sID, isAccept) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nForthisPage}confirm.${isAccept ? "accept" : "decline"}`),
        async (c) => {
          let request = {
            sID: sID,
            isAccept: isAccept
          }
          dispatch(DialogActionCreators.LoadSubmit(true) as any);
          await AxiosPost(`${ControllerAPI}/SubmitJobInvite`, request, (res) => {
            if (res.Status === 200) {
              dispatch(
                DialogActionCreators.OpenDialogSuccess(
                  i18n(`common.msgAlertSuccess`),
                  () => GetJob({})
                ) as any
              );
            }
          },
            null,
            () => {
              dispatch(DialogActionCreators.LoadSubmit(false) as any);
            }
          );
        }
      ) as any
    );
  };

  useEffect(() => {
    GetPermission(15, setPermission, fncDialog);

    const setDataOnPageLoad = async () => {
      await GetJob({});
    };
    setDataOnPageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  return (
    <>
      <Typography variant="h5" sx={{ mb: "10px", mt: "10px", fontWeight: 500 }}>
        {i18n(`common.myJobInvitation`)}
      </Typography>
      <CriteriaSearch
        onSearch={GetJob}
        pageType={EPageType.Invitation}
        page={Page}
      />
      <Typography variant="subtitle1" gutterBottom>
        {isLoading ? (
          <Skeleton width={"20%"} />
        ) : (
          i18n(`searchJob.foundData`, objPaging.nTotal)
        )}
      </Typography>
      <Grid container spacing={3}>
        {isLoading ? (
          <ComponentSkeletonSearch />
        ) : (
          lstJob &&
          lstJob.map((item) => {
            return (
              <Grid item xs={12} key={item.sEncryptJobPostID}>
                <Paper
                  sx={{
                    p: 2,
                    margin: "auto",
                    flexGrow: 1,
                    cursor: "pointer",
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  }}
                  onClick={() => {
                    navigate("/JobDetail?sID=" + item.sEncryptJobPostID);
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item sx={{ flexGrow: 1 }} xs container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Link
                            to={`/JobDetail?sID=${item.sEncryptJobPostID}`}
                            onClick={(e) => e.stopPropagation()}
                            className="txt-underline"
                          >
                            <Typography variant="subtitle1" component="span">
                              {item[`sJob_${language}`]}
                            </Typography>
                          </Link>
                          <Typography
                            variant="body2"
                            sx={{ marginBottom: "1rem" }}
                          >
                            {item.sCompanyName}
                          </Typography>

                          {arrJobFun.filter((f) => f.sCompanyPostId == item.sEncryptJobPostID).map((m) => (
                            <>
                              <Grid item xs={12} display={"flex"}>
                                <Typography variant="body1">
                                  <AssignmentIndIcon />
                                  {m[`sJobFunction${language}`]},

                                  {arrJobDetail.filter((De) => De.sCompanyPostId == m.sCompanyPostId && De.nJobFunctionID == m.nJobFunctionID).map((Detail) => (
                                    <>
                                      {Detail[`sJobDetail${language}`]}
                                    </>
                                  ))}
                                </Typography>
                              </Grid >
                            </>

                          ))}
                          {/* {item[`lstJobFunction_${language}`] &&
                            item[`lstJobFunction_${language}`].map(
                              (job, indxjob) => (
                                <Typography variant="body1" key={indxjob}>
                                  <AssignmentIndIcon />
                                  {job}
                                </Typography>
                              )
                            )} */}
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom variant="body2" color="red">
                            {`${item.sSalaryStart} - ${item.sSalaryEnd} ${item[`sCurrency_${language}`]
                              }`}
                          </Typography>
                          <Typography variant="body2">
                            {moment(item.dPostDate).fromNow()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container direction="column" spacing={2}>
                        <Grid item textAlign={"right"}>
                          {item.isConfirm === null ? (
                            <>
                              <BtnAccept
                                onClick={(e) => {
                                  onSubmit(
                                    item.sEncryptCompanyInvitationID,
                                    true
                                  );
                                  e.stopPropagation();
                                }}
                              />{" "}
                              <BtnDecline
                                txt={i18n(`${i18nForthisPage}btn.btnDecline`)}
                                onClick={(e) => {
                                  onSubmit(
                                    item.sEncryptCompanyInvitationID,
                                    false
                                  );
                                  e.stopPropagation();
                                }}
                              />
                            </>
                          ) : (
                            <Typography
                              variant="body2"
                              sx={{
                                color: item.isConfirm ? "#70B603" : "#D9001B",
                              }}
                            >
                              {`${i18n(
                                `${i18nForthisPage}${item.isConfirm ? "acceptAt" : "declineAt"
                                }`
                              )} ${moment(item.dConfirmDate).format(
                                "DD/MM/yyyy"
                              )}`}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item textAlign={"right"}>
                          <ButtonBase
                            sx={{ width: 86, height: 86 }}
                            onClick={(e) => {
                              navigate(
                                `/Guest/Company?sID=${item.sEncryptCompanyID}`
                              );
                              e.stopPropagation();
                            }}
                          >
                            <Img
                              alt="complex"
                              src={
                                item.sUrlComImg !== null &&
                                  item.sUrlComImg !== ""
                                  ? item.sUrlComImg
                                  : LogoBPS
                              }
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={2}>
                            <Grid item>
                              <Typography variant="body2">
                                <EventAvailableIcon htmlColor="#F59A23" />
                                {`${i18n(`${i18nForthisPage}send`)} ${moment(
                                  item.dSendDate
                                ).format("DD/MM/yyyy")}`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              {item[`lstEmpType_${language}`] &&
                                item[`lstEmpType_${language}`].map(
                                  (emp, indxemp) => (
                                    <Typography variant="body1" key={indxemp}>
                                      <HistoryIcon color="primary" />
                                      {emp}
                                    </Typography>
                                  )
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })
        )}
      </Grid>
      {/* {lstJob.length > objPaging.nPageSize && ( */}
      <Grid container justifyContent={"center"} sx={{ marginTop: "3rem" }}>
        <Grid item>
          {/* <Pagination
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            count={Math.ceil(objPaging.nTotal / objPaging.nPageSize)}
            page={objPaging.nPage}
            onChange={(e, page) => setPaging({ ...objPaging, nPage: page })}
          /> */}

          <Pagination
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            count={count}
            page={Page}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      {/* )} */}
    </>
  );
};
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const BtnAccept = (props) => {
  return (
    <Tooltip placement={"top"} title={i18n(`${i18nForthisPage}btn.btnAccept`)}>
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: "rgb(51, 166, 76)",
          ":hover": {
            backgroundColor: "rgb(24, 142, 50)",
          },
        }}
        {...props}
      >
        {i18n(`${i18nForthisPage}btn.btnAccept`)}
      </Button>
    </Tooltip>
  );
};

const BtnDecline = (props) => {
  return (
    <Tooltip placement={"top"} title={i18n(`${i18nForthisPage}btn.btnDecline`)}>
      <Button
        variant="contained"
        size="small"
        sx={{
          color: "white",
          backgroundColor: "#ed3847",
          ":hover": {
            backgroundColor: "rgb(220, 53, 69)",
          },
        }}
        {...props}
      >
        {i18n(`${i18nForthisPage}btn.btnDecline`)}
      </Button>
    </Tooltip>
  );
};
const ComponentSkeletonSearch = () => {
  return (
    <>
      {Array.from(Array(6).keys()).map((item) => (
        <Grid item xs={12} key={item}>
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
              cursor: "pointer",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <Grid container spacing={2}>
              <Grid item sx={{ flexGrow: 1 }} xs container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography variant="subtitle1" component="span">
                      <Skeleton />
                    </Typography>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="body2" color="red">
                      <Skeleton />
                    </Typography>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item textAlign={"right"}>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                  <Grid item textAlign={"right"}>
                    <Skeleton variant="circular" height={86} width={86} />
                  </Grid>
                  <Grid item textAlign={"right"}>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </>
  );
};
export default MyJobInvite;
