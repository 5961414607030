import AdminBannerSearch from "components/Banner/AdminBannerSearch";
import { Grid, Stack } from '@mui/material';
import { BtnRadius } from 'components/Common/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AdminBannerbox from './AdminBannerShowCard';
import { i18n } from "i18n";
import { useEffect, useState } from "react";
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";


const BannerShow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [nPermission, setPermission] = useState(0);

  const onCreate = () => {
    navigate("/AdminBannerAdd");
  }

  useEffect(() => {
    GetPermission(3, setPermission, fncDialog);
  }, []);

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }

  return (
    <Stack sx={{ border: "1px", mx: "2%" }}>
      <Grid container>
        {nPermission !== 2 ? (
          <Grid item md={2} xs={6} >
            <BtnRadius

              onClick={() => onCreate()} txt={'เพิ่ม'} />
          </Grid>
        ) : null}
        <Grid item lg={12} xs={12}>
          <AdminBannerSearch />
        </Grid>
        <Grid item lg={12} xs={12}>
          <AdminBannerbox />
        </Grid>
      </Grid>
    </Stack >

  );
}
export default BannerShow

