import { Grid, Stack, Typography, } from '@mui/material';
import { BtnAddOnTable, BtnRadius } from 'components/Common/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { GetPermission } from 'service/CommonFunction/TS_function';
import FormTablelBannerPackage from '../Banner/FormTablelBannerPackage';
import FormTablelCoinPackage from '../Coin/FormTablelCoinPackage';
import FormTablelJobPackage from '../Job/FormTablelJobPackage';
import { i18n } from 'i18n';
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';


const FormShowListPackage = () => {
    const i18nField = 'entities.Package';
    const history = useNavigate();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [nPermission, setPermission] = useState(0);

    useEffect(() => {
        GetPermission(5, setPermission, fncDialog);
    }, [])

    const fncDialog = () => {
        dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
            navigate("/");
        }) as any)
    }

    return (
        <Grid className="FormShowListPackage">
            <Grid container xs={12} >
                {nPermission == 2 ? (
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item md={2} xs={6} >
                                <BtnRadius
                                    IsDisabled={nPermission !== 2}
                                    onClick={() => { history('/CreatePackage') }} txt={'เพิ่ม'} />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item >
                        <Grid container xs={12} justifyContent="flex-end">

                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12} >
                    <Stack spacing={3} width={"100%"} sx={{ border: "2px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 2, my: 2 }}>
                        <Grid item sx={{ width: "100%", backgroundColor: "#ff6b6b", borderRadius: 5, p: 1 }}>
                            <Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                                แพ็กเกจ JOB POST
                            </Typography>
                        </Grid>
                        <Grid item sx={{ width: "100%" }}>
                            <FormTablelJobPackage />
                        </Grid>
                        <Grid item sx={{ width: "100%", backgroundColor: "#ffd83b", borderRadius: 5, p: 1 }}>
                            <Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                                แพ็กเกจ COIN
                            </Typography>
                        </Grid>
                        <Grid item sx={{ width: "100%" }}>
                            <FormTablelCoinPackage />
                        </Grid>
                        <Grid item sx={{ width: "100%", backgroundColor: "#6ccb77", borderRadius: 5, p: 1 }}>
                            <Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                                แพ็กเกจ BANNER
                            </Typography>
                        </Grid>
                        <Grid item sx={{ width: "100%" }}>
                            <FormTablelBannerPackage />
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default FormShowListPackage
