import { useEffect, useState } from "react";
import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { BtnAddOnTable, BtnEditOnTable } from "components/Common/Button"
import { useDispatch } from "react-redux"
import { Grid } from "@mui/material";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useForm } from "react-hook-form";
import { i18n } from "i18n";
import secureLocalStorage from 'react-secure-storage';
import { DialogActionCreators } from 'store/redux/DialogAlert';


export default function FromMasterTableContent() {
  const i18nField = 'entities.AdminContent';
  const dispatch = useDispatch();
  const history = useNavigate();
  const navigate = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);


  let arrrow = {
    ...initRows,
    sSortExpression: "dCreateDate",
    sSortDirection: "asc"
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "dCreateDate",
  //   sSortDirection: "asc"
  // });

  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  useEffect(() => {
    GetPermission(14, setPermission, fncDialog);
    loadData(dataRow);
  }, []);

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    AxiosPost("BPSAdminContent/SearchData_TableData", { ...p }, (result) => {
      setLoadingTable(false)

      setDataRow({
        ...p,
        arrRows: result.lstContent,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    });
  };

  const goToEdit = (sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    history(`/AdminContent?sID=${encodeURI(sID)}`);
  };

  // filter
  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sPageName" },
    { sTypeFilterMode: "input", sFieldName: "sCreateBy" },
    { sTypeFilterMode: "daterange", sFieldName: "sCreateDate" },
    { sTypeFilterMode: "daterange", sFieldName: "sUpdateDate" },
  ]

  //สร้างตาราง
  const dataColumn: GridColumns = [
    {
      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 100,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          (
            <BtnEditOnTable
              IsDisabled={nPermission !== 2}
              txt={'แก้ไข'}
              onClick={() => goToEdit(item.row.sID, item.row)}
            />
          )
        ];
      },
    },
    {
      field: "nNo",
      headerName: "ลำดับ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      width: 100,
    },
    {
      field: "sPageName",
      headerName: "ชื่อหน้า",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 3,
      minWidth: 600,
    },
    {
      field: "sCreateBy",
      headerName: "ผู้สร้าง",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 1,
      minWidth: 400,
    },
    {
      field: "sCreateDate",
      headerName: "วันที่สร้าง",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 1,
      minWidth: 250,
    },
    {
      field: "sUpdateDate",
      headerName: "วันที่แก้ไข",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 1,
      minWidth: 250,
    }
  ];

  return (
    <Grid container>
      <Table
        isLoading={loadingTable}
        filterField={filter}
        Rows={dataRow}
        Columns={dataColumn}
        OnLoadData={(p) => loadData(p)}
      />
    </Grid>
  );
}