import React, { useEffect, useRef, useState } from 'react'
import { Modal, Box, Tooltip, CircularProgress, Typography, Checkbox } from "@mui/material"
import { Close } from "@mui/icons-material"
import { i18n } from 'i18n';
import { useForm, FormProvider } from "react-hook-form"
import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem';
import { BtnSearch } from "components/Common/Button"
import { AxiosPost } from 'service/CommonFunction/TS_function';
import moment from 'moment';
import { BtnCustomIconWithLoading } from "components/Common/Button"
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import BadgeIcon from '@mui/icons-material/Badge';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PaidIcon from '@mui/icons-material/Paid';
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import secureLocalStorage from 'react-secure-storage';
export const th = {
  search: "ตำแหน่งงาน",
  title: "ส่งคำเชิญ",
  introduction_text: "ข้อความถึงผู้สมัคร",
  currentCoin: "Coin คงเหลือ :",
  packageLabel: "แพ็กเกจ :",
  notPackage: "ไม่พบแพ็กเกจที่เหลืออยู่",
  questionSubmit: "คุณต้องการส่งคำเชิญใช่หรือไม่ ?",
  success: "ส่งคำเชิญสำเร็จ",
  coinNotEnough: "Coin ของคุณไม่เพียงพอ",
  jobseekerDeleted: "บัญชีผู้สมัครงานถูกลบ",
  postNotFound: "ไม่พบ Job Post",
  err: "Request failed with status code Unauthorized",
  notcoin: "ไม่สามารถส่งคำเชิญได้เนื่องจากเหรียญไม่เพียงพอ"
}

export const en = {
  search: "Job Title",
  title: "Invite",
  introduction_text: "personalised message for candidate",
  currentCoin: "Coin balance :",
  packageLabel: "Package :",
  notPackage: "Package not found.",
  questionSubmit: "Do you want to Invite ?",
  success: "Invite success.",
  coinNotEnough: "Coin not enough.",
  jobseekerDeleted: "Jobseeker deleted account.",
  postNotFound: "Jobpost not found.",
  err: "Request failed with status code Unauthorized",
  notcoin: "Unable to send invitation due to insufficient coins."

}

export default function ModalInvite({ isOpen, setOpen, sJsId, setJsId }) {
  useEffect(() => {
    if (isOpen) {
      form.setValue("sIntroductionText", "")
      form.setValue("sSearch", "")
      getCoin()
    }
  }, [isOpen])

  const [sCoinAmountInPackage, setCoinAmountInPackage] = useState("0");
  const [sPackageName, setPackageName] = useState("");
  const [arrSelectPost, setSelectPost] = useState([]);
  const getCoin = () => {
    setLoading(true)
    AxiosPost(`${ControllerApi}/GetCoin`, {}, (res) => {
      if (res.Data) {
        setPackageName(res.Data.sPackageName + "\n")
        setCoinAmountInPackage(res.Data.sCoinAmountInPackage)

      } else {
        setPackageName(i18n(`${i18nField}notPackage`))
        setCoinAmountInPackage("")
      }
      // setCurrentCoin(res.Data)
    }, err => dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any), () => {
      setLoading(false)
    })
  }

  const i18nField = "EmployerSearch.ModalInvite."
  const form = useForm({
    defaultValues: {
      sIntroductionText: "",
      sSearch: ""
    }
  });
  const dispatch = useDispatch();
  const ControllerApi = "EmployerSearch"
  const [isLoading, setLoading] = useState(false);
  const [lstDataJobPost, setDataJobPost] = useState<any[]>([])
  const styles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "10px",
    p: 2,
    "@media screen and (min-width:640px)": {
      width: 600,
    }
  };
  const [objPaging, setPaging] = useState({
    nPage: 1,
    nPageSize: 5,
    nTotal: 0
  });

  const getDataJobPost = () => {
    setLoading(true)
    AxiosPost(`${ControllerApi}/filterJobPost`, { ...objPaging, ...form.getValues(), sId: sJsId }, (res) => {
      setPaging({ ...objPaging, nTotal: res.Data.nCount })
      setDataJobPost(res.Data.lstDataJobPost)
      setSelectPost([])

    }, (err) => {
      dispatch(DialogActionCreators.OpenDialogSuccess(err.Message) as any)
    }, () => {
      setLoading(false)
    })
  }

  const getDataWithLangCode = (objField) => {
    const getLangCode = secureLocalStorage.getItem("language").toString();
    if (!objField) return "";
    if (getLangCode.toLocaleLowerCase() == "th") {
      return objField["sTh"];
    } else {
      return objField["sEn"];
    }
  }

  const _onSelectJobPost = (item) => {
    setSelectPost(prev => {
      if (prev.length > 0 && prev.some(s => s.indexOf(item.sId) !== -1)) {
        prev = prev.filter(s => {
          return !(s.includes(item.sId))
        })
      } else {
        prev.push(item.sId)
      }
      return [...prev]
    })
  }

  const _replaceMessageError = (sMessage) => {
    switch (sMessage) {
      case "Coin not enough.":
        return i18n(`${i18nField}coinNotEnough`)
      case "Jobseeker deleted account.":
        return i18n(`${i18nField}jobseekerDeleted`)
      case "Jobpost not found.":
        return i18n(`${i18nField}postNotFound`)
      default:
        // return i18n(`${i18nField}err`);
        return
    }
  }

  const onSent = () => {
    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}questionSubmit`), () => {
      dispatch(DialogActionCreators.CloseDialogSubmit());
      setLoading(true)
      AxiosPost(`${ControllerApi}/SendInvite`, { sId: sJsId, arrPostId: arrSelectPost, sIntroductionText: form.getValues("sIntroductionText") }, () => {
        dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}success`), () => {
          setOpen(false)
          window.location.reload();
        }));
      },
        (err) => {
          // if (err.StatusCode === 400) {
          //   dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nField}notcoin`), () => {
          //   }) as any)
          //   setLoading(false)
          // } else 
          if (err.StatusCode === 409) {
            dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nField}notcoin`), () => {
            }) as any)
            setLoading(false)
          }
          else {
            dispatch(DialogActionCreators.OpenDialogWarning(err.Message, () => {
            }) as any)
            setLoading(false)
          }

        },
      )
    }) as any)
  }

  useEffect(() => {
    if (sJsId && isOpen) {
      setPaging({
        nPage: 1,
        nPageSize: 5,
        nTotal: 0
      })
      setTimeout(() => {
        getDataJobPost()
      }, 300);
    };
  }, [isOpen])

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setOpen(false)
        setJsId();
      }}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box sx={{ ...styles, width: 350, maxHeight: "80vh", overflow: "hidden" }} className={" flex flex-col"} >
        <div className="flex flex-row ">
          <div className="flex  ">
            <Typography variant='h6' >{i18n(`${i18nField}title`)}</Typography>
          </div>
          <div className="flex flex-[1] justify-end  ">
            <Tooltip title={i18n(`common.close`)}>
              <div onClick={() => {
                setOpen(false)
              }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                <Close fontSize='small' className='m-[auto] text-[white]' />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className='flex flex-col gap-[10px] my-[1em] overflow-hidden whitespace-pre-wrap'>
          <FormProvider {...form}>
            <div className="flex flex-row gap-[10px] px-[2px] py-[8px] ">
              <InputCustomLabel
                name={"sIntroductionText"}
                fullWidth
                multiline
                label={i18n(`${i18nField}introduction_text`)}
                rows={2}
                maxLength={1000}
              />
            </div>
            <div className="flex flex-row gap-[10px] px-[2px] py-[8px] ">
              <InputCustomLabel name='sSearch' onKeyPress={(e) => {
                if (e.charCode == 13) {
                  getDataJobPost();
                }
              }} fullWidth label={i18n(`${i18nField}search`)} />
              <BtnSearch txt={i18n("common.search")} onClick={() => getDataJobPost()} />
            </div>
            <div
              className={`flex flex-col gap-[10px] overflow-auto pr-[15px]  ${isLoading && "justify-center items-center"}`}>
              {isLoading ? (
                <div className="flex flex-row border rounded border-[#f1f1f1] min-h-[100px] w-full justify-center items-center ">
                  <CircularProgress />
                </div>
              ) : lstDataJobPost.map((m, i) => (
                <div
                  onClick={() => {
                    _onSelectJobPost(m)
                  }}
                  className={`w-full flex flex-col border border-[#d1d1d1] p-[10px] rounded-[1em] cursor-pointer ${arrSelectPost.some(s => s.indexOf(m.sId) !== -1) ? "!bg-[#f7f7f7]" : "hover:bg-[#f7f7f7]"} `} key={`card_item_jobpost_${i}`}>
                  <div className='flex flex-row items-center '>
                    <Checkbox sx={{ p: 0, mr: 1 }} size="small" checked={arrSelectPost.some(s => s.indexOf(m.sId) !== -1)} />
                    <label>{getDataWithLangCode(m.objJobName)}</label>
                  </div>
                  {m.lstWorkFuctionAndDetail.map((items, index) => (<div key={`job_function_${index}`} className='flex flex-row items-center'>
                    <AssignmentIndIcon className='!text-[1em]' /><span className="text-[0.8em]">{getDataWithLangCode(items) && getDataWithLangCode(items.objWorkDetail) ? `${getDataWithLangCode(items)} > ${getDataWithLangCode(items.objWorkDetail)}` : getDataWithLangCode(items)}</span>
                  </div>))}

                  <div className='flex flex-row items-center'>
                    <span className="text-[1em] text-[#ff3939]">{m.sSalaryStart == m.sSalaryEnd ? m.sSalaryStart : `${m.sSalaryStart} - ${m.sSalaryEnd}`} {i18n("common.baht")}</span>
                  </div>
                  <div className='flex flex-row gap-[5px] flex-wrap'>
                    <div className='flex flex-row gap-[3px]'>
                      <WorkIcon className='!text-[1em]' /><span className="text-[0.8em] ">{m.nExpStart == m.nExpEnd ? m.nExpStart : `${m.nExpStart} - ${m.nExpEnd}`}{` ${i18n("common.Year")}`}</span>
                    </div>
                    <div className='flex flex-row gap-[3px]'>
                      <SchoolIcon className='!text-[1em]' /><span className="text-[0.8em] ">{getDataWithLangCode(m.objEducation)}</span>
                    </div>
                    <div className='flex flex-row gap-[3px]'>
                      <BadgeIcon className='!text-[1em]' /><span className="text-[0.8em] ">{getDataWithLangCode(m.objJobAllow) ? getDataWithLangCode(m.objJobAllow) : i18n(`EmployerSearch.Info.none`)}</span>
                    </div>
                    <div className='flex flex-row '>
                      <PaidIcon className='!text-[1em]' /><span className="text-[0.8em] ">{getDataWithLangCode(m.objSalaryType) ? getDataWithLangCode(m.objSalaryType) : i18n(`EmployerSearch.Info.none`)}</span>
                    </div>
                  </div>
                  <div className='flex flex-row gap-[5px] flex-wrap	'>
                    {m.lstEmploymentType.map((items, i) => {
                      return (<div key={`tag_filter_${i}`} className="px-[3px] py-[4px] bg-[#1976d2] rounded border flex">
                        <span className="text-[0.6em] font-[600] text-[white]">{getDataWithLangCode(items)}</span>
                      </div>)
                    })}
                  </div>
                  <div className='flex flex-row border-b border-b-[#f1f1f1] my-[5px]  ' />
                  <div className='flex flex-row'>
                    <span className="text-[0.8em] text-[#a1a1a1] ">{i18n("common.LastUpdate")} {moment(m.dPostDate).fromNow()}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className='flex flex-col justify-center items-center '>
              {sPackageName && <span className='text-[12px]  self-start '>{i18n(`${i18nField}packageLabel`)} {sPackageName}</span>}
              {<span className='text-[12px]  self-start '>{i18n(`${i18nField}currentCoin`)} {sCoinAmountInPackage}</span>}
              <div>
                <BtnCustomIconWithLoading
                  txt={`${arrSelectPost.length} Coin`}
                  isLoading={isLoading}
                  sxCustom={{
                    borderRadius: "20px",
                    backgroundColor: arrSelectPost.length == 0 ? "#d1d1d1" : "#ffa500",
                    ":hover": {
                      backgroundColor: arrSelectPost.length == 0 ? "#d1d1d1" : "#b37400",
                    }
                  }}
                  sIconComponent={<MonetizationOnIcon />}
                  onClick={() => {
                    if (arrSelectPost.length > 0) {
                      onSent();
                    }
                  }}
                />
              </div>
            </div>
          </FormProvider>
        </div>

      </Box>
    </Modal>
  )
}