import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnViewOnTable } from "components/Common/Button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AxiosPost,
  GetPermission,
  ResultAPI,
} from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { Stack, Grid } from "@mui/material";
import AdminCompanyTabPanel from "./AdminCompanyTabPanel";
import { i18n } from "i18n";
import secureLocalStorage from 'react-secure-storage';


export default function AdminCompanyOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const [PriceTotal, setPriceTotal] = useState([]);
  const [Status, setStatus] = useState([]);

  const [loadingTable, setLoadingTable] = useState(true);
  const i18nField = 'entities.Admincompany';
  const [permission, setPermission] = useState(0);


  let arrrow = {
    ...initRows,
    sSortExpression: "sOrder_Purchase_Date",
    sSortDirection: "desc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "sOrder_Purchase_Date",
  //   sSortDirection: "desc",
  // });


  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sOrder_Number" },
    { sTypeFilterMode: "daterange", sFieldName: "sOrder_Purchase_Date" },
    { sTypeFilterMode: "input", sFieldName: "sPakageType" },
    { sTypeFilterMode: "input", sFieldName: "sOrder_Package_Name" },
    {
      sTypeFilterMode: "select", sFieldName: "sOrder_Total", lstDataSelect:
        [...PriceTotal]
    },
    { sTypeFilterMode: "input", sFieldName: "sUser_Name" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect:
        [...Status],
    },
  ];


  useEffect(() => {
    GetPermission(2, setPermission, fncDialog);

    loadData(dataRow);
  }, []);


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    const objFilter = { ...p, sID: sID };
    AxiosPost("AdminCompany/search_order", objFilter, (result) => {
      setLoadingTable(false);
      setPriceTotal(result.lstPrice)
      setStatus(result.lstStatus)
      setDataRow({
        ...p,
        arrRows: result.lstData,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        }
      }
    );
  };

  const onEdit = (sCompanyID: string, sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    navigate(`/AdminCompanyOrderDetail?sID=${encodeURI(sCompanyID)}&sOrderID=${encodeURI(sID)}`
    );
  };

  const dataColumn: GridColumns = [
    {
      field: "ปุ่ม (ดูรายละเอียด)",
      headerName: "",
      type: "actions",
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          <BtnViewOnTable
            txt="ดูรายละเอียด"
            onClick={() => onEdit(item.row.sCompanyID, item.row.sID, item.row)}
          />,
        ];
      },
    },
    {
      field: "sOrder_Number",
      headerName: "เลขที่คำสั่งซื้อ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      minWidth: 200,
      flex: 1
    },
    {
      field: "sOrder_Purchase_Date",
      headerName: "วันที่สั่งซื้อ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      minWidth: 150,
      flex: 1
    },
    {
      field: "sPakageType",
      headerName: "ประเภทแพ็กเกจ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      minWidth: 200, flex: 1
    },
    {
      field: "sOrder_Package_Name",
      headerName: "แพ็กเกจ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      minWidth: 350,
      flex: 1
    },
    {
      field: "sOrder_Total",
      headerName: "ราคารวม",
      headerAlign: "center",
      align: "right",
      resizable: false,
      sortable: true,
      minWidth: 150,
      flex: 1
    },
    {
      field: "sUser_Name",
      headerName: "ผู้ที่สร้างรายการ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      minWidth: 300,
      flex: 1
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      minWidth: 250,
      flex: 1
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mx: "2%" }}>
        <AdminCompanyTabPanel tabNo={3} />
        <Grid
          sx={{
            marginTop: "2%",
          }}
        ></Grid>
        <Table
          isLoading={loadingTable}
          filterField={filter}
          Rows={dataRow}
          Columns={dataColumn}
          OnLoadData={(p) => loadData(p)}
        />
      </Grid>
    </Grid>
  );
}
