import { Card, Divider, Grid, Typography } from "@mui/material"
import WorkExperienceNew from "./WorkExperienceNew"
import MyJobPositonNew from "./MyJobPositonNew";
import { useLocation, useNavigate } from 'react-router-dom';
import { i18n } from "i18n";
import MyProfileDayStart from "./MyProfileDayStart";
import PanelProfile from "./PanelProfile";
import MyAddressNew from "./MyAddressNew";
import EducationHistoryNew from "./EducationHistoryNew";
import MyCertificateNew from "./MyCertificateNew";
import PanelAbility from "./PanelAbility";
import LanguageSkillNew from "./LanguageSkillNew";
import MyComputerSkillNew from "./MyComputerSkillNew";
import PanelDriving from "./PanelDriving";
import { useEffect, useRef, useState } from "react";
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { useDispatch } from "react-redux";


export default function MyProfileEdit() {
  const navigate = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sMode');
  const sJobApply = new URLSearchParams(searchParams).get('sJobApply');
  const sIDNew = new URLSearchParams(searchParams).get('sIDNew');
  const i18nField = "entities.SeekerProfile";
  const myRef = useRef(null)
  const [sPostjobID, setsPostjobID] = useState("");
  const [permission, setPermission] = useState(0);

  const onGetDetail = () => {
    BlockUI();
    AxiosPost("JobDetail/GetCompanyDetailEnc", { sID: sIDNew }, (res) => {
      UnBlockUI();
      if (res.Status === 200) {
        setsPostjobID(res.sJobPostID);
      } else {
        dispatch(DialogActionCreators.OpenDialogError(res.Message) as any);
      }
    },
      (err) => {
        UnBlockUI();
        if (err.Status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        } else {
          dispatch(DialogActionCreators.OpenDialogError(err.Message) as any);
        }
      }
    );
  };

  useEffect(() => {
    GetPermission(69, setPermission, fncDialog)

    onGetDetail();
    setTimeout(() => {
      myRef.current.scrollIntoView(
        {
          // block: "start",
          // inline: "start",
          behavior: "smooth",
        })
    }, 300);
  }, [])


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
      UnBlockUI();
    }) as any)
  }


  const Onback = (objProfile, objJobDetail) => {
    navigate(`/JobApply?sJobDetailID=${sPostjobID}`, {
      state: {
        objProfile: objProfile,
        objJobDetail: objJobDetail
      },
    });
  }

  return (
    <>
      <div ref={myRef} className="title-Card"></div>
      <div className="title-CardHeight"></div>
      <Grid container spacing={5} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={7} md={4} >
          <Card
            sx={{
              position: 'relative',
              borderRadius: "30px",
              padding: '15px !important',
              background: "linear-gradient(90deg, rgba(1,128,190,1) 0%, rgba(8,63,136,1) 100%, rgba(0,212,255,1) 100%)"
            }}
          >
            {(sID == "Experience" || sID == "Position" || sID == "DayStartNew") ? (
              <Typography color={"white"} fontWeight={600} style={{ marginLeft: 10 }}>
                {i18n(`${i18nField}.WorkInformation`)}
              </Typography>
            ) : (
              <>
                <Typography color={"white"} fontWeight={600} style={{ marginLeft: 10 }}>
                  {i18n(`${i18nField}.PanelProfile`)}
                </Typography>
              </>)}
          </Card>
        </Grid>
        <Grid item xs={5} md={8}>
          <Divider style={{ backgroundColor: "#cbcccc", height: "2px" }} />
        </Grid>
        <Grid item xs={12} >
          {sID == "Experience" && (<WorkExperienceNew Edit={true} isExpanded={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} sMode="Experience" ProfileID="Experience" onBackEdit={Onback} />)}
          {sID == "Position" && (<MyJobPositonNew Edit={true} isExpanded={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} onBackEdit={Onback} />)}
          {sID == "DayStartNew" && (<MyProfileDayStart Edit={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} onBackEdit={Onback} />)}
          {sID == "PanelProfile" && (<PanelProfile Edit={true} isExpanded={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} onBackEdit={Onback} />)}
          {sID == "MyAddress" && (<MyAddressNew Edit={true} isExpanded={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} onBackEdit={Onback} />)}
          {sID == "Education" && (<EducationHistoryNew Edit={true} isExpanded={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} onBackEdit={Onback} />)}
          {sID == "Certificate" && (<MyCertificateNew Edit={true} isExpanded={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} onBackEdit={Onback} />)}
          {sID == "PanelAbility" && (<PanelAbility Edit={true} isExpanded={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} onBackEdit={Onback} />)}
          {sID == "LanguageSkill" && (<LanguageSkillNew Edit={true} isExpanded={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} onBackEdit={Onback} />)}
          {sID == "ComputerSkill" && (<MyComputerSkillNew Edit={true} isExpanded={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} onBackEdit={Onback} />)}
          {sID == "PanelDriving" && (<PanelDriving Edit={true} isExpanded={true} isJobApply={sJobApply === "sJobApply"} sIDNew={sIDNew} onBackEdit={Onback} />)}

        </Grid>
      </Grid>
    </>
  )

}


