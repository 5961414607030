import React, { useEffect, useState } from "react";
import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { BtnEditOnTable } from "components/Common/Button"
import { useDispatch } from "react-redux"
import { FormControlLabel, Grid, Tooltip } from "@mui/material";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import IOSSwitch from "components/Common/ElementInForm/IosSwitch";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useForm } from "react-hook-form";
import { i18n } from "i18n";
export default function AdminBannerMasterTable() {

  const i18nField = 'entities.Banner';
  const i18nDialog = 'dialog';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const history = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
    sSortExpression: "nNo",
    sSortDirection: "asc"
  });
  const onBack = () => {
    history(-1)
  }

  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  useEffect(() => {
    GetPermission(3, setPermission, fncDialog)
    loadData(dataRow);
  }, []);

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    AxiosPost("FromBanner/SearchData_Bannerdetail", p, (result) => {
      setLoadingTable(false)
      setDataRow({
        ...p,
        arrRows: result.lstBanner,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    });
  };

  const OnDelete = (e) => {
    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

      AxiosPost(`FromBanner/RemoveDataTable`, e, (result) => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        ResultAPI(result, i18n(`${i18nDialog}.DialogRemoveSuccess`), () => {
          dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nDialog}.DialogRemoveSuccess`)) as any)
          loadData(dataRow);
        });
      }, (err) => {
        if (!err.response) {
          history("/error", null)
          return;
        }
      })
    }) as any)
  }

  // filter
  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sEmployer_Name" },
    { sTypeFilterMode: "input", sFieldName: "sPo_Name" },
    { sTypeFilterMode: "input", sFieldName: "sPosition_Name" },
    { sTypeFilterMode: "daterange", sFieldName: "dStartDate" },
    { sTypeFilterMode: "daterange", sFieldName: "dEnd_Date" },
    { sTypeFilterMode: "input", sFieldName: "sBannerLink_Name" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect: [
        { value: "1", keyId: 0, label: i18n(`${i18nField}.ActiveName`) },
        { value: "0", keyId: 1, label: i18n(`${i18nField}.InactiveName`) },
      ],
    },
  ]

  //status 
  const OnStatus = (e, item) => {
    let arrRowcopy = [...dataRow.arrRows]
    let sStatust = arrRowcopy.filter(f => f.nBanner_ID == item.row.nBanner_ID)[0];
    if (sStatust) {
      sStatust.sStatus = e.target.checked
    }

    let oData = {
      sStatus: sStatust.sStatus,
      nBanner_ID: item.row.nBanner_ID,
      nPosition_ID: item.row.nPosition_ID,
      dBanner_StartDate: item.row.dBanner_StartDate,
      dBanner_EndDate: item.row.dBanner_EndDate

    }

    AxiosPost("FromBanner/ToggleActive", oData, (result) => {
      ResultAPI(result, "Saved successfully.", () => {
        loadData(dataRow);
      });
    }, (err) => {
      if (err.Status === 400) {
        dispatch(DialogActionCreators.OpenDialogWarning("ไม่สามารถเพิ่มข้อมูลได้เนื่องจากช่วงเวลาที่ระบุมีจำนวนแบนเนอร์เกินกำหนด", () => {
        }) as any)
      }
      loadData(dataRow);
    });
  }

  //สร้างตาราง
  const dataColumn: GridColumns = [
    {
      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 50,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          (
            <BtnEditOnTable
              txt="แก้ไข"
              onClick={() => goToEdit(item.row.sID, item.row)}
            />
          )
        ];
      },
    },
    {
      field: "nNo",
      headerName: "ลำดับ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      width: 80,
    },
    {
      field: "sEmployer_Name",
      headerName: "ผู้ว่าจ้าง",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 200,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sEmployer_Name}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sEmployer_Name}
              </div>
            </Tooltip>
          )
        ];
      }
    },
    {
      field: "sPo_Name",
      headerName: "หมายเลขใบสั่งซื้อ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "sPosition_Name",
      headerName: "ตำแหน่งโฆษณา",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 180,
    },

    {
      field: "dStartDate",
      headerName: "วันที่เริ่มต้น",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "dEnd_Date",
      headerName: "วันที่หมดอายุ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "sBannerLink_Name",
      headerName: "เชื่อมโยงไปยัง",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 180,
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 180,
      renderCell: (item) => {
        let sStatust = dataRow.arrRows.filter(f => f.nBanner_ID == item.row.nBanner_ID)[0];
        if (sStatust) {
        }
        return (
          <>
            <FormControlLabel
              control={<IOSSwitch disabled={nPermission !== 2} sx={{ m: 1 }} />}
              label={sStatust.sStatus ? "ใช้งาน" : "ไม่ใช้งาน"}
              checked={sStatust.sStatus}
              onChange={
                (e) => { OnStatus(e, item) }
              }
            />
          </>

        );
      },
    }
  ];

  const goToEdit = (sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    history(`/AdminBannerAdd?sID=${encodeURI(sID)}`);
  };

  return (

    <Table
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
      isShowCheckBox={nPermission == 2}
      onDelete={OnDelete}
    />

  );
}


