import React, { useEffect, useState } from "react";
import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { BtnDeleteTable, BtnEditOnTable, BtnTimeTable, BtnViewOnTable } from "components/Common/Button"
import { useDispatch } from "react-redux"
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useForm } from "react-hook-form";
import { i18n } from "i18n";
import { DialogActionCreators } from "store/redux/DialogAlert";
import TransitionsModalPause from "./ModalLogPause";
import ModalPauseCancle from "./ModalCanclePause";
import Tooltip from "@mui/material/Tooltip";
import secureLocalStorage from 'react-secure-storage';


export default function HoldPackageMasterTable() {
  const i18nDialog = 'dialog';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [DeleteTable, setDeleteTable] = useState(false);
  const [sId, setJsId] = useState("");
  const [sOrder, setOrder] = useState("");
  const [Status, setStatus] = useState([]);

  let arrrow = {
    ...initRows,
    sSortExpression: "nNo",
    sSortDirection: "asc"
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //     ...initRows,
  //     sSortExpression: "nNo",
  //     sSortDirection: "asc"
  // });

  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  useEffect(() => {
    GetPermission(54, setPermission, fncDialog)
    loadData(dataRow);
  }, []);


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    AxiosPost("HoldPackage/SearchData_PausePackagedetail", p, (result) => {
      setLoadingTable(false)
      setDeleteTable(result.lstData.isDelete);
      setStatus(result.lstFilterStatus);
      setDataRow({
        ...p,
        arrRows: result.lstData,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,

      });
    });
  };
  //กรุณาตรวจสอบรายการก่อนกดยืนยัน
  const OnDelete = (e) => {
    dispatch(DialogActionCreators.OpenDialogSubmit("กรุณาตรวจสอบรายการก่อนกดยืนยัน", () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

      AxiosPost(`HoldPackage/RemoveDataTablePause`, e, (result) => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        ResultAPI(result, i18n(`${i18nDialog}.DialogRemoveSuccess`), () => {
          dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nDialog}.DialogRemoveSuccess`)) as any)
          loadData(dataRow);
        });
      }, (err) => {
        if (err.Status === 400) {
          dispatch(DialogActionCreators.OpenDialogWarning("ลบข้อมูลไม่ได้เนื่องจากลบได้เฉพาะผู้สร้างข้อมูลเท่านั้น", () => {
          }) as any)
        }
      });
    }) as any)
  }

  // filter
  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sCompany_Name" },
    { sTypeFilterMode: "input", sFieldName: "sOrder_ID" },
    { sTypeFilterMode: "input", sFieldName: "sOrder_Name" },
    { sTypeFilterMode: "daterange", sFieldName: "sStart_Date" },
    { sTypeFilterMode: "daterange", sFieldName: "sEnd_Date_Order" },
    { sTypeFilterMode: "daterange", sFieldName: "sEnd_Date" },
    // { sTypeFilterMode: "input", sFieldName: "sStatus" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect:
        [...Status],
    },
  ]

  //สร้างตาราง
  const dataColumn: GridColumns = [
    {
      headerName: "",
      field: "ลบ/ยกเลิก",
      type: "actions",
      width: 50,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          (
            <BtnDeleteTable
              IsDisabled={nPermission !== 2 || item.row.isCancle === true || item.row.nStatusID === 15}
              txt="ลบ/ยกเลิก"
              onClick={form.handleSubmit(e => { onSubmit(item.row.sID, item.row) })}
            />
          )
        ];
      },
    },
    {
      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 50,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        if (item.row.nStatusID === 14 || item.row.nStatusID === 15) {
          return [
            <BtnViewOnTable
              IsDisabled={nPermission !== 2}
              txt="ดู" //Edit
              onClick={() => goToEdit(item.row.sID, item.row)}
            />
          ];
        } else {
          return [
            <BtnEditOnTable
              IsDisabled={nPermission !== 2}
              txt="แก้ไข" //Edit
              onClick={() => goToEdit(item.row.sID, item.row)}
            />
          ];
        }
      },
    },
    {
      field: "nNo",
      headerName: "ที่",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      width: 80,
    },
    {
      field: "sCompany_Name",
      headerName: "ผู้ประกอบการ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 250,
    },
    {
      field: "sOrder_ID",
      headerName: "เลขที่ใบสั่งซื้อ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "sOrder_Name",
      headerName: "แพ็กเกจ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 180,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sOrder_Name}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sOrder_Name}
              </div>
            </Tooltip>
          )
        ];
      }
    },
    {
      field: "sStart_Date",
      headerName: "เริ่มหยุดใช้งาน",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 170,
    },
    {
      field: "sEnd_Date_Order",
      headerName: "วันที่หมดอายุ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 170,
    },
    {
      field: "sEnd_Date",
      headerName: "กลับมาใช้งาน",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 170,
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 170,
    },
    {
      headerName: "",
      field: "",
      type: "actions",
      width: 50,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          (
            <BtnTimeTable
              IsDisabled={nPermission !== 2 || item.row.isHistory == false}
              txt={"ประวัติ"}
              onClick={() => ModalPause(item.row.sOrder, item.row)}
            />
          )
        ];
      }
    },
  ];
  const [isOpen, setIsOpen] = useState(false)

  const onSubmit = (sID: string, item: any) => {
    setIsOpen(true);
    setJsId(sID)
    setOrder(sId)
  };

  const goToEdit = (sID: string, item: any) => {
    history(`/PausePackageAdd?sID=${encodeURI(sID)}`);
  };

  const [isOpenPause, setIsOpenPause] = useState(false)

  const ModalPause = (sId: string, item: any) => {
    setIsOpenPause(true);
    setJsId(sId)
  };


  return (
    <>
      <Table
        isLoading={loadingTable}
        filterField={filter}
        Rows={dataRow}
        Columns={dataColumn}
        OnLoadData={(p) => loadData(p)}
        //  isShowCheckBox={nPermission === 2}
        // onDelete={OnDelete}
        isRowSelectable={(p) => p.row.isDelete}
      />

      <TransitionsModalPause isOpen={isOpenPause} setOpen={setIsOpenPause} sId={sId} />
      <ModalPauseCancle isOpen={isOpen} setOpen={setIsOpen} sID={sId} sOrder={sOrder} setOrder={setOrder} />
    </>
  );
}


