import { useEffect, useRef, useState } from "react";
import {
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import { i18n } from "i18n";
import CriteriaSearch from "components/SearchJob/CriteriaSearch";
import { useNavigate, useLocation } from "react-router-dom";
import JobTab from "./JobTab";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from 'react-helmet';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useDispatch } from 'react-redux';

const ControllerAPI = "SearchJob";
const i18nForthisPage = "searchJob.";
const i18nLabel = "searchJob.Page";

const SearchJobCOMP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permission, setPermission] = useState(0);
  const location = useLocation();
  const objSearchFromHome = location?.state ? location.state["objSearchFromHome"] : null;
  const [lstJob, setJob] = useState([]);
  const [NewsPage, setNewsPage] = useState() as any;
  const [Page, setPage] = useState(1);
  const [objPaging, setPaging] = useState({ nTotal: 0 });
  const [isLoading, setLoading] = useState(false);
  const [isFindCompanyName, setCompanyName] = useState("");
  const [nPerpageLast, setPerpageLast] = useState(0);
  const [nPerpageFirst, setPerpageFirst] = useState(0);
  const [isShowJobNum, setJobNum] = useState(true);
  const objSchema = {};
  const [objSchemaContext, setSchemaContext] = useState(objSchema);
  const schema = yup.object().shape(objSchemaContext);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: {
      nPage: Page
    } as any
  });

  const GetJob = (param, nPageParam = null) => {
    setLoading(true);
    let nPageFocus = nPageParam ? nPageParam : Page
    if (nPageFocus != Page) {
      setPage(nPageFocus)
    } else {
      param = {
        nPage: nPageFocus,
        ...param,
      };
      AxiosPost(`${ControllerAPI}/SearchJob`, param, (res) => {
        setLoading(false);
        if (res.ShowJoblist == "true") {
          setJobNum(true);
        } else {
          setJobNum(false);
        }

        let nCountPage = (res.nTotal) / 10;
        let nPageNews = Math.ceil(nCountPage);
        setNewsPage(nPageNews);
        setJob([...res.lstJob]);

        setPaging({
          nTotal: res.nTotal,
        });

        let nLastTotal = Page * 10;
        let nFirstTotal = (Page - 1) * 10 + 1;

        if (nFirstTotal < 1) {
          setPerpageFirst(Page);
        } else {
          setPerpageFirst(nFirstTotal)
        }

        if (Page == nPageNews) {
          setPerpageLast(res.nTotal);
        } else {
          setPerpageLast(nLastTotal);
        }
      },
        null
      );
    }
  };

  useEffect(() => {
    if (objSearchFromHome && (objSearchFromHome["arrJobFunctionID"] || objSearchFromHome["arrLocationID"] || objSearchFromHome["sSearch"])) {
      let param = {
        arrJobFunctionID: objSearchFromHome["arrJobFunctionID"],
        arrLocationID: objSearchFromHome["arrLocationID"],
        sSearch: objSearchFromHome["sSearch"]
      };

      setTimeout(() => {
        GetJob(param);
      }, 1000);
    } else if (isFindCompanyName) { // Click at company name
      let param = {
        sSearch: isFindCompanyName
      };
      GetJob(param);
    }
  }, [isFindCompanyName]);

  useEffect(() => {
    GetPermission(16, setPermission, fncDialog);

    setTimeout(() => {
      document.getElementById("scroll_Top").scrollIntoView(true)
    }, 200);
  }, []);


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }

  return (
    <>
      <Helmet>
        <title> {i18n(`${i18nLabel}.Title`)} </title>
      </Helmet>

      <CriteriaSearch
        objSearchFromHome={objSearchFromHome}
        onSearch={(val, nPage) => { GetJob(val, nPage) }}
        page={Page}
        isFindCompanyName={isFindCompanyName}
      />
      <Typography variant="subtitle1" gutterBottom>
        {isLoading ? (
          <Skeleton width={"20%"} />
        ) : isShowJobNum ? (
          i18n(`${i18nForthisPage}perpage`, nPerpageFirst) + " - " +
          i18n(`${i18nForthisPage}perpageLast`, nPerpageLast) +
          i18n(`${i18nForthisPage}foundData`, objPaging.nTotal)
        ) : null}
      </Typography>
      <Grid container spacing={2}>
        <JobTab lstJob={lstJob} isLoading={isLoading} setCompanyName={setCompanyName} NewsPage={NewsPage} Page={Page} setPage={setPage} />
      </Grid>

      {/* <Grid container justifyContent={"center"} spacing={2} sx={{ my: "2%" }}>
        <Pagination
          count={count}
          page={Page}
          color="primary"
          onChange={handleChange}
          showFirstButton
          showLastButton
        />
      </Grid> */}
    </>
  );
};
export default SearchJobCOMP;