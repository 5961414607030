import React, { useEffect, useState } from "react";
import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnEditOnTable } from "components/Common/Button";
import { useDispatch } from "react-redux";
import { FormControlLabel, Grid } from "@mui/material";
import {
  AxiosPost,
  GetPermission,
  ResultAPI,
} from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { i18n } from "i18n";
import secureLocalStorage from 'react-secure-storage';


export default function AdminCondidateViewed() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = useNavigate();
  const i18nField = 'entities.Candidate';
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");

  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);

  let arrrow = {
    ...initRows,
    sSortExpression: "dSend_Date",
    sSortDirection: "desc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName + "4");
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "dSend_Date",
  //   sSortDirection: "desc",
  // });


  //const isHead = nBanner_ID == 6 || nBanner_ID == 8 ? true : false;
  const onBack = () => {
    history(-1);
  };

  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });


  useEffect(() => {
    GetPermission(1, setPermission, fncDialog);

    loadData(dataRow);
  }, []);


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    AxiosPost(
      "AdminCandidate/SearchDataJobInvitation",
      { ...p, sID: sID },
      (result) => {
        setLoadingTable(false);
        setDataRow({
          ...p,
          arrRows: result.lstJobInvitation || [],
          nDataLength: result.nDataLength,
          nPageIndex: result.nPageIndex,
        });
      }
    );
  };

  // filter
  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sNumber_Post" },
    { sTypeFilterMode: "input", sFieldName: "sJob_TH" },
    { sTypeFilterMode: "input", sFieldName: "sCompany_Name" },
    { sTypeFilterMode: "input", sFieldName: "sIndustryType_Name" },
    { sTypeFilterMode: "input", sFieldName: "sComfirm" },
    { sTypeFilterMode: "daterange", sFieldName: "sComfirm_Date" },
    { sTypeFilterMode: "daterange", sFieldName: "sSend_Date" },
    //  { sTypeFilterMode: "input", sFieldName: "sStatus" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect: [
        { value: "1", keyId: 0, label: i18n(`${i18nField}.ActiveName`) },
        { value: "0", keyId: 1, label: i18n(`${i18nField}.InactiveName`) },
      ],
    },
  ];

  //สร้างตาราง
  const dataColumn: GridColumns = [
    {
      field: "sNumber_Post",
      headerName: "เลขที่ประกาศ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      width: 150,
    },
    {
      field: "sJob_TH",
      headerName: "ตำแหน่งงาน",
      headerAlign: "center",
      align: "left",
      resizable: true,
      flex: 1,
      minWidth: 250,
    },
    {
      field: "sCompany_Name",
      headerName: "ชื่อผู้ประกอบการ",
      headerAlign: "center",
      align: "left",
      resizable: true,
      flex: 1,
      minWidth: 250,
    },
    {
      field: "sIndustryType_Name",
      headerName: "ประเภทอุตสาหกรรม",
      headerAlign: "center",
      align: "left",
      resizable: true,
      flex: 1,
      minWidth: 250,
    },
    {
      field: "sComfirm",
      headerName: "ตอบรับ/ปฎิเสธ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      flex: 1,
      minWidth: 130,
    },
    {
      field: "sComfirm_Date",
      headerName: "วันที่ตอบรับ/ปฎิเสธ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      flex: 1,
      minWidth: 130,
    },
    {
      field: "sSend_Date",
      headerName: "ส่งคำเชิญเมื่อ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      flex: 1,
      minWidth: 130,
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      resizable: true,
      minWidth: 120,

    }
  ];

  return (
    <Table
      id="4"
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
    //isShowCheckBox={nPermission == 2}
    //onDelete={OnDelete}
    />
  );
}
