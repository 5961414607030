import { useState, useEffect } from "react";
import {
  Box,
  Step,
  Stepper,
  StepButton,
} from "@mui/material";
import { StepLineColorConnector } from "components/SeekerProfile/ElementCustom";
import ProfileFirst from "./ProfileFirst";
import ProfileProvider from "components/SeekerProfile/Context/ProfileContext";
import { i18n } from "i18n";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import ProfileWorkInformation from "./ProfileWorkInformation";
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const i18nField = "entities.SeekerProfile";
const steps = [
  i18n(`${i18nField}.Step1`),
  i18n(`${i18nField}.Step2`),
];

const ResgisterSeeker = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [isBackStep, setisBackStep] = useState(false);
  const [permission, setPermission] = useState(0);


  useEffect(() => {
    GetPermission(0, setPermission, fncDialog);

    UnBlockUI();
  }, [])


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const renderStep = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return <ProfileFirst onSuccessCreate={handleNext} isBackStep={isBackStep} />;
      case 1:
        return <ProfileWorkInformation onSuccessCreate={handleNext} />;
    }
  };
  const handleStep = (step: number) => () => {
    setActiveStep(step);
    setisBackStep(true)
  };
  return (
    <ProfileProvider>
      <Box sx={{ width: "100%" }}>
        <Stepper
          sx={{ m: "2%" }}
          activeStep={activeStep}
          connector={<StepLineColorConnector />}
          alternativeLabel
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepButton color="inherit" onClick={handleStep(index)}{...labelProps}>{label}</StepButton>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
          </>
        ) : (
          <>
            {renderStep(activeStep)}
          </>
        )}
      </Box>
    </ProfileProvider>
  );
};

export default ResgisterSeeker;
