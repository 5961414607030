import { useState, useEffect, Fragment } from 'react';
import { Box, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { BtnAddOnTable, BtnBack, BtnCancel, BtnRadius, BtnSave } from 'components/Common/Button';
import { AxiosPost, GetPermission, ResultAPI } from 'service/CommonFunction/TS_function';
import { FormProvider, useForm } from 'react-hook-form';
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import BPS_BannerPackageLogs from './BPS_BannerPackageLogs';
import { i18n } from 'i18n';
import InputNumber from "components/Common/ElementInForm/InputNumber";
export interface dataDuration {
    sName: string,
    nBannerPackage_ID?: string,
}

const FormCreateBannerPackage = () => {
    const i18nDialog = 'dialog';
    const location = useLocation();
    const searchParams = location.search;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const history = useNavigate();
    const [loadingTable, setLoadingTable] = useState(true);
    const [arrPositionType, setarrPositionType] = useState([]);
    const [arrDuration, setarrDuration] = useState<dataDuration[]>([]);
    const EditMode = new URLSearchParams(searchParams).get('isEditMode');
    const isEditMode = EditMode ? true : false;
    const [IsDelete, setIsDelete] = useState(false);
    const [nPermission, setPermission] = useState(0);
    const i18nField = 'entities.Package';
    //#region Form Main
    const [objSchemaMain, setobjSchemaMain] = useState({
    });

    const schemaMain = yup.object().shape(objSchemaMain);

    const formMain = useForm({
        resolver: yupResolver(schemaMain),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all"
    });
    //#endregion Form Main

    //#region From second
    const objSchema = {
        sName: yupFormSchemas.integer(i18n(`${i18nField}.DurationMonth`), { required: true, min: 1 }),
    };
    const schema = yup.object().shape(objSchema);

    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all"
    });
    const onBack = () => { history("/ShowListPackage"); }
    //#region event

    useEffect(() => {
        GetPermission(5, setPermission, fncDialog);
        loadData();
        onGetDetail();
    }, [])


    const fncDialog = () => {
        dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
            navigate("/");
        }) as any)
    }



    useEffect(() => {
        if (arrPositionType.length > 0 && arrDuration.length > 0) {
            arrPositionType.map((item, index) => {
                arrDuration.map((itemSub, indexSub) => {
                    objSchemaMain[`ID_${item.nPosition_ID + "_" + itemSub.nBannerPackage_ID}`] = yupFormSchemas.decimal(i18n(`${i18nField}.Price`), { required: true, min: 1 });
                    let objName = item.lstValue.find(f => f.nBannerPackage_ID == itemSub.nBannerPackage_ID)
                    if (objName) {
                        formMain.setValue(`ID_${item.nPosition_ID + "_" + objName.nBannerPackage_ID}`, objName.nValue);
                    }
                })
                setobjSchemaMain({ ...objSchemaMain })
            })
        }
    }, [arrDuration])


    const loadData = () => {
        setLoadingTable(true)
        AxiosPost("AdminUserPackage/SetFilter_TableBannerDataPackage", {}, (res) => {
            setarrPositionType(res.lstRow);
            setLoadingTable(false)
        });
    };

    const onGetDetail = () => {
        if (isEditMode == (true)) {
            AxiosPost("AdminUserPackage/SetFilter_TableBannerDataPackage", {}, (res) => {
                setarrDuration(res.lstDuration);
                setarrPositionType(res.lstRow);

                res.lstRow.map((item, index) => {
                    item.lstValue.map((itemSub, indexSub) => {
                        objSchemaMain[`ID_${item.nPosition_ID + "_" + itemSub.nBannerPackage_ID}`] = yupFormSchemas.decimal(i18n(`${i18nField}.Price`), { required: true, min: 0 });
                        let objName = res.lstDuration.find(f => f.nBannerPackage_ID == itemSub.nBannerPackage_ID)
                        if (objName) {
                            formMain.setValue(`ID_${itemSub.nPosition_ID + "_" + objName.nBannerPackage_ID}`, itemSub.nValue);

                        }
                    })
                    setobjSchemaMain({ ...objSchemaMain })
                })

            });
        }
    };

    const onSubmitDuration = (e) => {
        if (!arrDuration.some(d => d.sName === (e.sName) + "")) {
            let objarrTest2: dataDuration = {
                sName: form.getValues("sName") + "",
                nBannerPackage_ID: e.sName + ""
            }
            setarrDuration([...arrDuration, objarrTest2]);
            form.setValue("sName", "");
        }
        else {
            dispatch(
                DialogActionCreators.OpenDialogWarning(`มีข้อมูลแล้ว กรุณาระบุข้อมูลใหม่`) as any
            );
            return false;
        }
    };

    const onSubmit = (e) => {

        let lstSaveBanner = []
        arrDuration.map((item, index) => {
            let arrSavePrice = []
            arrPositionType.map((itemrPosition, indexrPosition) => {
                arrSavePrice.push({ nPosition_ID: itemrPosition.nPosition_ID, nPackage_Price: e[`ID_${itemrPosition.nPosition_ID + "_" + item.nBannerPackage_ID}`] })
            })
            lstSaveBanner.push({ nDuration: item.sName, IsDelete: IsDelete, lstPositionPackage: arrSavePrice })
        })
        let objSaveData = {
            nType: 3,
            lstSaveBanner
        }
        dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimSave`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            AxiosPost("AdminUserPackage/SaveData_FromCreatePackage", objSaveData, (result) => {
                ResultAPI(result, i18n(`common.AlertConfrimSave`), () => {
                    onBack()
                });
            });
        }) as any);
    };

    const OnDelete = (index, item) => {
        dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nDialog}.DialogRemoveSuccess`)) as any)
            let lstdata = arrDuration.filter(w => w.sName !== item.sName)
            setarrDuration(lstdata)

        }) as any)
    };

    // const onBack = () => {
    //     history(-1)
    // };

    //#endregion event

    return (
        <FormProvider {...formMain}>
            <Grid className="FormCreateBannerPackage" >
                <Stack spacing={2} direction="column" >
                    <Grid item spacing={5} xs={12}>
                        <Grid container spacing={2} xs={12}>
                            <FormProvider {...form}>
                                <Grid item lg={6} xs={11}>
                                    <Input
                                        name={"sName"}
                                        disabled={nPermission !== 2}
                                        fullWidth
                                        maxLength={100}
                                        label={i18n(`${i18nField}.DurationMonth`)}
                                        isNumberOnly
                                    />
                                </Grid>
                                <Grid item lg={6} xs={1} spacing={2} >
                                    <BtnAddOnTable IsDisabled={nPermission !== 2} onClick={form.handleSubmit(e => (onSubmitDuration(e)))} />
                                </Grid>
                            </FormProvider >
                            <Grid item lg={3} xs={5} spacing={2} >
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" sx={{ background: "#eeeeee" }} >{i18n(`${i18nField}.Duration`)}</TableCell>
                                                <TableCell sx={{ background: "#eeeeee" }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {arrDuration.map((item, index) => {
                                                return (
                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                        <TableCell component="th" scope="row" align="center" >{item.sName}  {i18n(`${i18nField}.Month`)}</TableCell>
                                                        <TableCell component="th" scope="row" align="right"  >
                                                            <BtnCancel isCircleWithOutText IsDisabled={nPermission !== 2} onClick={() => OnDelete(index, item)} />
                                                        </TableCell>
                                                    </TableRow>)
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={12} xs={12} >
                        <Grid container spacing={1} >
                            <Grid item md={12} xs={12} >
                                <h3>{i18n(`${i18nField}.Pricename`)}</h3>
                            </Grid>
                            <Grid item md={12} xs={12} sx={{
                                overflow: "scroll",
                                maxWidth: '100%',
                                //    borderRadius: "10px",
                            }}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell component="th" scope="row" align="center"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: "#eeeeee" }}>
                                                    {i18n(`${i18nField}.Package`)}
                                                </TableCell>
                                                {arrDuration.map((item, index) => {
                                                    return (
                                                        <TableCell sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 },
                                                            background: "#eeeeee",
                                                            minWidth: "100px",

                                                        }}
                                                            align="center" key={index}  >
                                                            {item.sName}  {i18n(`${i18nField}.Month`)}
                                                        </TableCell>)
                                                })}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {/* <FormProvider {...formSub}> */}
                                            {arrPositionType.map((item, index) => {
                                                return (<TableRow key={index}>
                                                    <TableCell key={index} align="center"
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: "100px", minWidth: "200px" }}>
                                                        {item.sName}
                                                    </TableCell>
                                                    {arrDuration.map((itemSub, indexSub) => {
                                                        return (<TableCell key={item.nPosition_ID + "_" + itemSub.nBannerPackage_ID} sx={{ minWidth: "100px" }}>
                                                            <InputNumber
                                                                disabled={nPermission !== 2}
                                                                decimalPoint={2}
                                                                name={`ID_${item.nPosition_ID + "_" + itemSub.nBannerPackage_ID}`}
                                                                required
                                                                label={<>
                                                                    <Typography sx={{ fontWeight: 600 }} component="label">{i18n(`${i18nField}.Price`)}</Typography>
                                                                </>}
                                                                labelString={""}
                                                            />
                                                        </TableCell>

                                                        )
                                                    })}

                                                </TableRow>)
                                            })}
                                            {/* </FormProvider > */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item spacing={3} xs={12} >
                        <Stack direction="row" >
                            <Box sx={{ flex: 1 }}>
                                <BtnBack
                                    txt='ย้อนกลับ'
                                    onClick={() => onBack()} />
                            </Box>
                            <BtnSave
                                txt='บันทึก'
                                IsDisabled={nPermission !== 2} onClick={formMain.handleSubmit(e => (onSubmit(e)))}
                            />
                        </Stack>
                    </Grid>
                </Stack>
                <Grid item sx={{ marginTop: "3%" }}>

                </Grid>
            </Grid >
        </FormProvider>

    );
}
export default FormCreateBannerPackage