import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BtnBack } from "components/Common/Button";
import { AxiosGet } from "service/CommonFunction/TS_function";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Paper,
  Link,
} from "@mui/material";
import AdminCompanyTabPanel from "./AdminCompanyTabPanel";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useDispatch } from 'react-redux';


const AdminCompanyOrderDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const history = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const sOrderID = new URLSearchParams(searchParams).get("sOrderID");

  const [detail, setDetail] = useState<{ [key: string]: any }>({});
  const [isBanner, setIsBanner] = useState(false);
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [permission, setPermission] = useState(0);


  const onGetDetail = () => {
    BlockUI();
    AxiosGet("AdminCompany/detail_order", { sID: sID, sOrderID: sOrderID }, (res) => {
      if (res.nPackageType_ID === 3) {
        setIsBanner(true);
      }
      setDetail(res);
      UnBlockUI();
    });
  };

  const onBack = () => {
    history(-1);
  };


  useEffect(() => {
    GetPermission(2, setPermission, fncDialog);

    onGetDetail();
  }, []);


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  const ShowDetail_by_Type = () => {
    if (isBanner) {
      return (
        <TableHead>
          <TableRow>
            <TableCell>ราคารวมทั้งสิ้น</TableCell>
            <TableCell align="right">{detail.sAfterVat}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
        </TableHead>
      );
    } else {
      return (
        <TableHead>

          {detail.nPakageType_ID === EnumPackageOrder.Jobpost ? (
            <>
              <TableRow hidden={true}>
                <TableCell>จำนวน Job Post</TableCell>
                <TableCell align="right">{detail.nPostjob}</TableCell>
                <TableCell align="left">งาน</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>จำนวน Coins (Free)</TableCell>
                <TableCell align="right">{detail.nCoinFree}</TableCell>
                <TableCell align="left">เหรียญ</TableCell>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow >
                <TableCell>จำนวน Coins ตามแพ็กเกจ</TableCell>
                <TableCell align="right">{detail.nCoinAmount}</TableCell>
                <TableCell align="left">เหรียญ</TableCell>
              </TableRow>
              <TableRow >
                <TableCell>จำนวน Coins (Free)</TableCell>
                <TableCell align="right">{detail.nCoinFree}</TableCell>
                <TableCell align="left">เหรียญ</TableCell>
              </TableRow>

              <TableRow >
                <TableCell>จำนวน Coins ทั้งหมด</TableCell>
                <TableCell align="right">{detail.nTotalCoin}</TableCell>
                <TableCell align="left">เหรียญ</TableCell>
              </TableRow>
            </>
          )}

          <TableRow>
            <TableCell>ระยะเวลาแพ็กเกจ</TableCell>
            <TableCell align="right">{detail.nDuration}</TableCell>
            <TableCell align="left">เดือน</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{detail.nPakageType_ID === EnumPackageOrder.Jobpost ? "ราคาปกติต่องาน" : "ราคา"}</TableCell>
            <TableCell align="right">{detail.sPrice}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>ส่วนลด</TableCell>
            <TableCell align="right">{detail.sDiscount}</TableCell>
            <TableCell align="left">%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ราคาสุทธิ</TableCell>
            <TableCell align="right">{detail.sNetPrice}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ภาษีมูลค่าเพิ่ม 7%</TableCell>
            <TableCell align="right">{detail.sVat}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ราคารวมทั้งสิ้น</TableCell>
            <TableCell align="right">{detail.sAfterVat}</TableCell>
            <TableCell align="left">บาท</TableCell>
          </TableRow>
        </TableHead >
      );
    }
  };

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <AdminCompanyTabPanel tabNo={3} />
        <Stack
          spacing={2}
          width={"100%"}
          sx={{
            marginTop: "2%",
            border: "2px #eeeeee solid",
            backgroundColor: "rgba(255,255,255,0.75)",
            borderRadius: 5,
            p: 3,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1">ข้อมูลคำสั่งซื้อ</Typography>
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid>
            <Grid container>
              <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography fontSize={20} fontWeight={500} color={"#277cd8"}>{detail.sPomotionName}</Typography>
                </Grid>
                <Stack direction={"column"} flex={1} >
                  <Stack direction={"column"} alignItems="start" sx={{ mt: "0px !important", gap: "5px" }} >
                    {detail.sPakageType} {detail.lstOrder_Package_Name ? (detail.lstOrder_Package_Name).map((m, index) =>
                      <Typography key={index} fontWeight={400} variant="subtitle2" >{m}<br /></Typography>) : null}
                  </Stack>
                </Stack>

              </Grid>
              <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    {ShowDetail_by_Type()}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                <Typography variant="subtitle1">หมายเหตุ</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="subtitle1">{detail.sNote}</Typography>
              </Grid>
              <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
                <Typography variant="subtitle1">เอกสาร</Typography>
              </Grid>
              {detail.lstOrderFile != null && detail.lstOrderFile.length > 0
                ? detail.lstOrderFile.map((item, index) => {
                  return (
                    <Grid key={index} item md={12} xs={12}>
                      <Link href={item.sUrl}>- {item.sFileName}</Link>
                    </Grid>
                  );
                })
                : "-"}
            </Grid>
          </Grid>
        </Stack>
        <Grid
          item
          xs={12}
          justifyContent={"start"}
          marginTop={3}
          marginBottom={3}
        >
          <BtnBack
            txt="ย้อนกลับ" onClick={() => onBack()} />
        </Grid>
      </Grid>
    </Grid>

  );
};
export default AdminCompanyOrderDetail;
export const enum EnumPackageOrder {
  Jobpost = 1,
  Coin = 2,
  Banner = 3,
}