import { useEffect, useState } from "react";
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import JobPostModify from "components/JobPost/JobPostModify"

const JobPostModifyView = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [permission, setPermission] = useState(0);

   useEffect(() => {
      GetPermission(73, setPermission, fncDialog);
   }, []);

   const fncDialog = () => {
      dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
         navigate("/");
      }) as any)
   }

   return (
      <JobPostModify />
   )
}

export default JobPostModifyView