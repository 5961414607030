import { Box, Grid } from "@mui/material"
import { i18n } from "i18n";
import "./ServicesBPS.css";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import FilterTabsSevice from "./FilterTabs";
import JobPostService from "./JobPostService";
import BuyServicesBPS from "./BuyServicesBPS";
import CoinService from "./CoinService";

import { Helmet } from 'react-helmet';
import BannerService from "./BannerService";
import CardTitleServiceBPS from "./CardTitleServiceBPS";
import { GetPermission } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const ServicesBPS = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permission, setPermission] = useState(0);
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sID');
  const i18nField = "OrderEmployer";
  const i18nFieldPage = "OrderEmployer.Page";
  const myRef = useRef(null)
  const [valueTabs, setvalueTabs] = useState(0)

  const onChangeTabs = (ele, v) => {
    setvalueTabs(v)
  }

  useEffect(() => {
    GetPermission(71, setPermission, fncDialog);
  }, [])


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }



  return (
    <>
      <Box style={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> {i18n(`${i18nFieldPage}.Title`)} </title>
        </Helmet>
      </Box>
      {sID && permission !== 0 ? (

        <Grid item >
          <BuyServicesBPS />
        </Grid>
      ) : (
        <>
          <CardTitleServiceBPS />
          <Grid item md={12} >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              className="grid-tap"
            >
              <FilterTabsSevice handleChange={onChangeTabs} value={valueTabs} />
            </Grid>
          </Grid>

          <Grid item xs={12} >
            <Box sx={{ mx: "3%", display: "row" }} >
              {valueTabs == 0 && (
                <JobPostService />
              )}
              {valueTabs == 1 && (
                <CoinService />
              )}
              {valueTabs == 2 && (
                <BannerService />
              )}
            </Box>
          </Grid>

        </>
      )
      }
    </>
  )

}
export default ServicesBPS