import { useEffect, useState } from "react";
import {
  Button,
  ButtonBase,
  Grid,
  Pagination,
  Paper,
  Skeleton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
// import LogoBPS from "ClientApp/public/wwwroot/UploadFile/images/BPS-Logo.png"
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import { i18n } from "i18n";
import CriteriaSearch, { EPageType } from "components/SearchJob/CriteriaSearch";
import { useNavigate } from "react-router-dom";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import JobTab from "./JobTab";
import secureLocalStorage from "react-secure-storage";

const i18nField = "entities.MyJobInterest";
const i18nForthisPage = "searchJob.";

const MyJobInterested = () => {
  const language = secureLocalStorage.getItem("language").toString().toUpperCase();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [permission, setPermission] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [arrJobInterested, setArrJobInterested] = useState([]);
  const [objPaging, setPaging] = useState({
    nPage: 1,
    nPageSize: 1000,
    nTotal: 0,
    nSumFetch: 0,
  });
  const [NewsPage, setNewsPage] = useState() as any;
  const [Page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(Page);

  const handleNotInteresed = () => {
    fetchJobInterested({});
  };

  const fetchJobInterested = (param) => {
    setLoading(true);
    param = {
      ...objPaging,
      ...param,
    };
    AxiosPost(
      "MyJob/GetMyJobInterest",
      param,
      (res) => {
        if (res.Data) {
          const jobItems = res.Data.items || [];
          const totalRec = res.Data.nTotalRecord || 0;
          const totalItem = res.Data.nTotalItem || 0;
          if (currentPage < objPaging.nPage) {
            setArrJobInterested(() => [...arrJobInterested, ...jobItems]);
          } else {
            setArrJobInterested(() => jobItems);
          }

          setCurrentPage(objPaging.nPage);
          setPaging({
            ...objPaging,
            nTotal: totalRec,
            nSumFetch: objPaging.nSumFetch + totalItem,
          });
        }
      },
      null,
      () => setLoading(false)
    );
  };

  const onApplyJpb = (sJobPostId, sCompanyId) => {
    //ส่งไปหน้า Job Apply ก่อน
    let objJobDetail = {
      sID: sJobPostId || "",
      isApply: true,
      sFromPage: "MyJobInterested",
      sCompanyID: sCompanyId,
    };

    navigate("/JobApply", {
      state: {
        objJobDetail: objJobDetail,
      },
    });
  };

  const onDisterest = (sInterestId, sCompanyId) => {
    const param = {
      sInterestId: sInterestId,
      sCompanyId: sCompanyId,
    };
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nField}.Confirm.DisInterest`),
        async (c) => {
          dispatch(DialogActionCreators.LoadSubmit(true) as any);
          await AxiosPost(
            `MyJob/DisInterest`,
            param,
            (res) => {
              if (res.StatusCode === 200) {
                dispatch(
                  DialogActionCreators.OpenDialogSuccess(
                    i18n(`common.msgAlertSuccess`),
                    () => fetchJobInterested({})
                  ) as any
                );
              }
            },
            null,
            () => {
              dispatch(DialogActionCreators.LoadSubmit(false) as any);
            }
          );
        }
      ) as any
    );
  };

  useEffect(() => {
    GetPermission(15, setPermission, fncDialog);

    const setDataOnPageLoad = async () => {
      await fetchJobInterested({});
    };
    setDataOnPageLoad();

  }, []);

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  return (
    <>
      <Typography variant="h5" sx={{ mb: "10px", mt: "10px", fontWeight: 500 }}>
        {i18n(`common.myJobInterested`)}
      </Typography>
      <CriteriaSearch
        onSearch={(Val) => fetchJobInterested(Val)}
        // onSearch={fetchJobInterested}
        pageType={EPageType.Interested}
        page={Page}
      // page={objPaging.nPage}
      />
      <Typography variant="subtitle1" gutterBottom>
        {isLoading ? (
          <Skeleton width={"20%"} />
        ) : (
          i18n(`searchJob.foundData`, objPaging.nTotal)
        )}
      </Typography>
      {/* phase 2 */}
      {/* <Typography variant="subtitle1" gutterBottom>
        {isLoading ? (
          <Skeleton width={"20%"} />
        ) : isShowJobNum ? (
          i18n(`${i18nForthisPage}perpage`, nPerpageFirst) + " - " +
          i18n(`${i18nForthisPage}perpageLast`, nPerpageLast) +
          i18n(`${i18nForthisPage}foundData`, objPaging.nTotal)
        ) : null}
      </Typography> */}
      <Grid container spacing={2}>
        <JobTab
          lstJob={arrJobInterested}
          isLoading={isLoading}
          handleNotInteresed={handleNotInteresed}
          NewsPage={NewsPage}
          Page={Page}
          setPage={setPage}
        />
      </Grid>
    </>
  );
};

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const BtnApplyJob = (props) => {
  return (
    <Tooltip placement={"top"} title={i18n(`${i18nField}.Btn.BtnApply`)}>
      <Button
        variant="contained"
        size="small"
        sx={{
          color: "black",
          backgroundColor: "#ffa726",
          ":hover": {
            backgroundColor: "#ff7043",
          },
        }}
        {...props}
      >
        {i18n(`${i18nField}.Btn.BtnApply`)}
      </Button>
    </Tooltip>
  );
};

const BtnDisInterest = (props) => {
  return (
    <Tooltip placement={"top"} title={i18n(`${i18nField}.Btn.BtnDisInterest`)}>
      <Button
        variant="contained"
        size="small"
        sx={{
          color: "black",
          backgroundColor: "#f5f5f5",
          ":hover": {
            backgroundColor: "#fafafa",
          },
        }}
        {...props}
      >
        {i18n(`${i18nField}.Btn.BtnDisInterest`)}
      </Button>
    </Tooltip>
  );
};

export default MyJobInterested;