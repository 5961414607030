import { useState, useEffect } from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Divider,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import {
  BtnBack,
  BtnSave,
} from "components/Common/Button";
import secureLocalStorage from "react-secure-storage";
import { initRows, PaginationInterface } from "components/Common/Table/DataGridMui";
import IOSSwitch from "components/Common/ElementInForm/IosSwitch";
import { i18n } from "i18n";

const lang = secureLocalStorage.getItem("language").toString() || "th";

const MemberEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const i18nFieldCommon = "common";
  const i18nField = "CompanyEmployee";
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sID');
  const isCompany = new URLSearchParams(searchParams).get("sMode") === "1";
  const isEditMode = sID ? true : false;
  const [isActive, setIsActive] = useState(true);
  const [nPermission, setPermission] = useState(0);
  const [isPostJob, setPostJob] = useState<boolean>(false);
  const [isJobSeeker, setJobSeeker] = useState<boolean>(false);
  const [isSearchJobSeeker, setSearchJobSeeker] = useState<boolean>(false);
  const [isOrder, setOrder] = useState<boolean>(false);
  const [SelectRole, setSelectRole] = useState("");
  const [sMemberName, setMemberName] = useState("");
  const [sMemberEmail, setMemberEmail] = useState("");
  const [sMemberPhone, setMemberPhone] = useState("");
  const [sStatus, setStatus] = useState("");

  const onBack = () => {
    navigate(-1)
  }

  const [objSchema, setObjSchema] = useState({
    sGroupName: yupFormSchemas.string("ชื่อกลุ่มผู้ใช้งานระบบ", { required: true, isAdmin: true }),
  });
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all"
  });

  useEffect(() => {
    GetPermission(58, setPermission, fncDialog);
    OnGetData();
  }, [])

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }

  const OnGetData = () => {
    let param = {
      isCompany: isCompany,
      sID: sID
    };
    AxiosPost("CompanyEmployee/Getdata_CompanyEmployee", param, (res) => {
      if (isEditMode) {
        let resData = res.objEmployee;

        setMemberName(resData.sEmpName)
        setMemberEmail(resData.sEmpEmail)
        setMemberPhone(resData.sTel)
        setPostJob(resData.isPostJob);
        setJobSeeker(resData.isJobSeeker);
        setSearchJobSeeker(resData.isSearchJobSeeker);
        setOrder(resData.isOrder);
        setSelectRole(resData.nRole);
        setIsActive(resData.sStatus == "1" || resData.sStatus == "2" ? true : false);
        setStatus(resData.sStatus);
      }
    },
      (err) => {
        if (!err.response) {
          navigate("/error", null);
        }
      }
    );
  };

  const onSubmit = () => {
    let ischeckActive = sStatus == "2" ? 2 : isActive ? 1 : 0

    if (isPostJob == false && isOrder == false && isSearchJobSeeker == false) {
      dispatch(
        DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nFieldCommon}.msgAlertMemberWarning`), //Saved successfully 
          // () => { navigate("/MemberEmployeeTable") }
        ) as any
      );
    } else {
      let objParam = {
        sID: isEditMode ? sID : "",
        nRole: +SelectRole,
        isPostJob: isPostJob,
        isJobSeeker: isJobSeeker,
        isSearchJobSeeker: isSearchJobSeeker,
        isOrder: isOrder,
        // nStatus: isActive ? 1 : 0 //0 = Inactive | 1 = Active | 2 = send email
        nStatus: ischeckActive
      };

      dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nFieldCommon}.AlertConfrimSave`), () => { //Do you want save?
        AxiosPost("CompanyEmployee/SaveData_CompanyEmployee", { ...objParam }, (result) => {
          dispatch(
            DialogActionCreators.OpenDialogSuccess(
              i18n(`${i18nFieldCommon}.msgAlertSuccess`), //Saved successfully 
              () => { navigate("/MemberEmployeeTable") }
            ) as any
          );
        }, (err) => {
          if (err.Message != "") {
            dispatch(
              DialogActionCreators.OpenDialogWarning(
                err.Message
              ) as any
            );
          }
        });
      }) as any
      );
    }
  };

  return (
    <FormProvider {...form}>

      <Grid container spacing={3} sx={{ border: "1px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 1, flex: 1 }}>
        <Grid item xs={12}>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sx={{ mb: "1%" }}>
                <Typography component="label" sx={{ fontWeight: 600 }}>
                  {/*ข้อมูลสมาชิก*/}
                  {i18n(`${i18nField}.memberinfo`)}
                </Typography>
              </Grid>

              <Grid item container xs={12} sx={{ mb: "1%", marginLeft: "10px" }}>
                <Grid item md={2} xs={6}>
                  <Typography fontWeight={500} >
                    {i18n(`${i18nField}.Name`)} {/*ชื่อ-นามสกุล*/}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Typography fontWeight={500}>{sMemberName}</Typography>
                </Grid>
              </Grid>

              <Grid item container xs={12} sx={{ mb: "1%", marginLeft: "10px" }}>
                <Grid item md={2} xs={6}>
                  <Typography fontWeight={500} >
                    {i18n(`${i18nField}.Email`)}  {/*Email*/}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Typography fontWeight={500}>{sMemberEmail}</Typography>
                </Grid>
              </Grid>

              <Grid item container xs={12} sx={{ mb: "1%", marginLeft: "10px" }}>
                <Grid item md={2} xs={6}>
                  <Typography fontWeight={500} >
                    {i18n(`${i18nField}.phonenumber`)}  {/*Phone*/}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Typography fontWeight={500}>{sMemberPhone}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Grid item>
            <Grid container>
              <Grid item xs={12} md={3}>
                <Typography component="label" sx={{ fontWeight: 600 }}>
                  {i18n(`${i18nField}.Role`)} {/*บทบาทหน้าที่*/}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <RadioGroup
                row
                value={SelectRole}
                onChange={(e, v) => {
                  // setRole(v === "1");
                  setSelectRole(v);
                }}
              >
                <Grid item container xs={12} sx={{ mb: "1%", marginLeft: "10px" }}>
                  <Grid item md={2} xs={12}>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      disabled={nPermission != 2 || isCompany == true}
                      label={i18n(`${i18nField}.admin`)} //"ผู้ดูแลระบบ"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      disabled={nPermission != 2 || isCompany == true}
                      label={i18n(`${i18nField}.user`)} //"ผู้ใช้งาน"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormGroup>

          </Grid>
          <Divider />
        </Grid>
        {
          SelectRole == "1" ?
            <Grid item xs={12}>
              <Typography component="label" sx={{ color: "black", fontWeight: 600 }}>
                {i18n(`${i18nField}.adminpermission`)}   {/*ผู้ดูแล Account มีสิทธิ์ในการใช้งานได้ทุกเมนู*/}
              </Typography>
            </Grid>
            :
            <Grid item xs={12}>
              <Typography component="label" sx={{ color: "black", fontWeight: 600 }}>
                {i18n(`${i18nField}.assignpermission`)} {/*กำหนดสิทธิ์ต่างๆให้กับผู้ใช้งาน*/}
                <Typography component="span" sx={{ color: "red" }}>
                  {" * "}
                </Typography>
              </Typography>
              <Grid item container xs={12} sx={{ mb: "1%", marginLeft: "10px" }}>
                <Grid item md={12} xs={12}>
                  <FormGroup>
                    {/* check 1 */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPostJob}
                          disabled={nPermission != 2 || isCompany == true}
                          onChange={() => {
                            setPostJob((prev) => !prev);

                          }}
                        />
                      }
                      label={
                        <Typography>{i18n(`${i18nField}.postjob`)} {/*ประกาศงาน*/}
                          <span style={{ color: "#B3B3B3", fontSize: "13px" }}>{i18n(`${i18nField}.postjobDetail`)}</span> {/*สามารถดู แก้ไข และประกาศงานได้*/}
                        </Typography>
                      }
                    />

                    {/* check 2 */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSearchJobSeeker}
                          disabled={nPermission != 2 || isCompany == true}
                          onChange={() => {
                            setSearchJobSeeker((prev) => !prev);
                            // if (isCheckedConsent2) {
                            //   setErrorConsent(false)
                            // }
                          }}
                        />
                      }
                      label={
                        <Typography>{i18n(`${i18nField}.jobseeker`)} {/*ค้นหาและจัดการผู้สมัครงาน*/}
                          <span style={{ color: "#B3B3B3", fontSize: "13px" }}>{i18n(`${i18nField}.jobseekerDetail`)}</span> {/*สามารถค้นหาผู้สมัครงานและจัดการผู้สมัครงานได้ทั้งเมนูค้นหาผู้สมัครงานและผู้สมัครงาน*/}
                        </Typography>
                      }
                    />

                    {/* check 3 */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isOrder}
                          disabled={nPermission != 2 || isCompany == true}
                          onChange={() => {
                            setOrder((prev) => !prev);
                            // if (isCheckedConsent2) {
                            //   setErrorConsent(false)
                            // }
                          }}
                        />
                      }
                      label={
                        <Typography>{i18n(`${i18nField}.buyandreportpayment`)} {/*ซื้อและแจ้งรายการชำระเงิน*/}
                          <span style={{ color: "#B3B3B3", fontSize: "13px" }}>{i18n(`${i18nField}.buyandreportpaymentDetail`)}</span> {/*สามารถซื้อแพ็กเกจออนไลน์ ดูใบแจ้งข้อมูล สรุปรายละเอียด Account*/}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
        }
        <Grid item xs={12}>
          <Typography>
            <span style={{ fontWeight: 600 }}> {i18n(`${i18nFieldCommon}.status`)} </span>
            <span style={{ color: "red" }}> *</span>
          </Typography>
          <Grid item container xs={12} sx={{ mb: "1%", marginLeft: "10px" }}>
            <Grid item md={12} xs={12}>
              <Typography>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      disabled={nPermission != 2 || isCompany == true}
                      sx={{ m: 1 }}
                      onChange={() => setIsActive(!isActive)}
                      checked={isActive}
                    />
                  }
                  label={isActive ? i18n(`${i18nFieldCommon}.active`) : i18n(`${i18nFieldCommon}.inactive`)}
                />
              </Typography>
            </Grid>
          </Grid>
          <Divider />
        </Grid>

        <Grid item xs={6} justifyContent={"start"}>
          <BtnBack txt={i18n(`${i18nFieldCommon}.back`)} onClick={() => onBack()} />
        </Grid>
        <Grid item container xs={6} justifyContent={"end"}>
          {nPermission == 2 ? <BtnSave
            txt={i18n(`${i18nFieldCommon}.save`)}
            IsDisabled={isCompany == true}
            onClick={() => onSubmit()}
          />
            : null
          }
        </Grid>
      </Grid >

    </FormProvider >
  );
};

export default MemberEdit;