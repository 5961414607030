import { Box, Button, Card, Grid, Hidden, Typography } from "@mui/material";
import { i18n } from "i18n";
import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { useEffect, useState } from "react";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { Add } from "@mui/icons-material";
import { BtnEditOnTable, BtnViewOnTable } from "components/Common/Button";
import { GridColumns } from "@mui/x-data-grid-pro";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from 'react-helmet';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const ControllerAPI = "JobPost";
const i18nForthisPage = "jobPost.ListPage.";

const JobPostList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permission, setPermission] = useState(0);

  const [IsLoadTable, setLoadTable] = useState<boolean>(false);
  const history = useNavigate();
  const language = secureLocalStorage.getItem("language").toString();


  let arrrow = {
    ...initRows,
    // sSortExpression: "sDate_Buy",
    // sSortDirection: "desc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  ////const [dataRow, setDataRow] = useState<PaginationInterface>(initRows);


  const LoadDataTable = (paging: PaginationInterface) => {
    setLoadTable(true);
    AxiosPost(`${ControllerAPI}/GetJobPost`, {
      ...paging,
      dStartDate: paging["dStartDate"] ?
        paging["dStartDate"]
        :
        [null, null],
    }, (res) => {

      let lstData = res.lstData.map((item, indx) => ({
        ...item,
        sID: `JobPostID_${indx}`,
      }));
      setDataRow({
        ...paging,
        arrRows: lstData || [],
        nDataLength: res.nDataLength,
        nPageIndex: res.nPageIndex,
      });
      setLoadTable(false);
    }
    );
  };

  const dataColumn: GridColumns = [
    {
      headerName: "",
      field: i18n(`${i18nForthisPage}dataColumn.ButtonAdd`),
      type: "actions",
      width: 70,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        if (item.row.nStatusID == 1) {
          return [
            <BtnEditOnTable
              txt={i18n(`${i18nForthisPage}dataColumn.Edit`)}
              onClick={() =>
                // history(`/JobPostModify/${item.row.sEncryptID}`)
                history(`/JobPostModify?sID=${(item.row.sEncryptID)}`)
              }
            />,
          ];
        } else {
          return [
            <BtnViewOnTable
              txt={i18n(`${i18nForthisPage}dataColumn.View`)}
              onClick={() =>
                history(`/JobPostModify?sID=${(item.row.sEncryptID)}`)
              }
            />,
          ];
        }

      },
    },
    {
      field: "sNo",
      headerName: i18n(`${i18nForthisPage}dataColumn.No`),
      headerAlign: "center",
      align: "center",
      resizable: false,
      minWidth: 200,
      flex: 2,
    },
    {
      field: `sPosition_${language}`,
      headerName: i18n(`${i18nForthisPage}dataColumn.Position`),
      headerAlign: "center",
      align: "left",
      resizable: false,
      minWidth: 500,
      flex: 2,
    },
    {
      field: "sCandidate",
      headerName: i18n(`${i18nForthisPage}dataColumn.Candidate`),
      headerAlign: "center",
      align: "center",
      resizable: false,
      minWidth: 160,
      flex: 2,
    },
    {
      field: "dStartDate",
      headerName: i18n(`${i18nForthisPage}dataColumn.StartDate`),
      headerAlign: "center",
      align: "center",
      resizable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (item) => {
        return moment(item.row.dStartDate).format("DD/MM/yyyy");
      },
    },
    {
      field: "dEndDate",
      headerName: i18n(`${i18nForthisPage}dataColumn.EndDate`),
      headerAlign: "center",
      align: "center",
      resizable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (item) => {
        return moment(item.row.dEndDate).format("DD/MM/yyyy");
      },
    },
    {
      field: `sStatus_${language}`,
      headerName: i18n(`${i18nForthisPage}dataColumn.Status`),
      headerAlign: "center",
      align: "center",
      resizable: false,
      minWidth: 160,
      flex: 2,
    },
  ];

  const filter: FilterFieldInterface[] = [
    {
      sFieldName: "sSearch", sLabel: i18n(`${i18nForthisPage}dataColumn.No`), sTypeFilterMode: "input",
    },
    {
      sFieldName: `nStatus_ID`,
      sLabel: i18n(`${i18nForthisPage}filter.status`),
      sTypeFilterMode: "select",
      lstDataSelect: [
        { value: 1, keyId: 1, label: i18n(`${i18nForthisPage}filter.Draft`) }, //Draft
        { value: 2, keyId: 2, label: i18n(`${i18nForthisPage}filter.Active`) }, //Active
        { value: 3, keyId: 3, label: i18n(`${i18nForthisPage}filter.Scheduled`) }, //Scheduled
        { value: 4, keyId: 4, label: i18n(`${i18nForthisPage}filter.Deactivate`) }, //Expire
        { value: 7, keyId: 7, label: i18n(`${i18nForthisPage}filter.Expire`) }, //Expire
      ],
    },
    {
      sFieldName: "dStartDate",
      sLabel: i18n(`${i18nForthisPage}filter.date`),
      sTypeFilterMode: "daterange",
    },
  ];

  useEffect(() => {
    LoadDataTable(dataRow);
    GetPermission(26, setPermission, fncDialog);
  }, []);

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }



  return (
    <>
      <Box sx={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> {i18n(`${i18nForthisPage}Title`)} </title>
        </Helmet>
      </Box>


      {/* phone size */}
      <Hidden smUp>
        <Card sx={{
          padding: "2em",
          borderRadius: "1em",
          position: "relative",

        }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>{i18n(`${i18nForthisPage}Header`)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<Add />}
                onClick={() => {
                  history("/JobPostModify");
                }}
                disabled={false}
                variant="contained"
                sx={{ borderRadius: "20px" }}
              >
                {i18n(`${i18nForthisPage}button.btnNewPost`)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Table
                    isLoading={IsLoadTable}
                    filterField={filter}
                    Rows={dataRow}
                    Columns={dataColumn}
                    OnLoadData={(p) => LoadDataTable(p)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Hidden>

      {/* normal size */}
      <Hidden smDown>
        <Card sx={{
          padding: "2em",
          borderRadius: "1em",
          position: "relative",
          mx: "10%"
        }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>{i18n(`${i18nForthisPage}Header`)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<Add />}
                onClick={() => {
                  history("/JobPostModify");
                }}
                disabled={false}
                variant="contained"
                sx={{ borderRadius: "20px" }}
              >
                {i18n(`${i18nForthisPage}button.btnNewPost`)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Table
                    isLoading={IsLoadTable}
                    filterField={filter}
                    Rows={dataRow}
                    Columns={dataColumn}
                    OnLoadData={(p) => LoadDataTable(p)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Hidden>
    </>
  );
};

export default JobPostList;
