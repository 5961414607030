import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { i18n } from "i18n";

import { AxiosGet, AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";

const Adminseting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const [isCheckedConsent, SetIsCheckedConsent] = useState<boolean>(true);
  const [isCheckedUnderage, SetIsCheckedUnderage] = useState<boolean>(true);
  const [dataInfo, setDataInfo] = useState<{ [key: string]: any }>({});
  const [nPermission, setPermission] = useState(0);
  const [isLoadingSentForgot, setIsLoadingSendForgot] = useState<boolean>(false);
  const [isOpenForgot, setOpenForgot] = useState<boolean>(false)
  const [isFormJobSeeker, setIsFormJobSeeker] = useState<boolean>(true);

  useEffect(() => {
    GetPermission(1, setPermission, fncDialog);
    onGetDetail();
  }, []);


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }

  const onGetDetail = () => {
    AxiosGet("AdminCandidate/GetInfo", { sID: sID }, (res) => {

      SetIsCheckedConsent(res.JobSeekerInfo.IsGetnews)
      SetIsCheckedUnderage(res.JobSeekerInfo.IsUnderage)
      setDataInfo(res.JobSeekerInfo);
    });
  };

  const handleChangeDisclosure = (e) => {
    const IsDisclosure = e.target.value === "1" ? true : false;
    const IsGetNews = e.target.value === "1" ? true : false;
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`common.AlertConfrimSave`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          AxiosGet("AdminCandidate/ChangDisclosure",
            { sID: sID, Disclosure: IsDisclosure, Getnews: IsGetNews },
            (res) => {
              dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`common.AlertConfrimDel`), () => {
                dataInfo.IsDisclosure = e.target.value === "1" ? true : false;
                dataInfo.IsGetNews = e.target.value === "1" ? true : false;
                setDataInfo({ ...dataInfo });
              }) as any);
            }
          );
        }
      ) as any
    );
  };

  const onResetPassword = () => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`common.AlertConfrimEdit`),

        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          AxiosGet("AdminCandidate/ResetPassword", { sID: sID }, (res) => {
            dispatch(
              DialogActionCreators.OpenDialogSuccess(
                i18n(`common.AlertConfrimSave`),
              ) as any
            );
          });
        }
      ) as any
    );
  };
  const OnResetPWD = () => {
    let sEmail = dataInfo.sEmail
    if (!sEmail) {
      dispatch(
        DialogActionCreators.OpenDialogWarning(
          "กรุณากรอกอีเมลก่อนรีเซ็ตรหัสผ่าน"
        ) as any
      );
      return;
    }
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        `คุณต้องการเปลี่ยนรหัสผ่านใช่หรือไม่?`,
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          AxiosPost("AdminCandidate/OnResetPWD", { sEmail: sEmail, nJobSeekerID: sID, sResetPWDCode: "" }, (res) => {
            if (res.Status === 200) {
              dispatch(DialogActionCreators.OpenDialogSuccess(
                `อีเมลได้ถูกส่งไปยังผู้ใช้เพื่อยืนยันการรีเซ็ตรหัสผ่าน`
              ) as any
              );

              //   form.reset({ inp_subscribe: "" });
            } else {
              dispatch(DialogActionCreators.OpenDialogWarning(res.Message) as any);
            }
          }
          );
        }
      ) as any
    );
  };

  const onSubmitForgot = () => {
    let sEmail = dataInfo.sEmail
    setIsLoadingSendForgot(true)
    AxiosPost("Authen/SendRequestForgotPw", { isJobSeeker: isFormJobSeeker, inp_email: sEmail }, (res) => {
      dispatch(DialogActionCreators.OpenDialogSuccess("ส่งคำขอสำเร็จ \nโปรดตรวจสอบที่อีเมลของคุณ", () => {
        setOpenForgot(false)
      }) as any)
    }, err => {
      dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any)
    }, () => {
      setIsLoadingSendForgot(false)
    })
  }

  return (
    <Stack
      spacing={2}
      width={"100%"}
      sx={{
        marginTop: "2%",
        border: "2px #eeeeee solid",
        backgroundColor: "rgba(255,255,255,0.75)",
        borderRadius: 5,
        p: 3,
      }}
    >
      <Grid container direction={"column"}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="subtitle1" className="flex sm:flex-1">
            ข้อมูลสำหรับติดต่อ
            {/* <Divider /> */}
          </Typography>
          <Typography variant="subtitle1">
            ปรับปรุงเมื่อ : {dataInfo.sUpdate_Date}{" "}
            เวลา{" "}
            {dataInfo.sUpdate_Time}
            {/* <Divider /> */}
          </Typography>
        </Grid>

        <Divider />
        <Grid container>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <FormControl>
                  <FormLabel id="sData"></FormLabel>
                  <RadioGroup
                    aria-labelledby="Data"
                    //defaultValue="1"
                    value={dataInfo.IsDisclosure === true ? "1" : "2"}
                    name="radio-buttons-group"
                    onChange={handleChangeDisclosure}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="เปิดเผยข้อมูล"
                      disabled
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="ไม่เปิดเผยข้อมูล"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            เปลี่ยนรหัสผ่าน/Change Password
            <Divider />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item lg={2} md={2} xs={6} sx={{ marginTop: "2%" }}>
              <Button
                variant="contained"
                disabled={nPermission !== 2}
                fullWidth
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#0099FF",
                  color: "White",
                  ":hover": { bgcolor: "#cc9900" },
                }}
                onClick={() => onSubmitForgot()}
              >
                {/* Reset Password */}
                {i18n(`entities.UserGroup.ResetSecureCode`)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ mt: "15px", gap: "15px" }}>
          <Typography variant="subtitle1"  >
            การเลือกรับข่าวสารผ่านช่องทางอีเมลของเว็บไซต์ และบริษัทในเครือ
            <Divider />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item md={6} xs={12} sx={{ mt: "15px", gap: "15px" }} >
              <FormControl>
                <FormGroup>
                  <FormControlLabel control={<Checkbox
                    checked={isCheckedConsent}
                    disabled
                  />
                  }
                    label={"รับข่าวสารผ่านช่องทางอีเมลของเว็บไซต์"} />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} >
          <Typography variant="subtitle1"  >
            ความยินยอมจากบิดา/มารดา หรือผู้ปกครอง
            <Divider />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container >
            <Grid item md={6} xs={12} sx={{ mt: "15px", gap: "15px" }}  >
              <FormControl>
                <FormGroup>
                  <FormControlLabel control={<Checkbox
                    checked={isCheckedUnderage}
                    disabled
                  />
                  }
                    label={"ขณะทำรายการมีอายุไม่ต่ำกว่า 20 ปี หรือได้รับความยินยอมจากผู้ปกครอง"} />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};
export default Adminseting;
