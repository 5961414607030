import { useState, useEffect } from 'react';
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, Radio, Stack, Typography } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { BtnBack, BtnSave } from 'components/Common/Button';
import { AxiosGet, AxiosPost, GetPermission, ResultAPI } from 'service/CommonFunction/TS_function';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useDispatch } from "react-redux"
import { FormProvider, useForm } from 'react-hook-form';
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import IOSSwitch from 'components/Common/ElementInForm/IosSwitch';
import {
  DataGridMui as Table,
  initRows,
  PaginationInterface
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { i18n } from 'i18n';
import React from 'react';

export default function BPS_GroupForm() {
  const i18nField = 'entities.GroupForm';
  const i18nCommon = 'common';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sID');
  const isEditMode = sID ? true : false;
  const [isActive, setIsActive] = useState(true);
  const [nPermission, setPermission] = useState(0);
  const [dataRow, setDataRow] = useState<PaginationInterface>({
    ...initRows,
  });
  const [objStateContent, setObjStateContent] = useState({
    nTypeContent_1: 1
  });
  const [lstComponent, setLstComponent] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [lstCheck, setLstCheck] = useState([]);
  const history = useNavigate();
  const [lstCheckbox, setlstCheckbox] = useState([]);
  const [checked, setChecked] = React.useState(true);
  const [IsCheckall, setIsCheckall] = useState(false);

  const onBack = () => {
    navigate(-1)
  }

  const [objSchema, setObjSchema] = useState({
    sGroupName: yupFormSchemas.string("ชื่อกลุ่มผู้ใช้งานระบบ", { required: true, isAdmin: true }),
  });
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all"
  });

  useEffect(() => {
    GetPermission(24, setPermission, fncDialog);
    GetData();
  }, [])

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }

  const GetData = () => {
    AxiosGet("GroupBPS/GetData_Datatype", {}, (result) => {
      setLstCheck(result.lstCheck || []);
      OnGetData(dataRow);
    },
      (err) => {
        if (!err.response) {
          history("/error", { replace: true })
        }
      });
  }

  const OnGetData = (p: PaginationInterface) => {
    AxiosPost("GroupBPS/GetDetail_Permission", { ...p, sID: sID }, (res) => {
      if (isEditMode) {
        form.setValue("sGroupName", res.sGroupName || "");
        setIsActive(res.isActive || false);

        let objPermission = {};
        res.lstPermission.forEach(element => {
          objPermission[element.sID] = element.nPRMS
        });
        setSelectedValue(objPermission);

        setLstCheck(res.lstindustry || []);

      } else {
        setLstComponent([
          { sGroupName: "", id: 1 }
        ]);
      }

      setDataRow({
        ...p,
        arrRows: res.lstPermission,
        nDataLength: res.nDataLength,
        nPageIndex: res.nPageIndex,
      });
    },
      (err) => {
        if (!err.response) {
          navigate("/error", null);
          return;
        }
      }
    );
  };

  const handleChange = (e, item) => {
    selectedValue[item.sID] = e.target.value;
    setSelectedValue({ ...selectedValue });

    let tempList = dataRow;
    // let tempRow = tempList.arrRows.filter((f) => f.sID == sID)[0];
    setDataRow({
      ...tempList,
    });
  };

  const handleChangeCheckbox = (e) => {

    let oUPD = lstCheck.filter((f) => f.nData_ID == e.target.value)[0];
    if (oUPD) {
      oUPD.ischeck = e.target.checked
    }

    let checkall = lstCheck.filter((f) => !f.ischeck)

    setLstCheck([...lstCheck])
    setIsCheckall(checkall.length == 0)
  };

  const handleChangeCheckboxAll = (e) => {
    lstCheck.forEach((item) => {
      item.ischeck = e.target.checked
    })
    setLstCheck([...lstCheck])
    setIsCheckall(e.target.checked);

  };

  const onSubmit = (e) => {
    //Check Table Permission
    let isCheckPermComplete = true;
    dataRow.arrRows.forEach((item) => {
      if (!item.isView && !item.isEdit) {
        dispatch(
          DialogActionCreators.OpenDialogWarning(
            `กรุณาเลือกการอนุญาตสำหรับเมนูทั้งหมด!`
          ) as any
        );
        isCheckPermComplete = false;
        return false;
      }
    });

    //#region loop array
    let arrValue = [];
    for (const [key, value] of Object.entries(selectedValue)) {
      let objPRMS = {
        sID: key,
        nPRMS: value
      }
      arrValue.push(objPRMS)
    }

    let arrValueCheck = [];
    let objnData = lstCheck.filter((f) => f.ischeck)
    let arrIndustry = objnData.map(value => value.nData_ID);
    for (const [key, value] of Object.entries(arrIndustry)) {
      let objData = {
        sID: key,
        nData_ID: value
      }
      arrValueCheck.push(objData)
    }

    //#endregion

    if (isCheckPermComplete) {
      let objParam = {
        sID: isEditMode ? sID : "",
        sGroupName: form.getValues("sGroupName"),
        isActive: isActive,
        lstOnsavePRMS: arrValue,
        lstOnsavenData: arrValueCheck,

        //   nGroup_ID: +(form.getValues("nGroup_ID")),
        nMenuID: 24
      };

      dispatch(
        DialogActionCreators.OpenDialogSubmit("คุณต้องการบันทึกข้อมูลหรือไม่ ?", () => { //Do you want save?
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);

          AxiosPost("GroupBPS/SaveData_Permission", { ...objParam }, (result) => {
            ResultAPI(result, "บันทึกข้อมูลสำเร็จ", () => {
              dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ",
                () => onBack()
              ) as any);
            });
          }, (err) => {
            if (err.Status === 409) {
              dispatch(DialogActionCreators.OpenDialogWarning("มีชื่อกลุ่มสิทธิ์การใช้งานนี้แล้ว", () => {
              }) as any)
            }
          }
          );
        }) as any
      );
    }
  };

  const dataColumn: GridColumns = [
    {
      field: "nNo",
      headerName: "ลำดับ", //No.
      headerAlign: "center",
      editable: false,
      width: 80,
      resizable: true,
      sortable: false,
      align: "center",
    },
    {
      field: "sMenuNameTH",
      headerName: "ชื่อเมนู", //Menu
      headerAlign: "center",
      align: "left",
      resizable: true,
      sortable: false,
      flex: 4,
      minWidth: 200,
      renderCell: (item) => item.value || "",
    },
    {
      field: "",
      headerName: "ไม่มีสิทธิ์เข้าถึง",
      headerAlign: "center",
      align: "center",
      resizable: true,
      sortable: false,
      minWidth: 200,


      renderCell: (item) => {
        return (
          nPermission == 2 ? (
            <Radio
              checked={selectedValue[item.row.sID] == 0}
              value={0}
              onChange={(e) => handleChange(e, item.row)}
              name={"Disabled-button" + item.row.sID}
            />
          ) : (
            <Radio
              disabled
              checked={selectedValue[item.row.sID] == 0}
              value={0}
              onChange={(e) => handleChange(e, item.row)}
              name={"Disabled-button" + item.row.sID}
            />
          )
        )
      },
    },
    {
      field: "isView",
      headerName: "อ่านเท่านั้น",
      headerAlign: "center",
      align: "center",
      resizable: true,
      sortable: false,
      minWidth: 200,
      renderCell: (item) => {
        return (
          nPermission == 2 ? (
            (item.row.isView ? (<Radio
              checked={selectedValue[item.row.sID] == 1}
              value={1}
              onChange={(e) => handleChange(e, item.row)}
              name={"ReadOnly-button" + item.row.sID}
            />) : (<Radio
              disabled={true}
            />))
          ) : (
            <Radio
              disabled
              checked={selectedValue[item.row.sID] == 1}
              value={1}
              onChange={(e) => handleChange(e, item.row)}
              name={"ReadOnly-button" + item.row.sID}
            />
          )
        )
      },
    },
    {
      field: "isEdit",
      headerName: "เพิ่ม/ลบ/แก้ไข",
      headerAlign: "center",
      align: "center",
      resizable: true,
      sortable: false,
      minWidth: 200,
      renderCell: (item) => {
        return (
          nPermission == 2 ? (
            (item.row.isEdit ? (<Radio
              checked={selectedValue[item.row.sID] == 2}
              value={2}
              onChange={(e) => handleChange(e, item.row)}
              name={"Enabled-button" + item.row.sID}
            />) : (<Radio
              disabled={true}
            />))
          ) : (
            <Radio
              disabled
              checked={selectedValue[item.row.sID] == 2}
              value={2}
              onChange={(e) => handleChange(e, item.row)}
              name={"Enabled-button" + item.row.sID}
            />
          )
        )
      },
    },
  ];

  return (
    <FormProvider {...form}>
      <Grid container spacing={3} sx={{ border: "1px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 2, flex: 1 }}>
        <Grid item xs={12}>
          <Grid item xs={12} >
            {nPermission == 2 ? (
              <Input
                name={"sGroupName"}
                fullWidth
                required
                maxLength={250}
                label={
                  <>
                    <Typography sx={{ fontWeight: 600 }} component="label">{"ชื่อกลุ่มผู้ใช้งานระบบ"}</Typography>

                  </>
                }
                labelString={""}
              />
            ) : (
              <Input
                disabled
                name={"sGroupName"}
                fullWidth
                required
                maxLength={250}
                label={
                  <>
                    <Typography sx={{ fontWeight: 600 }} component="label">{"ชื่อกลุ่มผู้ใช้งานระบบ"}</Typography>
                    <Typography component="span" sx={{ fontWeight: 600, fontSize: 12 }}>{"(ชื่อกลุ่ม)"}</Typography>
                  </>
                }
                labelString={""}
              />
            )}
            <Grid item>
              <Grid container>
                <Grid item xs={12} md={3} sx={{ marginTop: "2%" }}>
                  <Typography component="label" sx={{ fontWeight: 600 }}>
                    {/* เลือกรายชื่อประเภทอุตสาหกรรมที่รับผิดชอบ */}
                    {"เลือกรายชื่อประเภทอุตสาหกรรมที่รับผิดชอบ"}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginTop: "1.3%" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={nPermission != 2}
                        checked={IsCheckall}
                        onChange={handleChangeCheckboxAll}
                      />
                    }
                    label={"เลือกทั้งหมด"} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                  {lstCheck != null && lstCheck.length > 0 ? lstCheck.map((item, index) => (
                    <Grid item xs={12} sm={4} md={4} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={nPermission != 2}
                            checked={item.ischeck}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={item.nData_ID} />
                        }
                        label={item.sData_Name_TH} />
                    </Grid>
                  )) : null}
                </Grid>
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography component="label" sx={{ color: "black", fontWeight: 600 }}>
            {"กำหนดสิทธิ์การเข้าใช้งานระบบ"}
            <Typography component="span" sx={{ color: "red" }}>
              {" * "}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Table
              Rows={dataRow}
              Columns={dataColumn}
              isNotShowTotal={true}
              isNotShowPagination={true}
              OnLoadData={(p) => OnGetData(p)}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            <span style={{ fontWeight: 600 }}> {"สถานะ"} </span>
            <span style={{ color: "red" }}> *</span>
          </Typography>
          <Typography>
            <FormControlLabel
              control={
                <IOSSwitch
                  disabled={nPermission != 2}
                  sx={{ m: 1 }}
                  onChange={() => setIsActive(!isActive)}
                  checked={isActive}
                />
              }
              label={isActive ? "ใช้งาน" : "ไม่ใช้งาน"}
            />
            {/* {nPermission == 2 ? (
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    onChange={() => setIsActive(!isActive)}
                    checked={isActive}
                  />
                }
                label={isActive ? i18n(`${i18nField}.Active`) : i18n(`${i18nField}.Inactive`)}
              />
            ) : (
              <FormControlLabel
                control={
                  <IOSSwitch
                    disabled
                    sx={{ m: 1 }}
                    onChange={() => setIsActive(!isActive)}
                    checked={isActive}
                  />
                }
                label={isActive ? i18n(`${i18nField}.Active`) : i18n(`${i18nField}.Inactive`)}
              />
            )} */}
          </Typography>

          <Divider />
        </Grid>

        <Grid item xs={6} justifyContent={"start"}>
          <BtnBack txt={'ย้อนกลับ'} onClick={() => onBack()} />
        </Grid>
        <Grid item container xs={6} justifyContent={"end"}>
          <BtnSave
            txt={"บันทึก"}
            IsDisabled={nPermission == 2 ? false : true}
            onClick={form.handleSubmit((e) => {
              onSubmit(e)
            })}
          />
          {/* {nPermission == 2 ? (
            <BtnSave
              txt={i18n(`${i18nField}.SaveButt`)}
              onClick={form.handleSubmit((e) => {
                onSubmit(e)
              })}
            />
          ) : (
            <BtnSave
              txt={i18n(`${i18nField}.SaveButt`)}
              IsDisabled={true}
              onClick={form.handleSubmit((e) => {
                onSubmit(e)
              })}
            />
          )} */}
        </Grid>
      </Grid>
    </FormProvider>
  );
}