import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnViewOnTable } from "components/Common/Button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AxiosPost,
  GetPermission,
  ResultAPI,
} from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { Stack, Grid, Tooltip } from "@mui/material";
import AdminCompanyTabPanel from "./AdminCompanyTabPanel";
import secureLocalStorage from 'react-secure-storage';


export default function AdminCompanyJob() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const [Status, setStatus] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  const [permission, setPermission] = useState(0);


  let arrrow = {
    ...initRows,
    sSortExpression: "sUpdateDate",
    sSortDirection: "desc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "sUpdateDate",
  //   sSortDirection: "desc",
  // });


  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sNumber_Post" },
    { sTypeFilterMode: "input", sFieldName: "sJob" },
    { sTypeFilterMode: "input", sFieldName: "sJobType" },
    { sTypeFilterMode: "input", sFieldName: "sJobPackage" },
    { sTypeFilterMode: "daterange", sFieldName: "sUpdateDate" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect:
        [...Status],
    },
  ];

  useEffect(() => {
    GetPermission(2, setPermission, fncDialog);

    loadData(dataRow);
  }, []);


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    const objFilter = { ...p, sID: sID };
    AxiosPost(
      "AdminCompany/search_job",
      objFilter,
      (result) => {
        setLoadingTable(false);
        setStatus(result.lstStatus);
        setDataRow({
          ...p,
          arrRows: result.lstData,
          nDataLength: result.nDataLength,
          nPageIndex: result.nPageIndex,
        });
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        }
      }
    );
  };

  const onEdit = (sCompanyID: string, sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    navigate(
      `/AdminCompanyJobDetail?sID=${encodeURI(sCompanyID)}&sJobID=${encodeURI(
        sID
      )}`
    );
  };

  const dataColumn: GridColumns = [
    {
      field: "ปุ่ม (ดูรายละเอียด)",
      headerName: "",
      type: "actions",
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          <BtnViewOnTable
            txt="ดูรายละเอียด"
            onClick={() => onEdit(item.row.sCompanyID, item.row.sID, item.row)}
          />,
        ];
      },
    },
    {
      field: "sNumber_Post",
      headerName: "เลขที่ประกาศ",
      headerAlign: "center",
      align: "left",
      flex: 1,
      resizable: false,
      sortable: true,
      minWidth: 200,
    },
    {
      field: "sJob",
      headerName: "ชื่อตำแหน่งงาน",
      headerAlign: "center",
      align: "left",
      flex: 1,
      resizable: false,
      sortable: true,
      minWidth: 300,
    },
    {
      field: "sJobType",
      headerName: "ประเภทงาน",
      headerAlign: "center",
      align: "left",
      flex: 1,
      resizable: false,
      sortable: true,
      minWidth: 350,
    },
    {
      field: "sJobPackage",
      headerName: "แพ็กเกจ",
      headerAlign: "center",
      align: "left",
      flex: 1,
      resizable: false,
      sortable: true,
      minWidth: 200,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sJobPackage}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sJobPackage}
              </div>

            </Tooltip>
          )
        ];
      }
    },
    {
      field: "sUpdateDate",
      headerName: "วันที่ลงประกาศ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
      sortable: true,
      minWidth: 160,
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      resizable: false,
      minWidth: 120,

    }
  ];

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mx: "2%" }}>
        <AdminCompanyTabPanel tabNo={1} />
        <Grid
          sx={{
            marginTop: "2%",
          }}
        ></Grid>
        <Table
          isLoading={loadingTable}
          filterField={filter}
          Rows={dataRow}
          Columns={dataColumn}
          OnLoadData={(p) => loadData(p)}
        />
      </Grid>
    </Grid>
  );
}
