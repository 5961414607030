import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DialogActionCreators } from "store/redux/DialogAlert";
import {
  Typography,
  Divider,
  Button,
  Grid,
  Card,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { BtnBack, BtnSubmit } from "components/Common/Button";
import { useDispatch } from "react-redux";
import { AxiosPost, GetPermission } from "service/CommonFunction/TS_function";
import { i18n } from "i18n";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import ChangePassword from "view/font/Login/ChangePassword";



export default function MemberSetting() {
  const navigate = useNavigate();
  const [permission, setPermission] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const [sIdEdit, setsIdEdit] = useState("");

  const [isLoading, setLoading] = useState(true);

  const i18nLabel = "SetupTemplate.label";
  const i18nField = "RegisterEmployer";
  const i18nCommon = "common";
  const i18nCompanyEmployer = "CompanyEmployee";

  const isEditMode = sID ? true : false;
  const [isCheckedConsent1, SetIsCheckedConsent1] = useState<boolean>(true);
  const [errorConsent, setErrorConsent] = useState(false);
  const [OpenChangePass, setOpenChangePass] = useState(false);
  const [initialValues] = useState(() => {
    return {
      lstTag: [],
    };
  });

  //Yup validate
  const objSchema = {
    sEmail: yupFormSchemas.string(i18n(`${i18nField}.Email`), {
      required: true,
    }),
    sEmpName: yupFormSchemas.string(i18n(`${i18nField}.RegisterName`), {
      required: true,
    })
  };

  //Yup object
  const schema = yup.object().shape(objSchema);
  const AuthenController = 'Authen/'
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: initialValues as any,
  });

  const onGetDetail = () => {
    let obj = {
      sID: sID,
    }

    AxiosPost(
      "CompanyEmployee/Getdata_CompanyEmployee",
      obj,
      (res) => {
        if (res.Status === 200) {
          form.setValue("sEmail", res.objEmployee.sEmpEmail);
          form.setValue("sEmpName", res.objEmployee.sEmpName);
          form.setValue("sTel", res.objEmployee.sTel);
          setsIdEdit(res.objEmployee.sID);

          SetIsCheckedConsent1(res.objEmployee.isGetNews);
        } else {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Data not found.") as any
          );
        }
        setLoading(false);
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              history("/");
            }) as any
          );
        }
      }
    );
  };

  useEffect(() => {
    GetPermission(60, setPermission, fncDialog);
    onGetDetail();
  }, []);


  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }

  const onSubmit = (e) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nCommon}.AlertConfrimSave`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);

          let objSaveData = {
            sID: sIdEdit,
            isGetNews: isCheckedConsent1,
            sEmpName: form.getValues("sEmpName") || null,//e["sEmpName"]
            sEmail: form.getValues("sEmail") || null,//e["sEmail"],
            sTel: form.getValues("sTel") || null,//e["sTel"],
            nSettingType: 1
          };
          AxiosPost(
            "CompanyEmployee/SaveData_CompanyEmployee",
            objSaveData,
            (res) => {
              if (res.Status === 200) {
                dispatch(
                  DialogActionCreators.OpenDialogSuccess(
                    i18n(`${i18nCommon}.msgAlertSuccess`),
                    () => {
                      window.location.reload();
                    }
                  ) as any
                );
              } else {
                dispatch(
                  DialogActionCreators.OpenDialogWarning(res.Message) as any
                );
              }
            },
            (err) => {
              if (!err.response) {
                history("/error", null);
                return;
              }
              if (err.response && err.response.status === 401) {
                dispatch(
                  DialogActionCreators.OpenDialogWarning(
                    "Token expired.",
                    () => {
                      history("/");
                    }
                  ) as any
                );
              }
            }
          );
        }
      )
    );
  };

  const onSubmitPassword = (e) => {
    AxiosPost(`${AuthenController}ChangePassword`, { ...e }, (res) => {
      if (res.Status === 200) {
        dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nCommon}.msgAlertSuccess`), () => { //save success
          setOpenChangePass(false)
        }) as any)
      } else {
        dispatch(
          DialogActionCreators.OpenDialogWarning(i18n(`${i18nCommon}.msgAlertDataNotFound`)) as any //Data not found.
        );
      }
    }, err => {
      if (err.Status === 409) {
        dispatch(DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.msgAlertIncorrectSecureCode`), () => { //password incorrect
          }) as any)
      } else {
        dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any)
      }
    }, () => {
      // setIsLoadingSendForgot(false)
    })
  }

  const onBack = () => {
    history("/MyPageEmployer");
  };

  return (
    <FormProvider {...form}>
      <Card sx={{
        padding: "1em",
        borderRadius: "1em",
        position: "relative",
        marginTop: "6px"
        // background: "white"
      }}>
        {/* <Grid container className="fade-bg-color"> */}
        <Grid container>
          <Grid item xs={12} sx={{ mx: "2%" }}>
            <Grid item>
              <Grid item marginBottom={3}>
                {/* <Box sx={{ marginLeft: "86%" }}> */}
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <Button
                    variant="contained"
                    onClick={() => setOpenChangePass(true)}
                    sx={{ fontSize: "12px", borderRadius: "20px" }}
                  // txt="Reset Passetword"
                  >{i18n(`${i18nLabel}.UserSecureCodeChange`)}</Button> {/*change password*/}
                </Grid>
                {/* </Box> */}
                <ChangePassword
                  isOpen={OpenChangePass}
                  setOpen={setOpenChangePass}
                  funcSubmitPass={onSubmitPassword}
                  isJobSeeker={undefined}
                  isLoading={undefined}
                />

                <Grid item md={12} xs={12} sx={{ marginTop: "3%" }}>
                  <Input
                    name={"sEmpName"}
                    labelString={i18n(`${i18nCompanyEmployer}.Name`)}
                    required
                    fullWidth
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nCompanyEmployer}.Name`)}
                        </Typography>
                      </>
                    }
                  />
                </Grid>

                <Grid item md={12} xs={12} sx={{ marginTop: "3%" }}>
                  <Input
                    name={"sEmail"}
                    labelString={i18n(`${i18nField}.Email`)}
                    required
                    fullWidth
                    disabled
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Email`)}
                        </Typography>
                      </>
                    }
                  />
                </Grid>

                {/* Tel */}
                <Grid item md={12} xs={12} sx={{ marginTop: "3%" }}>
                  <Input
                    name={"sTel"}
                    labelString={i18n(`${i18nField}.Phone`)}
                    fullWidth
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Phone`)}
                        </Typography>
                      </>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider />

          <Grid item xs={12} sx={{ mx: "2%" }}>
            <FormControl required error={errorConsent} component="fieldset" variant="standard" >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckedConsent1}
                      onChange={() => {
                        SetIsCheckedConsent1((prev) => !prev);
                      }}
                    />
                  }
                  label={i18n(`${i18nField}.ConfirmRegisDoc`)}//"ต้องการรับข่าวสาร และข้อมูลที่เกี่ยวข้องต่างๆ"
                />
              </FormGroup>
              {errorConsent ? (<FormHelperText>{i18n(`${i18nField}.ErrorConsent`)}</FormHelperText>) : null}
            </FormControl>
          </Grid>

          {isLoading ? (
            ""
          ) : (
            <Grid container sx={{ my: "2%", mx: "2%" }}>
              <Grid item xs={6} justifyContent={"start"}>
                <BtnBack
                  txt={i18n(`${i18nCommon}.back`)}
                  onClick={() => onBack()}
                />
              </Grid>
              <Grid item container xs={6} justifyContent={"end"}>
                <BtnSubmit
                  txt={i18n(`${i18nCommon}.save`)}
                  onClick={form.handleSubmit((e) => onSubmit(e))}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </FormProvider>
  );
}