import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnAddOnTable, BtnEditOnTable, BtnViewOnTable } from "components/Common/Button";
import { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Tooltip, Typography } from "@mui/material";
import {
  AxiosPost, GetPermission,
} from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { Grid } from "@mui/material";
import { getLanguage } from "i18n";
import secureLocalStorage from 'react-secure-storage';


let sController = "AdminOrder";
let sSearch = sController + "/SearchData";
let sDetail = "AdminOrderDetail";

export default function AdminOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingTable, setLoadingTable] = useState(true);
  const [arrEmployer_ID, setarrEmployer_ID] = useState([]);
  const [arrOrder_ID, setarrOrder_ID] = useState([]);
  const [arrPackage_ID, setarrPackage_ID] = useState([]);
  const [arrStatus_ID, setarrStatus_ID] = useState([]);
  const [nPermission, setPermission] = useState(0);

  let arrrow = {
    ...initRows,
    sSortExpression: "sDate_Buy",
    sSortDirection: "desc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "sDate_Buy",
  //   sSortDirection: "desc",
  // });
  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sOrder_Number" },
    { sTypeFilterMode: "input", sFieldName: "sEmployer_Name" },
    {
      sTypeFilterMode: "select", sFieldName: "sPackage_Name", lstDataSelect:
        [...arrPackage_ID]
    },
    { sTypeFilterMode: "input", sFieldName: "sValue" },
    { sTypeFilterMode: "daterange", sFieldName: "sDate_Buy" },
    { sTypeFilterMode: "daterange", sFieldName: "sDate_Expiry" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus_Name", lstDataSelect:
        [...arrStatus_ID]
    }
  ];

  useEffect(() => {
    GetPermission(33, setPermission, fncDialog);
    loadData(dataRow);
  }, []);

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    const objFilter = {
      ...p,
      nType_ID: 0
    }
    ////console.log("objFilter", objFilter);

    AxiosPost(sSearch, objFilter, (result) => {
      ////console.log("loadData", result);

      setLoadingTable(false);
      setarrEmployer_ID(result.arrEmployer);
      setarrOrder_ID(result.arrOrder);
      setarrPackage_ID(result.arrPackage);
      setarrStatus_ID(result.arrStatus);
      setDataRow({
        ...p,
        arrRows: result.arrData,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        }
      }
    );
  };


  const onEdit = (sID: string) => {
    navigate(`/${sDetail}?sID=${sID}`);
  };

  const dataColumn: GridColumns = [
    {
      renderHeader: (item) => <BtnAddOnTable
        IsDisabled={nPermission !== 2}
        onClick={() => { navigate('/AdminOrderDetail') }}
        txt="เพิ่ม"
      />,
      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      // flex: 1,
      width: 70,
      resizable: false,
      sortable: false,
      getActions: (item) => {

        let html = <BtnEditOnTable
          IsDisabled={nPermission !== 2}
          txt="แก้ไข"
          onClick={() => onEdit(item.row.sID)}
        />

        if (item.row.nStatus_ID === 5 || item.row.nStatus_ID === 10) {
          html = <BtnViewOnTable
            IsDisabled={nPermission !== 2}
            txt="ดู"
            onClick={() => onEdit(item.row.sID)}
          />
        }
        return [html]
      }
    },
    {
      field: "sOrder_Number",
      headerName: "เลขที่คำสั่งซื้อ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "sEmployer_Name",
      headerName: "ชื่อผู้ประกอบการ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: "แพ็กเกจ",
      field: "sPackage_Name",
      type: "actions",
      flex: 1,
      minWidth: 300,
      resizable: false,
      sortable: true,
      align: "left",
      renderCell: (item) => {

        const html2 = <Box sx={{ overflow: 'hidden' }}>
          <Tooltip title={item.row.sPackage_Name}>
            <Box>{item.row.sPackage_Name}</Box>
          </Tooltip>
        </Box>

        let html = html2;

        if (item.row.isPromotion && item.row.isCheckedConsent) {
          html = <>
            <Typography style={{ color: "red" }}>[Special] [Promotion]</Typography>
            &nbsp;
            {html2}
          </>
        }
        else if (item.row.isPromotion) {
          html = <>
            <Typography style={{ color: "red" }}>[Promotion]</Typography>
            &nbsp;
            {html2}
          </>
        } else if (item.row.isCheckedConsent) {
          html = <>
            <Typography style={{ color: "red" }}>[Special]</Typography>
            &nbsp;
            {html2}
          </>
        }

        // html = <Tooltip placement="top" title={item.row.sPackage_Name}>
        //   <div id={item.row.sID}
        //     style={{
        //       whiteSpace: 'nowrap',
        //       overflow: 'hidden',
        //       textOverflow: 'ellipsis',
        //       width: '300px',
        //     }}>
        //     {item.row.sPackage_Name}
        //   </div>
        // </Tooltip>
        return (html);
      }
    },
    {
      field: "sValue",
      headerName: "ราคารวมทั้งสิ้น",
      headerAlign: "center",
      align: "right",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "sDate_Buy",
      headerName: "วันที่สั่งซื้อ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "sDate_Expiry",
      headerName: "วันที่หมดอายุ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "sStatus_Name",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 180,
    },
  ];

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <Table
          isLoading={loadingTable}
          filterField={filter}
          Rows={dataRow}
          Columns={dataColumn}
          OnLoadData={(p) => loadData(p)}
        />
      </Grid>
    </Grid>
  );
}