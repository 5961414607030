import { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { Stack, Typography, Divider, Button, Grid, FormControl, FormGroup, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import UploadFile from "components/Common/ElementInForm/UploadFile/UploadFile";
import { Extension } from "service/CommonFunction/TS_function";
import { BtnBack, BtnSave } from "components/Common/Button";
import { useDispatch } from "react-redux";
import {
  AxiosPost,
  AxiosGet,
  GetPermission,
} from "service/CommonFunction/TS_function";
import {
  dataContent,
  saveDataContent,
} from "components/Banner/AdminContent/ManageContent";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import BoxContent from "components/Banner/AdminContent/BoxContent";
import AdminCompanyTabPanel from "./AdminCompanyTabPanel";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { i18n } from "i18n";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";

const AdminCompanySetting = () => {
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const dispatch = useDispatch();
  const history = useNavigate();

  const [info, setInfo] = useState<{ [key: string]: any }>({});
  const [infoReg, setInfoReg] = useState<{ [key: string]: any }>({});
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const [isCheckedConsent, SetIsCheckedConsent] = useState<boolean>(true);
  const [errorConsent, setErrorConsent] = useState(false);
  const [Country, setCountry] = useState([]);
  const [Region, setRegion] = useState([]);
  const [Province, setProvince] = useState([]);
  const [Optioncountry, setOptioncountry] = useState(0);
  const navigate = useNavigate();

  const [lstOrder, setLstOrder] = useState<number[]>([1]);
  const [arrFileLogo, setarrFileLogo] = useState([]);
  const [arrFileBanner, setarrFileBanner] = useState([]);
  const [isEmailDup, setIsEmailDup] = useState(false);
  const [objStateContent, setObjStateContent] = useState({
    nTypeContent_1: 1,
  });
  const [lstComponent, setLstComponent] = useState<dataContent[]>([]);
  const [isAddAction, setIsAddAction] = useState(false);
  const [isChangeOrder, setIsChangeOrder] = useState(false);
  const [initialValues] = useState(() => {
    return {
      lstTag: [],
    };
  });
  const [nPermission, setPermission] = useState(0);
  const [objSchema, setObjSchema] = useState({});
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: initialValues as any,
  });

  useEffect(() => {
    GetPermission(2, setPermission, fncDialog);

    onGetDetail();
    OnGetDropDown();
  }, []);

  
  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  const onBack = () => {
    navigate(-1);
  };

  const onGetDetail = () => {
    AxiosGet("AdminCompany/info", { sID: sID }, (res) => {
      setInfo(res);
      if (res.CompanyInfo) {
        setInfoReg(res.CompanyInfo);
        SetIsCheckedConsent(res.CompanyInfo.IsGetnews);
        form.setValue("sTel", res.CompanyInfo.sTel);
        form.setValue("nCountry_ID", res.CompanyInfo.objCountry);
        if (res.CompanyInfo.objCountry) {
          setOptioncountry(res.CompanyInfo.objCountry.value)
        }
        form.setValue("nRegion_ID", res.CompanyInfo.objRegion);
        form.setValue("nProvinceID", res.CompanyInfo.objProvince);
        form.setValue("sPostCode", res.CompanyInfo.sPostCode);
        form.setValue("sStreet", res.CompanyInfo.sStreet);
        form.setValue("sMoo", res.CompanyInfo.sMoo);
        form.setValue("sAddressNo", res.CompanyInfo.sAddressNo);
        form.setValue("nIndustryTypeID", res.CompanyInfo.objIndustryType);
        form.setValue("sEmail", res.CompanyInfo.sEmail);
        form.setValue("sRegisterName", res.CompanyInfo.sRegister_Name);
        form.setValue("sComponyName", res.CompanyInfo.sCompany_Name);
      }
    });
    AxiosPost("SetupTemplate/detail", { sID: sID, lang: "th" }, (res) => {
      if (res.Status === 200) {
        setarrFileLogo(res.lstFileLogo || []);
        setarrFileBanner(res.lstFileBanner || []);
        if (res.lstDataContent && res.lstDataContent.length > 0) {
          let lstTmpComponent: dataContent[] = [];
          let lstOrder = [];
          res.lstDataContent.forEach((e, i) => {
            objStateContent[`nTypeContent_${i + 1}`] = e.nTypeID;
            if (e.nTypeID == 2 || e.nTypeID == 3) {
              e.fImageOrVideo.sFileType = e.fImageOrVideo.sSysFileName.split(".")[1];
              objStateContent[
                `${e.nTypeID == 2 ? "fFileImg_" : "fFileVideo_"}${i + 1}`
              ] = [e.fImageOrVideo];
            }
            let dataComponent: dataContent = {
              nType: e.nTypeID,
              fFileImageOrVideo: e.nTypeID == 2 || e.nTypeID == 3 ? [e.fImageOrVideo] : [],
              sContent: e.sContent,
              nOrder: i + 1,
              id: i + 1,
            };
            switch (e.nTypeID) {
              case 1:
                objStateContent[`sContentOnly_${i + 1}`] = e.sContent;
                break;
              case 2:
                objStateContent[`sContentBottomImg_${i + 1}`] = e.sContent;
                break;
              case 3:
                objStateContent[`sContentBottomVideo_${i + 1}`] = e.sContent;
                break;
              case 4:
                objStateContent[`sUrl_${i + 1}`] = e.sUrl;
                objStateContent[`sContentBottomUrl_${i + 1}`] = e.sContent;
                break;
            }
            setObjStateContent({ ...objStateContent });
            lstTmpComponent.push(dataComponent);
            lstOrder.push(i + 1);
          });
          setLstOrder(lstOrder);
          setLstComponent(lstTmpComponent);
          setIsChangeOrder(true);
        } else {
          setLstComponent([
            {
              nType: 1,
              sContent: "",
              fFileImageOrVideo: [],
              id: 1,
              nOrder: 1,
            },
          ]);
        }
      } else {
        dispatch(
          DialogActionCreators.OpenDialogWarning("Data not found.") as any
        );
      }
      UnBlockUI();
    },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              history("/");
            }) as any
          );
        }
        UnBlockUI();
      }
    );
  };

  const onSubmit = (e) => {

    dispatch(
      DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimSave`), () => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        let lstDataContent: saveDataContent[] = [];
        lstComponent.forEach((f, i) => {
          let objDataContent: saveDataContent = {
            nOrder: i + 1,
            nTypeID: objStateContent[`nTypeContent_${f.id}`],
          };
          switch (objStateContent[`nTypeContent_${f.id}`]) {
            case 1:
              objDataContent.sContent = objStateContent[`sContentOnly_${f.id}`];
              break;
            case 2:
              objDataContent.fImageOrVideo =
                objStateContent[`fFileImg_${f.id}`][0];
              objDataContent.sContent = e[`sTextBottomImage_${f.id}`];
              break;
            case 3:
              objDataContent.fImageOrVideo =
                objStateContent[`fFileVideo_${f.id}`][0];
              objDataContent.sContent = e[`sTextBottomVideo_${f.id}`];
              break;
            case 4:
              objDataContent.sContent = e[`sTextBottomUrl_${f.id}`];
              break;
          }
          lstDataContent.push(objDataContent);
        }
        );

        //ประกาศตัวแปร UpFile1
        let lstFileLogo = [];
        var objDataFileLogo = {};
        //เช็คค่า
        if (arrFileLogo.length > 0) {
          objDataFileLogo = {
            sFileName: arrFileLogo[0].sFileName + "",
            sSysFileName: arrFileLogo[0].sSysFileName + "",
            sURL: arrFileLogo[0].sFileLink + "",
            sFolderName: arrFileLogo[0].sFolderName + "",
            sRootURL: arrFileLogo[0].sRootURL + "",
            IsNew: arrFileLogo[0].IsNew,
          };
          lstFileLogo.push(objDataFileLogo);
        }

        //ประกาศตัวแปร UpFile2
        let lstFileBanner = [];
        var objDataFileBanner = {};
        //เช็คค่า
        if (arrFileBanner.length > 0) {
          objDataFileBanner = {
            sFileName: arrFileBanner[0].sFileName + "",
            sSysFileName: arrFileBanner[0].sSysFileName + "",
            sURL: arrFileBanner[0].sFileLink + "",
            sFolderName: arrFileBanner[0].sFolderName + "",
            sRootURL: arrFileBanner[0].sRootURL + "",
            IsNew: arrFileBanner[0].IsNew,
          };
          lstFileBanner.push(objDataFileBanner);
        }

        let objSaveData = {
          sID: sID,
          lstFileLogo: lstFileLogo,
          lstFileBanner: lstFileBanner,
          lstDataContent: lstDataContent,
          isGetNews: isCheckedConsent,
          sTel: form.getValues("sTel"),
          nCountryID: +(form.getValues("nCountry_ID").value) || 0,
          nRegionID: +(form.getValues("nRegion_ID").value) || 0,
          nProvinceID: +(form.getValues("nProvinceID").value) || 0,
          sPostCode: form.getValues("sPostCode") || null,
          sStreet: form.getValues("sStreet") || null,
          sMoo: form.getValues("sMoo") || null,
          sAddressNo: form.getValues("sAddressNo") || null,
          sComponyName: form.getValues("sComponyName") || null,
          nIndustryTypeID: +(form.getValues("nIndustryTypeID").value) || 0,
          sRegisterName: form.getValues("sRegisterName") || null,//e["sRegisterName"]
          sEmail: form.getValues("sEmail") || null,//e["sEmail"],
        };

        AxiosPost(
          "SetupTemplate/save",
          objSaveData,
          (res) => {
            if (res.Status == 200) {
              dispatch(
                DialogActionCreators.OpenDialogSuccess(
                  "บันทึกเรียบร้อยแล้ว"
                ) as any
              );
              onGetDetail();
            } else {
              dispatch(
                DialogActionCreators.OpenDialogWarning(res.Message) as any
              );
            }
          },
          (err) => {
            if (!err.response) {
              history("/error", null);
              return;
            }
            if (err.response && err.response.status === 401) {
              dispatch(
                DialogActionCreators.OpenDialogWarning("Token expired.", () => {
                  history("/");
                }) as any
              );
            }
          }
        );
      }) as any
    );
  };

  const AddComponent = () => {
    const maxId = lstComponent.reduce(
      (max, objItem) => (objItem.id > max ? objItem.id : max),
      lstComponent[0].id
    );

    let objDataComponent: dataContent = {
      nType: 1,
      sContent: "",
      fFileImageOrVideo: [],
      id: maxId + 1,
      nOrder: lstOrder.length + 1,
    };
    setIsAddAction(true);
    setObjStateContent({ ...objStateContent });
    setLstComponent([...lstComponent, objDataComponent]);
    setLstOrder([...lstOrder, lstOrder.length + 1]);
  };

  const OnResetPWD = () => {
    let sEmail = infoReg.sEmail
    if (!sEmail) {
      dispatch(
        DialogActionCreators.OpenDialogWarning(
          "กรุณากรอกอีเมลก่อนรีเซ็ตรหัสผ่าน"
        ) as any
      );
      return;
    }
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        `คุณต้องการเปลี่ยนรหัสผ่านใช่หรือไม่?`,
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          AxiosPost("SetupTemplate/OnResetPWD", { sEmail: sEmail, sCompanyID: sID, sResetPWDCode: "" }, (res) => {
            if (res.Status === 200) {
              dispatch(DialogActionCreators.OpenDialogSuccess(
                `อีเมลได้ถูกส่งไปยังผู้ใช้เพื่อยืนยันการรีเซ็ตรหัสผ่าน`
              ) as any
              );

              form.reset({ inp_subscribe: "" });
            } else {
              dispatch(DialogActionCreators.OpenDialogWarning(res.Message) as any);
            }
          },
            (err) => {
              if (!err.response) {
                history("/error", null);
                return;
              }
            }
          );
        }
      ) as any
    );
  };

  const onSubmitForgot = () => {
    let sEmail = infoReg.sEmail
    //  setIsLoadingSendForgot(true)
    AxiosPost("Authen/SendRequestForgotPw", { inp_email: sEmail }, (res) => {
      dispatch(DialogActionCreators.OpenDialogSuccess("ส่งคำขอสำเร็จ \nโปรดตรวจสอบที่อีเมลของคุณ", () => {
      }) as any)
    }, err => {
      dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any)
    }, () => {
    })
  }

  const OnGetDropDown = () => {
    AxiosGet("AdminCompany/GetData_DropDown", {}, (result) => {
      setCountry(result.lstOptionCountry || [])
      setRegion(result.lstOptionRegion || [])
      setProvince(result.lstOptionProvince || [])
    });
  }

  const CheckExistMail = (value) => {
    const param = {
      sEmail: value,
    };
    AxiosPost("Guest/CheckAlreadyMailEmployer", param, (res) => {
      const data = res.Data;
      if (data.IsAlready) {
        setIsEmailDup(true);
        form.setError("Email", { type: "custom" });
      } else {
        setIsEmailDup(false);
        // form.clearErrors();
        form.clearErrors("Email");
      }
    });
  };

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <AdminCompanyTabPanel tabNo={4} />
        <FormProvider {...form}>
          <Stack
            spacing={2}
            width={"100%"}
            sx={{
              marginTop: "2%",
              border: "2px #eeeeee solid",
              backgroundColor: "rgba(255,255,255,0.75)",
              borderRadius: 5,
              p: 3,
            }}
          >
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography fontWeight={700} fontSize={16}>
                    ข้อมูลสำหรับติดต่อ
                  </Typography>
                  <Typography variant="subtitle2" align="right">
                    ปรับปรุงเมื่อ : {infoReg.sUpdateDate}
                  </Typography>
                </Grid>

                <Grid item md={12} xs={12} >
                  <Divider sx={{ width: "100%" }} />
                </Grid>

                <Grid item md={12} xs={12} sx={{ my: "2%" }}>
                  <Input
                    name={"sEmail"}
                    labelString={"อีเมล"}
                    required
                    disabled={nPermission !== 2}
                    fullWidth
                    maxLength={300}
                    onBlur={(value) => {
                      CheckExistMail(value.target.value);
                    }}
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        อีเมล
                      </Typography>
                    }
                  />
                  {isEmailDup ? (
                    <FormHelperText style={{ color: "red" }}>
                      อีเมลนี้ถูกใช้งานแล้ว
                    </FormHelperText>
                  ) : null}
                </Grid>

                <Grid item md={12} xs={12} sx={{ marginBottom: "2%" }}>
                  <Input
                    name={"sTel"}
                    labelString={"เบอร์โทรติดต่อ"}
                    required
                    disabled={nPermission !== 2}
                    fullWidth
                    maxLength={20}
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        เบอร์โทรติดต่อ
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Grid item container spacing={2} xs={12}>
                    <Grid item md={4} xs={12} >
                      <AutoCompleteSelect
                        name={'nCountry_ID'}
                        label={"ประเทศ"}
                        options={Country}
                        disabled={nPermission !== 2}
                        onChange={(v) => {
                          setOptioncountry(v.value)
                          form.setValue('nCountry_ID', v ? v : null);
                        }}
                      />
                    </Grid>
                    {Optioncountry == 519 ? (
                      <Grid item md={4} xs={12} >
                        <AutoCompleteSelect
                          fullWidth
                          required
                          disabled={nPermission !== 2}
                          name={"nRegion_ID"}
                          label={"ภูมิภาค/กรุงเทพมหานคร"} //region
                          options={Region}
                          onChange={(v) => {
                            form.setValue('nRegion_ID', v ? v : 0);
                          }}
                        />
                      </Grid>
                    ) : null}
                    {Optioncountry == 519 ? (
                      <Grid item md={4} xs={12}>
                        <AutoCompleteSelect
                          fullWidth
                          required
                          disabled={nPermission !== 2}
                          name={"nProvinceID"}
                          label={"จังหวัด/เขต"} //province
                          options={Province}
                          onChange={(v) => {
                            form.setValue('nProvinceID', v ? v : null)
                          }}
                        />
                      </Grid>
                    ) : null}
                    {Optioncountry == 519 ? (
                      <Grid item md={12} xs={12} sx={{ marginBottom: "2%" }}>
                        <Grid item container spacing={2} xs={12}>
                          <Grid item md={2} xs={12}>
                            <Input
                              required
                              fullWidth

                              disabled={nPermission !== 2}
                              name={"sPostCode"}
                              label={
                                <Typography sx={{ fontWeight: 400 }} component="label">
                                  รหัสไปรษณีย์
                                </Typography>
                              }
                              maxLength={6}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Input
                              required
                              fullWidth
                              disabled={nPermission !== 2}
                              name={"sStreet"}
                              label={
                                <Typography sx={{ fontWeight: 400 }} component="label">
                                  ถนน
                                </Typography>
                              }
                              maxLength={500}
                            />
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <Input
                              required
                              fullWidth
                              disabled={nPermission !== 2}
                              name={"sMoo"}
                              label={
                                <Typography sx={{ fontWeight: 400 }} component="label">
                                  หมู่
                                </Typography>
                              }
                              maxLength={10}
                            />
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <Input
                              required
                              fullWidth
                              disabled={nPermission !== 2}
                              name={"sAddressNo"}
                              label={
                                <Typography sx={{ fontWeight: 400 }} component="label">
                                  เลขที่
                                </Typography>
                              }
                              maxLength={20}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <Typography fontWeight={700} fontSize={16} sx={{ marginTop: "2%" }}>
                    เปลี่ยนรหัสผ่าน/Change Password
                  </Typography>
                </Grid>
                {/* <Grid item md={3}>
                  <Typography variant="subtitle2" align="right">
                    สมัครเมื่อ : {infoReg.sUpdateDate}
                  </Typography>
                </Grid> */}
                <Grid item md={12} xs={12} >
                  <Divider sx={{ width: "100%" }} />
                </Grid>
                <Grid item lg={2} md={3} xs={4} sx={{ marginTop: "2%" }}>
                  <Button
                    variant="contained"
                    disabled={nPermission !== 2}
                    fullWidth
                    sx={{
                      borderRadius: "20px",
                      backgroundColor: "#0099FF",
                      color: "White",
                      ":hover": { bgcolor: "#cc9900" },
                    }}
                    onClick={() => onSubmitForgot()}
                  >
                    เปลี่ยนรหัสผ่าน
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "2%" }}>
              <Typography fontWeight={700} fontSize={16}>
                การเลือกรับข่าวสารผ่านช่องทางอีเมลของเว็บไซต์ และบริษัทในเครือ
                <Divider sx={{ width: "100%" }} />
              </Typography>
            </Grid>

            <Grid container justifyContent="center" alignItems="center" spacing={1} >
              <Grid item xs={12}>
                <FormControl required error={errorConsent} component="fieldset" variant="standard" >
                  <FormGroup>
                    <FormControlLabel control={<Checkbox
                      checked={isCheckedConsent}
                      disabled
                    />
                    }
                      label={"รับข่าวสารผ่านช่องทางอีเมลของเว็บไซต์"} />
                  </FormGroup>
                  {errorConsent ? <FormHelperText>{"กรุณา 'ยอมรับ' รายการข้อที่ 2 เพื่อใช้งานระบบต่อไป"}</FormHelperText> : null}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={16}>
                  หน้าจอแสดงข้อมูลผู้ประกอบการ
                </Typography>
              </Grid>

              <Grid item md={12} xs={12} >
                <Divider sx={{ width: "100%" }} />
              </Grid>
              <Grid item xs={12} marginTop={3}>
                <Grid container>
                  <Grid item xs={12} sx={{ mx: "2%" }}>
                    <Grid item>
                      <Grid item marginBottom={3}>
                        <Grid item xs={12}>
                          <UploadFile
                            id="sAttach_File"
                            keyID={1}
                            sTitle={"อัปโหลดโลโก้"}
                            IsRequired={false}
                            arrFile={arrFileLogo || []}
                            setarrFile={setarrFileLogo}
                            IsFolder={false}
                            IsFile={true}
                            IsMultiple={false}
                            Extension={[...Extension.Image]}
                            IsDrag={false}
                            nLimitFile={5}
                            isSetupEmployerBanner={false}
                            isSetupEmployerLogo={true}
                            disabled={nPermission !== 2}
                          />
                        </Grid>
                      </Grid>
                      <Grid item marginBottom={3}>
                        <Grid item xs={12}>
                          <UploadFile
                            id="sAttach_File"
                            keyID={1}
                            sTitle={"อัปโหลดแบนเนอร์"}
                            IsRequired={false}
                            arrFile={arrFileBanner || []}
                            setarrFile={setarrFileBanner}
                            IsFolder={false}
                            IsFile={true}
                            IsMultiple={false}
                            Extension={[...Extension.Image]}
                            IsDrag={false}
                            nLimitFile={5}
                            isSetupEmployerBanner={true}
                            isSetupEmployerLogo={false}
                            disabled={nPermission !== 2}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography sx={{ fontWeight: 600 }} component="label">
                          ธุรกิจของผู้ประกอบการ
                        </Typography>
                        <Typography
                          component="label"
                          sx={{ color: "red", margin: "0 2px" }}
                        >
                          {" * "}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{ color: "#B3B3B3", fontSize: 13 }}
                        >
                          {" แนะนำบริษัทของคุณที่นี่ เพื่อให้ผู้สมัครงานรู้จักบริษัทของคุณ!"}
                        </Typography>
                      </Grid>
                      <Stack
                        sx={{
                          marginTop: "0px !important",
                          border: "1px #eeeeee solid",
                          backgroundColor: "rgba(255,255,255,0.75)",
                          borderRadius: "5px",
                          p: 2,
                          flex: 1,
                        }}
                        spacing={2}
                        direction="column"
                      >
                        {lstComponent.map((item, index) => {
                          return (
                            <Fragment key={`f_${index}`}>
                              {index > 0 ? (
                                <Divider
                                  key={`divider_c_${index}`}
                                  sx={{ marginTop: "24px !important" }}
                                />
                              ) : null}
                              <BoxContent
                                key={`box_form_c_${index}`}
                                objData={item}
                                lstComponent={lstComponent}
                                setLstComponent={setLstComponent}
                                objSchema={objSchema}
                                setSchema={setObjSchema}
                                objStateContent={objStateContent}
                                setObjStateContent={setObjStateContent}
                                lstOrder={lstOrder}
                                setLstOrder={setLstOrder}
                                nOrder={index + 1}
                                disabled={nPermission != 2}
                                isAddAction={isAddAction}
                                setIsAddToDefault={() => setIsAddAction(false)}
                                setIsChangeOrder={(e) => setIsChangeOrder(e)}
                                isChangeOrder={isChangeOrder}
                                isAddPic={true}
                                isEmployerSetting={false}
                              />
                            </Fragment>
                          );
                        })}
                        {/* <Stack direction="row">
                          <Button
                            startIcon={<Add />}
                            onClick={() => AddComponent()}
                            disabled={nPermission != 2}
                            fullWidth
                            variant="contained"
                          >
                            Add Paragraph
                          </Button>
                        </Stack> */}
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ my: "2%", mx: "2%" }}>
                    <Grid item xs={6} justifyContent={"start"}>
                      <BtnBack txt="ย้อนกลับ" onClick={() => onBack()} />
                    </Grid>
                    <Grid item container xs={6} justifyContent={"end"}>
                      <BtnSave
                        txt={"บันทึก"}
                        IsDisabled={nPermission != 2}
                        onClick={form.handleSubmit((e) => onSubmit(e))}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
          <Grid
            container
            sx={{
              border: "1px #eeeeee solid",
              backgroundColor: "rgba(255,255,255,0.75)",
              borderRadius: 5,
              p: 2,
            }}
          ></Grid>
        </FormProvider>
      </Grid>
    </Grid>
  );
};
export default AdminCompanySetting;