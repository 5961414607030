import { useEffect, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Checkbox,
  Card,
  Hidden,
  Typography,
} from "@mui/material";
import { i18n } from "i18n";

import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import {
  AxiosGet,
  AxiosPost,
  Extension,
  ResultAPI,
} from "service/CommonFunction/TS_function";
import {
  BtnCancel,
  BtnSaveDraft,
  BtnSubmit,
  BtnBack,
} from "components/Common/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import UploadFile from 'components/Common/ElementInForm/UploadFile/UploadFile';
import InputNumber from "components/Common/ElementInForm/InputNumber";
import { GetPermission } from "service/CommonFunction/TS_function";

export default function OrderEmployerForm() {
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const isEditMode = sID ? true : false;

  const [isDisabled, setisDisabled] = useState(false);
  const [DisablrdBanner, setDisablrdBanner] = useState(false);
  const [isDisabledPackage, setisDisabledPackage] = useState(true);
  const [isStatus, setisStatus] = useState(false);
  const i18nField = "OrderEmployer";
  const i18nCommon = "common";
  const history = useNavigate();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isBanner, setIsBanner] = useState(false);
  const [detail, setDetail] = useState<{ [key: string]: any }>({});

  const [lstPackage, setlstPackage] = useState([]);
  const [lstPackageType, setlstPackageType] = useState([]);

  //Banner Package
  const [arrDuration, setarrDuration] = useState([]);
  const [arrlstRow, setarrlstRow] = useState([]);
  const [lstarrValue, setarrValue] = useState([]);
  const [lstCheck, setlstCheck] = useState([]);
  const [isAddPayment, setAddPayment] = useState(false);
  const [CancelStatus, setCancelStatus] = useState(false);
  const [isAddPaymentButt, setAddPaymentButt] = useState(false);
  const [isAddPayment_Tax, setisAddPayment_Tax] = useState(false);
  const [WaitPayment, setWaitPayment] = useState(false);
  const [Type, setType] = useState(false);
  const [PostPackkage, setPostPackkage] = useState(false);
  const [TypePackage, setTypePackage] = useState(false);
  const [arrFile1, setarrFile1] = useState([]);
  const [arrFile2, setarrFile2] = useState([]);
  const [arrFile3, setarrFile3] = useState([]);
  const [CancleOrder, setCancle] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [Type_ID, setType_ID] = useState(0);
  const [Status, setStatus] = useState("");
  const [permission, setPermission] = useState(0);


  useEffect(() => {
    GetPermission(29, setPermission, fncDialog);
  }, [])

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }


  //ถ้าเป็น Banner ไม่ต้อง required
  const objSchema = {
    sType_ID: yupFormSchemas.string(i18n(`${i18nField}.sPackage`), {
      required: true,
    }),
    sPackage_ID: yupFormSchemas.string(i18n(`${i18nField}.Select`), {
      required: !isBanner,
    }),
  };

  const onBack = () => {
    history(-1);
  };

  const onResetValue = () => {
    form.setValue("sPackage_ID", 0);
    // window.location.reload();
  }

  useEffect(() => {
    OnGetData_DropDown_PackageType();
    OnGetData_BannerPackage();
    OnGetData();
  }, []);

  //step2.2นำข้อมูลมาทำเป็น Yup object
  const schema = yup.object().shape(objSchema);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const OnGetData_DropDown_PackageType = () => {
    AxiosGet("OrderEmployer/GetData_DropDown_PackageType", {}, (result) => {
      setlstPackageType(result.lstOptionPackageType || []);
    },
      (err) => {
        if (!err.response) {
          history("/error", { replace: true });
          return;
        }
      }
    );
  };

  const OnGetData_DropDown_Package = () => {//sOrder_ID: sID
    let sType_ID = form.getValues("sType_ID");
    if (sType_ID === "3") {
      setIsBanner(true);
    } else {
      setIsBanner(false);
      AxiosGet("OrderEmployer/GetData_DropDown_Package", { sID: sType_ID }, (result) => {
        setlstPackage(result.lstOptionPackage || []);
      },
        (err) => {
          if (!err.response) {
            history("/error", { replace: true });
            return;
          }
        }
      );
    }
    setisDisabledPackage((sType_ID === "" ? true : false));
  };

  const OnGetData_PricePackage = () => {
    let sPackage_ID = form.getValues("sPackage_ID");

    AxiosPost("OrderEmployer/GetData_PricePakage",
      {
        sPackage_ID: sPackage_ID,
        sType_ID: form.getValues("sType_ID"),
      }, (result) => {
        setDetail(result);
      },
      (err) => {
        if (!err.response) {
          history("/error", { replace: true });
          return;
        }
      }
    );
  };

  const OnGetData_BannerPackage = () => {
    AxiosPost("AdminUserPackage/SetFilter_TableBannerDataPackage", {}, (result) => {
      setarrDuration(result.lstDuration);
      setarrlstRow(result.lstRow);
      setarrValue(result.lstValue);
    },
      (err) => {
        if (!err.response) {
          history("/error", { replace: true });
          return;
        }
      }
    );
  };

  const handleChangeCheckboxBanner = (e) => {
    let nPosition_ID = +e.target.value.split("_")[0];
    let nBannerPackage_ID = +e.target.value.split("_")[1];

    let objCheck = lstCheck.filter((f) => f.nPosition_ID === nPosition_ID)[0];
    if (objCheck) {
      if (e.target.checked) {
        objCheck.nBannerPackage_ID = nBannerPackage_ID;
      } else {
        objCheck.nBannerPackage_ID = null;
      }
    }
    setlstCheck([...lstCheck]);
    CalGrandTotal();
  };

  const CalGrandTotal = () => {
    let total = 0;
    lstCheck.map((item, index) => {
      let obj = lstarrValue.filter(
        (f) =>
          f.nPosition_ID === item.nPosition_ID &&
          f.nBannerPackage_ID === item.nBannerPackage_ID
      )[0];
      if (obj) {
        total = total + obj.nValue;
      }
    });
    detail.sAfterVat = (Math.round(total * 100) / 100).toLocaleString();
    setDetail(detail);
  };

  const OnGetData = () => {
    let obj = {
      sOrder_ID: sID,
    };
    AxiosPost("OrderEmployer/GetData_DetailOrderEmployer", obj, (result) => {
      ////console.log("GetData_DetailOrderEmployer", result);

      if (isEditMode) {
        if (result.sStatus_ID === "2") {
          setisDisabled(true);
          setCancle(true);
        }

        if (result.sStatus_ID === "3" && result.nType_ID != 2) {
          setAddPaymentButt(true);
          setCancle(true);
          setWaitPayment(true);
          setisDisabled(false);
        }

        if (result.sStatus_ID === "3" && result.sType_ID === "3") {
          setDisablrdBanner(true);
        }
        if (result.sStatus_ID === "4" && result.nType_ID != 2) {
          setisDisabled(true);
          setAddPaymentButt(true);
          setisAddPayment_Tax(true);
        }

        if (result.sStatus_ID === "5" && result.nType_ID != 2) {
          setisStatus(true);
          setisDisabled(true);
          setAddPayment(true);
          setisAddPayment_Tax(true);
        }

        if (result.sStatus_ID === "6") {
          setisStatus(true);
          setisDisabled(true);
        }

        if (result.nType_ID === 2) {
          setisStatus(true);
          setisDisabled(true);
          setCancle(false);
          setType(true);
        }
        if (result.nType_ID === 2 && result.sType_ID === "1") {
          setPostPackkage(true);
        }

        if (result.nType_ID === 1 && (result.sStatus_ID === "7" || result.sStatus_ID === "9")) {
          setisStatus(true);
          setisDisabled(true);
          setCancle(false);
          //  setType(true);
        }

        if (result.nType_ID === 2 && result.sType_ID != "3") {
          setTypePackage(true);
        }

        if (result.sType_ID === "3") {
          setIsBanner(true);
        }

        if (result.sStatus_ID == "1") {
          setShowDelete(true)
        }

        if (result.sStatus_ID == "10") {
          setisDisabled(true);
          setCancelStatus(true);
        }

        setStatus(result.sStatus_ID);
        setType_ID(result.sType_ID);
        form.setValue("sType_ID", result.sType_ID);
        // OnGetData_DropDown_Package();
        setlstPackage(result.lstOptionPackage || []);
        form.setValue("sPackage_ID", result.sPackage_ID);
        form.setValue("nCoin", result.nCoin);
        form.setValue("nPostjob", result.nPostjob);
        form.setValue("sPackageName", result.sPackageName);
        form.setValue("sDescription", result.sDescription);
        form.setValue("sComment", result.sComment);
        form.setValue("nDuration", result.nDuration);
        setDetail(result);
        setarrFile1(result.arrFile1 || []);
        setarrFile2(result.arrFile2 || []);
        setarrFile3(result.arrFile3 || []);
      }
      setlstCheck(result.lstBanner || []);
    },
      (err) => {
        if (!err.response) {
          history("/error", { replace: true });
          return;
        }
      }
    );
  };

  const onSubmit = (e, status) => {

    if (status == 4 && arrFile2.length === 0) {
      dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nField}.MsgPayment`), () => {
        dispatch(DialogActionCreators.CloseDialogWarning() as any);
      }) as any);
    } else {
      let sTitle = status == 1 ? i18n(`common.AlertSaveDraftPostJob`) : status == 4 ? i18n(`common.AlertSendProofPayment`) : status == 10 ? i18n(`common.AlertCancelOrder`) : i18n(`${i18nCommon}.AlertConfrimSave`);
      let objSaveData = {
        sOrder_ID: sID,
        sType_ID: form.getValues("sType_ID"),
        sPackage_ID:
          form.getValues("sType_ID") === "3" ?
            "" : form.getValues("sPackage_ID"),
        sPrice: detail.sPrice,
        sDiscount: detail.sDiscount,
        sNetPrice: detail.sNetPrice,
        sVat: detail.sVat,
        sAfterVat: detail.sAfterVat,
        sStatus_ID: status,
        lstBanner: lstCheck,
        arrFile1: arrFile1,
        arrFile2: arrFile2,
        arrFile3: arrFile3,
      };

      dispatch(
        DialogActionCreators.OpenDialogSubmit(sTitle, () => { //Do you want to save ?
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);

          AxiosPost(
            "OrderEmployer/SaveDataOrder",
            objSaveData,
            (result) => {
              dispatch(DialogActionCreators.CloseDialogSubmit() as any);
              ResultAPI(result, "Save successfully", () => {
                let msgAlertSuccess = status == 10 ? i18n(`${i18nField}.msgCalSuccess`) : status == 4 ? i18n(`common.AlertProofPaymentSuccess`) : i18n(`${i18nCommon}.msgAlertSuccess`);
                dispatch(
                  DialogActionCreators.OpenDialogSuccess(
                    msgAlertSuccess, //Save successfully
                    () => onBack()
                  ) as any
                );
              });
            },
            (err) => {
              if (err.Status === 409) {
                dispatch(
                  DialogActionCreators.OpenDialogWarning(i18n(`${i18nCommon}.magAlertPackageDeleted`), //cannot save, the package has been deleted
                    () => onResetValue()
                  ) as any
                );
              } else if (err.Message != "") {
                dispatch(
                  DialogActionCreators.OpenDialogWarning(err.Message) as any
                );
              }
            }
          );
        }) as any
      );
    }
  };

  const ShowDetail_by_Type = () => {
    if (isBanner) {
      return (
        <TableHead>
          <TableRow>
            <TableCell>{i18n(`${i18nField}.GrandTotal`)}</TableCell>
            <TableCell align="right">{detail.sAfterVat}</TableCell>
            <TableCell align="left">{i18n(`${i18nField}.Baht`)}</TableCell>
          </TableRow>
        </TableHead>
      );
    } else if (Type) {
      return (
        <TableHead>
          {PostPackkage ?
            <TableRow hidden={true}>
              <TableCell>{i18n(`${i18nField}.JobPost`)}<span style={{ color: "red" }}>*</span></TableCell>
              <TableCell align="right">
                <InputNumber
                  name={"nPostjob"}
                  disabled={true}
                /></TableCell>
              <TableCell align="left">{i18n(`${i18nField}.Post`)}</TableCell>
            </TableRow>
            : null

          }
          <TableRow hidden={true}>
            <TableCell>{i18n(`${i18nField}.CoinNumber`)}<span style={{ color: "red" }}>*</span></TableCell>
            <TableCell align="right">
              <InputNumber
                name={"nCoin"}
                disabled={true}
              /></TableCell>
            <TableCell align="left">{i18n(`${i18nField}.Coint`)}</TableCell>
          </TableRow>
          <TableRow hidden={true}>
            <TableCell>{i18n(`${i18nField}.Expirey`)} <span style={{ color: "red" }}>*</span></TableCell>
            <TableCell align="right">
              <InputNumber
                name={"nDuration"}
                disabled={true}
              /></TableCell>
            <TableCell align="left">{i18n(`${i18nField}.Month`)}</TableCell>
          </TableRow>
        </TableHead>
      );
    }
    else {
      return (
        <TableHead>

          {detail.sType_ID === EnumPackage.Jobpost ? (
            <>
              <TableRow >
                <TableCell>{i18n(`${i18nField}.JobPosting`)}</TableCell>
                <TableCell align="right">{detail.sJobPostAmount}</TableCell>
                <TableCell align="left">{i18n(`${i18nField}.Job`)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell>{i18n(`${i18nField}.CoinsFree`)}</TableCell>
                <TableCell align="right">{detail.sCoinFree}</TableCell>
                <TableCell align="left">{i18n(`${i18nField}.Coint`)}</TableCell>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow >
                <TableCell>{i18n(`${i18nField}.CoinPakageAmount`)}</TableCell>
                <TableCell align="right">{detail.sCoinAmount}</TableCell>
                <TableCell align="left">{i18n(`${i18nField}.Coint`)}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell>{i18n(`${i18nField}.CoinsFree`)}</TableCell>
                <TableCell align="right">{detail.sCoinFree}</TableCell>
                <TableCell align="left">{i18n(`${i18nField}.Coint`)}</TableCell>
              </TableRow>

              <TableRow >
                <TableCell>{i18n(`${i18nField}.TotalCoin`)}</TableCell>
                <TableCell align="right">{detail.sTotalCoin}</TableCell>
                <TableCell align="left">{i18n(`${i18nField}.Coint`)}</TableCell>
              </TableRow>
            </>
          )}

          <TableRow >
            <TableCell>{i18n(`${i18nField}.OrderPeriodPackage`)}</TableCell>
            <TableCell align="right">{detail.nDuration}</TableCell>
            <TableCell align="left">{i18n(`${i18nField}.Month`)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{detail.sType_ID === EnumPackage.Jobpost ? i18n(`${i18nField}.PricePerJob`) : i18n(`${i18nField}.CoinPirce`)}</TableCell>
            <TableCell align="right">{detail.sPrice}</TableCell>
            <TableCell align="left">{i18n(`${i18nField}.Baht`)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{i18n(`${i18nField}.Discount`)}</TableCell>
            <TableCell align="right">{detail.sDiscount}</TableCell>
            <TableCell align="left">%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{i18n(`${i18nField}.NetPrice`)}</TableCell>
            <TableCell align="right">{detail.sNetPrice}</TableCell>
            <TableCell align="left">{i18n(`${i18nField}.Baht`)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{i18n(`${i18nField}.Vatname`)}</TableCell>
            <TableCell align="right">{detail.sVat}</TableCell>
            <TableCell align="left">{i18n(`${i18nField}.Baht`)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{i18n(`${i18nField}.GrandTotal`)}</TableCell>
            <TableCell align="right">{detail.sAfterVat}</TableCell>
            <TableCell align="left">{i18n(`${i18nField}.Baht`)}</TableCell>
          </TableRow>
        </TableHead>
      );
    }
  };

  return (
    <FormProvider {...form}>
      {/* {isBanner ? () : ()} */}

      {/* phone size */}
      <Hidden smUp>
        <Card
          sx={{
            padding: "20px",
            borderRadius: "1em",
            position: "relative",
            maxWidth: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item container spacing={1} xs={12}>
              <Grid item xs={12} sm={12} md={12}>
                <AutoCompleteSelect
                  required
                  name={"sType_ID"}
                  label={i18n(`${i18nField}.SelectType`)}
                  options={lstPackageType}
                  customValue={{ key: "value" }}
                  onChange={(v) => {
                    form.setValue("sType_ID", v ? v : "");

                    form.setValue("sPackage_ID", "");
                    setDetail({});

                    OnGetData_DropDown_Package();
                  }}
                  disabled={WaitPayment || isDisabled || (Type_ID == 1 && Status == "9")}
                />
              </Grid>
            </Grid>
            {TypePackage ? (
              <Grid item container spacing={1} xs={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <Input
                    required={false}
                    name={"sPackageName"}
                    label={i18n(`${i18nField}.Select`)}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            ) :
              <Grid item container spacing={1} xs={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <AutoCompleteSelect
                    required
                    name={"sPackage_ID"}
                    label={i18n(`${i18nField}.Select`)}
                    options={lstPackage}
                    //   options={showDelete ? lstPackage : lstPackage.filter((f) => !f.isDelete)}
                    customValue={{ key: "value" }}
                    onChange={(v) => {
                      form.setValue("sPackage_ID", v ? v : "");
                      OnGetData_PricePackage();
                    }}
                    disabled={isDisabled || isDisabledPackage || WaitPayment || (Type_ID == 1 && Status == "9")}
                    hidden={isBanner}
                  />
                </Grid>
              </Grid>
            }
            <Grid item container xs={12} sx={{ marginTop: "-5%" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                hidden={isBanner !== true ? true : false}
              >
                <TableContainer component={Paper} sx={{ maxHeight: "80%" }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          background: "#eeeeee",
                          border: " 1px solid #dddddd",
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {i18n(`${i18nField}.Package`)}
                        </TableCell>
                        {arrDuration != null && arrDuration.length > 0
                          ? arrDuration.map((item, index) => {
                            return (
                              <TableCell
                                align="center"
                                key={index}
                                sx={{ border: " 1px solid #dddddd" }}
                              >
                                {item.sName} {i18n(`${i18nField}.Month`)}
                              </TableCell>
                            );
                          })
                          : null}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {arrlstRow != null && arrlstRow.length > 0
                        ? arrlstRow.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                key={index}
                                align="left"
                                sx={{ border: " 1px solid #dddddd" }}
                              >
                                {item.sName}
                              </TableCell>

                              {arrDuration != null && item.lstValue.length > 0 ? arrDuration.map((itemSub, indexSub) => {
                                let nvar = item.lstValue.find(e => e.nBannerPackage_ID == itemSub.nBannerPackage_ID)
                                return (
                                  <TableCell
                                    key={
                                      nvar.nPosition_ID +
                                      "_" + nvar.nBannerPackage_ID
                                    }
                                    align="center"
                                    sx={{
                                      border: " 1px solid #dddddd",
                                    }}
                                  >
                                    <FormControlLabel
                                      label={nvar.sName}
                                      control={
                                        <Checkbox
                                          disabled={isDisabled || DisablrdBanner}
                                          checked={
                                            lstCheck.filter(
                                              (f) =>
                                                f.nPosition_ID ===
                                                nvar.nPosition_ID &&
                                                f.nBannerPackage_ID ===
                                                nvar.nBannerPackage_ID
                                            )[0]
                                              ? true
                                              : false
                                          }
                                          value={
                                            nvar.nPosition_ID +
                                            "_" +
                                            nvar.nBannerPackage_ID
                                          }
                                          onChange={(e) =>
                                            handleChangeCheckboxBanner(e)
                                          }
                                        />
                                      }
                                    />
                                  </TableCell>
                                )
                              }) : null}
                            </TableRow>
                          );
                        })
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {isBanner ? (
              <Grid item container spacing={1} xs={12} sx={{ marginTop: "-10%" }}>
                <Grid item lg={12} xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">{ShowDetail_by_Type()}</Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Grid item container spacing={1} xs={12}>
                <Grid item lg={12} xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">{ShowDetail_by_Type()}</Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}

            {isAddPayment || isAddPaymentButt ?
              (
                <>
                  <Grid item lg={12} xs={12} >
                    <UploadFile
                      id="Upload_Invoice"
                      keyID={1}
                      sTitle={i18n(`${i18nField}.Invoice`)}
                      IsRequired={true}
                      arrFile={arrFile1 || []}
                      setarrFile={setarrFile1}
                      IsFolder={false}
                      IsFile={true}
                      IsMultiple={false}
                      Extension={[...Extension.ImagePDF]}
                      IsDrag={false}
                      nLimitFile={5}
                      isSetupEmployerBanner={false}
                      isSetupEmployerLogo={false}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item lg={12} xs={12} >
                    <UploadFile
                      id="Payment_Pic"
                      keyID={2}
                      sTitle={i18n(`${i18nField}.Payment`)}
                      IsRequired={true}
                      arrFile={arrFile2 || []}
                      setarrFile={setarrFile2}
                      IsFolder={false}
                      IsFile={true}
                      IsMultiple={false}
                      Extension={[...Extension.ImagePDF]}
                      IsDrag={false}
                      nLimitFile={5}
                      isSetupEmployerBanner={false}
                      isSetupEmployerLogo={false}
                      disabled={isAddPayment_Tax ? true : false}
                    />
                  </Grid>
                </>
              ) : null}
            {isAddPayment_Tax ?
              (
                <>
                  <Grid item lg={12} xs={12} >
                    <UploadFile
                      id="Upload_Tax"
                      keyID={1}
                      sTitle={i18n(`${i18nField}.Tax`)}
                      IsRequired={true}
                      arrFile={arrFile3 || []}
                      setarrFile={setarrFile3}
                      IsFolder={false}
                      IsFile={true}
                      IsMultiple={false}
                      Extension={[...Extension.ImagePDF]}
                      IsDrag={false}
                      nLimitFile={5}
                      isSetupEmployerBanner={false}
                      isSetupEmployerLogo={false}
                      disabled={true}
                    />
                  </Grid>
                </>
              ) : null
            }
            {/* wait for payment approve (sStatusID 2) */}
            {isAddPaymentButt ?
              (
                <>
                  <Grid item xs={12}>
                    <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
                      <Grid item>
                        <BtnBack
                          txt={i18n(`${i18nField}.BtnBack`)}
                          onClick={() => onBack()} />
                      </Grid>
                      <Grid item>
                        <BtnSaveDraft
                          txt={i18n(`${i18nField}.BtnDraft`)}
                          IsDisabled={isDisabled}
                          onClick={form.handleSubmit((e) => {
                            onSubmit(e, "1");
                          })}
                        />
                      </Grid>

                      <Grid item>
                        <BtnSubmit
                          txt={i18n(`${i18nField}.BtnSavePayment`)}
                          IsDisabled={isDisabled}
                          onClick={form.handleSubmit((e) => {
                            onSubmit(e, "4");
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                CancelStatus ?
                  <Grid item xs={12}>
                    <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
                      <Grid item>
                        <BtnBack
                          txt={i18n(`${i18nField}.BtnBack`)}
                          onClick={() => onBack()} />
                      </Grid>
                    </Grid>
                  </Grid>
                  : (Type_ID == 1 && Status == "9") ?
                    <Grid item xs={12}>
                      <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
                        <Grid item>
                          <BtnBack
                            txt={i18n(`${i18nField}.BtnBack`)}
                            onClick={() => onBack()} />
                        </Grid>
                      </Grid>
                    </Grid>
                    :
                    <Grid item xs={12}>
                      <Grid container spacing={1} sx={{ justifyContent: "flex-end" }} >
                        <Grid item>
                          <BtnBack
                            txt={i18n(`${i18nField}.BtnBack`)}
                            onClick={() => onBack()} />
                        </Grid>

                        {isStatus == false && (
                          <>
                            <Grid item>
                              <BtnSaveDraft
                                txt={i18n(`${i18nField}.BtnDraft`)}
                                IsDisabled={isDisabled}
                                onClick={form.handleSubmit((e) => {
                                  onSubmit(e, "1");
                                })}
                              />
                            </Grid>

                            <Grid item>
                              <BtnSubmit
                                txt={i18n(`${i18nField}.BtnSave`)}
                                IsDisabled={isDisabled}
                                onClick={form.handleSubmit((e) => {
                                  onSubmit(e, "2");
                                })}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
              )
            }

            {/* cancle order */}
            {CancleOrder ? (
              <Grid item xs={12} >
                <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
                  <Grid item>
                    <BtnCancel
                      txt={i18n(`${i18nField}.BtnCancle`)}
                      onClick={form.handleSubmit((e) => {
                        onSubmit(e, "10");
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Card>
      </Hidden>

      {/* normal size */}
      <Hidden smDown>
        <Card
          sx={{
            padding: "20px",
            borderRadius: "1em",
            position: "relative",
            maxWidth: "100%",
            justifyContent: "center",
            alignItems: "center",
            mx: "10%"
          }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography fontSize={20} fontWeight={500} color={"#277cd8"}>{detail.sPomotionName}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} >
                <AutoCompleteSelect
                  required
                  name={"sType_ID"}
                  label={i18n(`${i18nField}.SelectType`)}
                  options={lstPackageType}
                  customValue={{ key: "value" }}
                  onChange={(v) => {
                    form.setValue("sType_ID", v ? v : "");

                    form.setValue("sPackage_ID", "");
                    setDetail({});

                    OnGetData_DropDown_Package();
                  }}
                  disabled={WaitPayment || isDisabled || (Type_ID == 1 && Status == "9")}
                />
              </Grid>
            </Grid>
            {TypePackage ? (
              <Grid item container spacing={1} xs={12}>
                {/* <Grid item xs={12} sm={12} md={12}>
                  <Input
                    required={false}
                    name={"sPackageName"}
                    label={i18n(`${i18nField}.Select`)}
                    disabled={true}
                  />
                </Grid> */}
              </Grid>
            ) :
              <Grid item container spacing={1} xs={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <AutoCompleteSelect
                    required
                    name={"sPackage_ID"}
                    label={i18n(`${i18nField}.Select`)}
                    options={lstPackage}
                    // options={showDelete ? lstPackage : lstPackage.filter((f) => !f.isDelete)}
                    customValue={{ key: "value" }}
                    onChange={(v) => {
                      form.setValue("sPackage_ID", v ? v : "");
                      OnGetData_PricePackage();
                    }}
                    disabled={isDisabled || isDisabledPackage || WaitPayment || (Type_ID == 1 && Status == "9")}
                    hidden={isBanner}
                  />
                </Grid>
              </Grid>
            }
            <Grid item container xs={12} sx={{ marginTop: "-5%" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                hidden={isBanner !== true ? true : false}
              >
                <TableContainer component={Paper} >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          background: "#eeeeee",
                          border: " 1px solid #dddddd",
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {i18n(`${i18nField}.Package`)}
                        </TableCell>
                        {arrDuration != null && arrDuration.length > 0
                          ? arrDuration.map((item, index) => {
                            return (
                              <TableCell
                                align="center"
                                key={index}
                                sx={{ border: " 1px solid #dddddd" }}
                              >
                                {item.sName} {i18n(`${i18nField}.Month`)}
                              </TableCell>
                            );
                          })
                          : null}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {arrlstRow != null && arrlstRow.length > 0
                        ? arrlstRow.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                key={index}
                                align="left"
                                sx={{ border: " 1px solid #dddddd" }}
                              >
                                {item.sName}
                              </TableCell>

                              {arrDuration != null && item.lstValue.length > 0 ? arrDuration.map((itemSub, indexSub) => {
                                let nvar = item.lstValue.find(e => e.nBannerPackage_ID == itemSub.nBannerPackage_ID)
                                return (
                                  <TableCell
                                    key={
                                      nvar.nPosition_ID +
                                      "_" + nvar.nBannerPackage_ID
                                    }
                                    align="center"
                                    sx={{
                                      border: " 1px solid #dddddd",
                                    }}
                                  >
                                    <FormControlLabel
                                      label={nvar.sName}
                                      control={
                                        <Checkbox
                                          disabled={isDisabled || DisablrdBanner}
                                          checked={
                                            lstCheck.filter(
                                              (f) =>
                                                f.nPosition_ID ===
                                                nvar.nPosition_ID &&
                                                f.nBannerPackage_ID ===
                                                nvar.nBannerPackage_ID
                                            )[0]
                                              ? true
                                              : false
                                          }
                                          value={
                                            nvar.nPosition_ID +
                                            "_" +
                                            nvar.nBannerPackage_ID
                                          }
                                          onChange={(e) =>
                                            handleChangeCheckboxBanner(e)
                                          }
                                        />
                                      }
                                    />
                                  </TableCell>
                                )
                              }) : null}
                            </TableRow>
                          );
                        })
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {isBanner ? (
              <Grid item container spacing={1} xs={12} >
                <Grid item lg={12} xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">{ShowDetail_by_Type()}</Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item container spacing={1} xs={12}>
                  <Grid item lg={12} xs={12}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">{ShowDetail_by_Type()}</Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </>
            )}

            {isAddPayment || isAddPaymentButt ?
              (
                <>
                  <Grid item lg={12} xs={12} >
                    <UploadFile
                      id="Upload_Invoice"
                      keyID={1}
                      sTitle={i18n(`${i18nField}.Invoice`)}
                      IsRequired={true}
                      arrFile={arrFile1 || []}
                      setarrFile={setarrFile1}
                      IsFolder={false}
                      IsFile={true}
                      IsMultiple={false}
                      Extension={[...Extension.ImagePDF]}
                      IsDrag={false}
                      nLimitFile={5}
                      isSetupEmployerBanner={false}
                      isSetupEmployerLogo={false}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item lg={12} xs={12} >
                    <UploadFile
                      id="Payment_Pic"
                      keyID={2}
                      sTitle={i18n(`${i18nField}.Payment`)}
                      IsRequired={true}
                      arrFile={arrFile2 || []}
                      setarrFile={setarrFile2}
                      IsFolder={false}
                      IsFile={true}
                      IsMultiple={false}
                      Extension={[...Extension.ImagePDF]}
                      IsDrag={false}
                      nLimitFile={5}
                      isSetupEmployerBanner={false}
                      isSetupEmployerLogo={false}
                      disabled={isAddPayment_Tax ? true : false}
                    />
                  </Grid>
                </>
              ) : null}

            {isAddPayment_Tax ?
              (
                <>
                  <Grid item lg={12} xs={12} >
                    <UploadFile
                      id="Upload_Tax"
                      keyID={1}
                      sTitle={i18n(`${i18nField}.Tax`)}
                      IsRequired={true}
                      arrFile={arrFile3 || []}
                      setarrFile={setarrFile3}
                      IsFolder={false}
                      IsFile={true}
                      IsMultiple={false}
                      Extension={[...Extension.ImagePDF]}
                      IsDrag={false}
                      nLimitFile={5}
                      isSetupEmployerBanner={false}
                      isSetupEmployerLogo={false}
                      disabled={true}
                    />
                  </Grid>
                </>
              ) : null
            }

            {Type ? (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <Input name="sDescription" rows={3}
                    required={false}
                    disabled={true}
                    multiline
                    maxLength={100}
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">เหตุผลในการขออนุญาต</Typography>
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Input name="sComment" rows={3}
                    required={false}
                    disabled={true}
                    multiline
                    maxLength={100}
                    label={
                      <>
                        <Typography sx={{ fontWeight: 400 }} component="label">หมายเหตุ</Typography>
                      </>
                    }
                  />
                </Grid>
              </>
            ) : null
            }

            {/* wait for payment approve (sStatusID 2) */}
            {isAddPaymentButt ?
              (
                <>
                  <Grid item xs={12}>
                    <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
                      <Grid item>
                        <BtnBack
                          txt={i18n(`${i18nField}.BtnBack`)}
                          onClick={() => onBack()} />
                      </Grid>
                      <Grid item>
                        <BtnSaveDraft
                          txt={i18n(`${i18nField}.BtnDraft`)}
                          IsDisabled={isDisabled}
                          onClick={form.handleSubmit((e) => {
                            onSubmit(e, "1");
                          })}
                        />
                      </Grid>

                      <Grid item>
                        <BtnSubmit
                          txt={i18n(`${i18nField}.BtnSavePayment`)}
                          IsDisabled={isDisabled}
                          onClick={form.handleSubmit((e) => {
                            onSubmit(e, "4");
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                CancelStatus ?
                  <Grid item xs={12}>
                    <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
                      <Grid item>
                        <BtnBack
                          txt={i18n(`${i18nField}.BtnBack`)}
                          onClick={() => onBack()} />
                      </Grid>
                    </Grid>
                  </Grid>
                  : (Type_ID == 1 && Status == "9") ?
                    <Grid item xs={12}>
                      <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
                        <Grid item>
                          <BtnBack
                            txt={i18n(`${i18nField}.BtnBack`)}
                            onClick={() => onBack()} />
                        </Grid>
                      </Grid>
                    </Grid>
                    :
                    <Grid item xs={12}>
                      <Grid container spacing={1} sx={{ justifyContent: "flex-end" }} >
                        <Grid item>
                          <BtnBack
                            txt={i18n(`${i18nField}.BtnBack`)}
                            onClick={() => onBack()} />
                        </Grid>

                        {isStatus == false && (
                          <>
                            <Grid item>
                              <BtnSaveDraft
                                txt={i18n(`${i18nField}.BtnDraft`)}
                                IsDisabled={isDisabled}
                                onClick={form.handleSubmit((e) => {
                                  onSubmit(e, "1");
                                })}
                              />
                            </Grid>

                            <Grid item>
                              <BtnSubmit
                                txt={i18n(`${i18nField}.BtnSave`)}
                                IsDisabled={isDisabled}
                                onClick={form.handleSubmit((e) => {
                                  onSubmit(e, "2");
                                })}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
              )
            }

            {/* cancle order */}
            {CancleOrder ? (
              <Grid item xs={12} >
                <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
                  <Grid item>
                    <BtnCancel
                      txt={i18n(`${i18nField}.BtnCancle`)}
                      onClick={form.handleSubmit((e) => {
                        onSubmit(e, "10");
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Card>
      </Hidden >
    </FormProvider >
  );
}
export const enum EnumPackage {
  Jobpost = "1",
  Coin = "2",
  Banner = "3",
}