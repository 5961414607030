import { useState, useEffect } from 'react';
import { DataGridMui as Table, FilterFieldInterface, initRows, PaginationInterface } from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import { BtnEditOnTable } from "components/Common/Button"
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useDispatch } from 'react-redux';
import { i18n } from 'i18n';
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import { Box, Tooltip, Typography } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';


const FormTablelJobPackage = () => {
  const i18nField = 'entities.Package';
  const i18nDialog = 'dialog';
  const dispatch = useDispatch();
  const history = useNavigate();
  const navigate = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [isDataActive, setIsActive] = useState(true);
  const [isDelete, setIsDelete] = useState(true);


  let arrrow = {
    ...initRows,
    sSortExpression: "sEndDate",
    sSortDirection: "asc"
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName + "1");
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "sEndDate",
  //   sSortDirection: "asc"
  // });

  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sPackageName" },
    { sTypeFilterMode: "input", sFieldName: "nPostAmoun" },
    { sTypeFilterMode: "input", sFieldName: "nCoinAmoun" },
    { sTypeFilterMode: "input", sFieldName: "nExDurationAmoun" },
    { sTypeFilterMode: "input", sFieldName: "sPriceAfterVat" },
    { sTypeFilterMode: "input", sFieldName: "sNormalPrice" },
    { sTypeFilterMode: "daterange", sFieldName: "sLastUpDate" },
    { sTypeFilterMode: "daterange", sFieldName: "sStartDate" },
  ]
  useEffect(() => {
    GetPermission(5, setPermission, fncDialog);
    loadData(dataRow);
  }, []);

  const fncDialog = () => {
    dispatch(DialogActionCreators.OpenDialogWarning("คุณไม่มีสิทธิ์ในการเข้าถึง", () => {
      navigate("/");
    }) as any)
  }

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    AxiosPost("AdminUserPackage/SearchData_TableDataPackage", { ...p, nTypeTable: 1 }, (result) => {
      ////console.log("SearchDataTableDataPackage", result);

      setLoadingTable(false)
      setIsDelete(result.lstMaster.isOrder)
      setDataRow({
        ...p,
        arrRows: result.lstMaster,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    })
  }
  const OnDelete = (e) => {
    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

      AxiosPost(`AdminUserPackage/RemoveDataTable`, { lstRemoveData: e, nTypeRemove: 1 }, (result) => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        ResultAPI(result, i18n(`${i18nDialog}.DialogRemoveSuccess`), () => {
          dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nDialog}.DialogRemoveSuccess`)) as any)
          loadData(dataRow);
        });
      })
    }) as any)
  }

  const goToEdit = (sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    history(`/CreateJobPackage?sID=${encodeURI(sID)}`);
  };

  const dataColumn: GridColumns = [
    {
      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 100,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          (
            <BtnEditOnTable
              IsDisabled={nPermission !== 2}
              txt={'แก้ไข'}
              onClick={() => goToEdit(item.row.sID, item.row)}
            />
          )
        ];
      },
    },
    {
      field: "sPackageName",
      headerName: "ชื่อแพ็กเกจ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 3,
      minWidth: 300,
      renderCell: (item) => {
        const html = <>
          {item.row.isPromotion ?
            <Typography style={{ color: "red" }}>[Promotion]</Typography>
            : ""
          }
          &nbsp;
          <Box sx={{ overflow: 'hidden' }}>
            <Tooltip title={item.row.sPackageName}>
              <Box>{item.row.sPackageName}</Box>
            </Tooltip>
          </Box>
        </>
        return (html);
      }
    },
    {
      field: "nPostAmoun",
      headerName: "จำนวน Job Post",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 120
    },
    {
      field: "nCoinAmoun",
      headerName: " จำนวน Coin",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 120,

    },
    {
      field: "nExDurationAmoun",
      headerName: "ระยะเวลาหมดอายุ (เดือน)",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 160
    },
    {
      field: "sPriceAfterVat",
      headerName: "ราคารวมทั้งสิ้น",
      headerAlign: "center",
      align: "right",
      resizable: false,
      flex: 2,
      minWidth: 180,
    },
    {
      field: "sNormalPrice",
      headerName: "ราคาปกติต่อ 1 Job",
      headerAlign: "center",
      align: "right",
      resizable: false,
      flex: 2,
      minWidth: 180,
    },
    {
      field: "sLastUpDate",
      headerName: "แก้ไขล่าสุด",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "sStartDate",
      headerName: "วันที่เริ่ม",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 150,

    },

  ];

  return (
    <Table
      id="1"
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
      isShowCheckBox={nPermission == 2}
      onDelete={OnDelete}
      isRowSelectable={(p) => !p.row.isOrder}
    />
  );

}

export default FormTablelJobPackage


